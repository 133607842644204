var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('Modal',{attrs:{"id":"addOnModal","size":"is-medium","isOpen":_vm.isModalOpen,"header":_vm.$t('dispatch.addOn'),"loading":_vm.loading,"buttonLabel":_vm.$t('button.confirm')},on:{"update:isOpen":function($event){_vm.isModalOpen=$event},"update:is-open":function($event){_vm.isModalOpen=$event},"cancel":function () {},"save":_vm.onSubmit}},[_c('form',{ref:"form",on:{"submit":function($event){$event.preventDefault();return _vm.onSubmit.apply(null, arguments)}}},[_c('p',{staticClass:"subTitle"},[_vm._v(_vm._s(_vm.$t('participant.infoLabel')))]),_c('Autocomplete',{attrs:{"label":_vm.$t('modal.name'),"api":{
        url: 'participants',
        query: 'participant_full_name',
        params: [{ id: 'is_active', value: 1 }]
      },"model":"id","field":"participant_full_name","selectOnBlur":false,"keepFirst":false,"required":""},on:{"select":_vm.onSelectParticipant,"primitiveInput":_vm.onInput},scopedSlots:_vm._u([{key:"results",fn:function(ref){
      var data = ref.data;
return [_c('div',{staticClass:"w-100"},[_c('strong',[_vm._v(_vm._s(data.participant_full_name))]),_c('p',[_vm._v(_vm._s(data.participant_address_full_address))])])]}}]),model:{value:(_vm.formData.participant_full_name),callback:function ($$v) {_vm.$set(_vm.formData, "participant_full_name", $$v)},expression:"formData.participant_full_name"}}),_c('p',{staticClass:"subTitle"},[_vm._v(_vm._s(_vm.$t('schedule.delivery')))]),_c('Field',{attrs:{"label":_vm.$t('global.orderNumber'),"maxlength":15,"type":"Text","required":""},model:{value:(_vm.formData.tracking_number),callback:function ($$v) {_vm.$set(_vm.formData, "tracking_number", $$v)},expression:"formData.tracking_number"}}),_c('Autocomplete',{key:("address-" + _vm.keyId),attrs:{"label":_vm.$t('address.address', []),"data":_vm.addresses,"field":"full_address","model":"id","required":"","keep-first":""},on:{"select":function (id) {
          if (id) { _vm.formData.participant_address_id = id; }
        }},model:{value:(_vm.formData.participant_address_id),callback:function ($$v) {_vm.$set(_vm.formData, "participant_address_id", $$v)},expression:"formData.participant_address_id"}}),_c('p',{staticClass:"subTitle"},[_vm._v(_vm._s(_vm.$t('dispatch.pickupAddressCDP')))]),_c('Autocomplete',{attrs:{"label":_vm.$t('menu.cdp'),"api":{
        url: 'cdp',
        query: 'name',
        full: true
      },"field":"name","model":"id","required":"","disabled":"","loading":_vm.loading.manifest,"keep-first":""},model:{value:(_vm.formData.close_door_pharmacy_id),callback:function ($$v) {_vm.$set(_vm.formData, "close_door_pharmacy_id", $$v)},expression:"formData.close_door_pharmacy_id"}}),_c('Field',{attrs:{"label":_vm.$tc('global.comment', 0),"type":"textarea","upper":false,"placeholder":_vm.$t('global.placeholder.deliveryComment'),"rows":1},model:{value:(_vm.formData.comments),callback:function ($$v) {_vm.$set(_vm.formData, "comments", $$v)},expression:"formData.comments"}}),_c('div',{staticClass:"addOn-sequence"},[_c('p',{staticClass:"subTitle"},[_vm._v(_vm._s(_vm.$t('dispatch.deliverySequence')))]),_c('div',{staticClass:"flex f-jsa"},[_c('div',[_c('iSwitch',{staticClass:"mt-4",attrs:{"text":_vm.$t('global.rush')},model:{value:(_vm.priority),callback:function ($$v) {_vm.priority=$$v},expression:"priority"}}),_c('iSwitch',{staticClass:"mt-5 mb-4",attrs:{"text":_vm.$t('deliveries.restriction')},model:{value:(_vm.hasRestriction),callback:function ($$v) {_vm.hasRestriction=$$v},expression:"hasRestriction"}})],1),_c('iSwitch',{staticClass:"mt-5",attrs:{"text":_vm.$t('global.refrigerated')},model:{value:(_vm.formData.refrigeration_needed),callback:function ($$v) {_vm.$set(_vm.formData, "refrigeration_needed", $$v)},expression:"formData.refrigeration_needed"}})],1),_c('div',{staticClass:"date-constraint"},[_c('DateConstraint',{attrs:{"hasRestriction":_vm.hasRestriction ? true : false},on:{"change":_vm.onDeliveryTimesChange}})],1)])],1)])}
var staticRenderFns = []

export { render, staticRenderFns }
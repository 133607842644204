export default [
  {
    path: '/login',
    name: 'login',
    meta: { isPublic: true, title: 'Login' },
    component: () => import(/* webpackChunkName: "Login" */ '../views/Public/Login/')
  },
  {
    alias: '/reset-password',
    path: '/register-password',
    name: 'registerPassword',
    meta: { isPublic: true, title: 'Reset password' },
    component: () => import(/* webpackChunkName: "registerPassword" */ '@/views/RegisterPassword/')
  },
  {
    path: '/success-mobile-password',
    name: 'successMobilePassword',
    meta: { isPublic: true, title: 'Success password changed' },
    component: () =>
      import(/* webpackChunkName: "successMobilePassword" */ '@/views/SuccessMobilePassword/')
  },
  {
    path: '/tracking/:trackingHashId',
    name: 'tracking',
    meta: { isPublic: true, title: 'Tracking' },
    component: () => import(/* webpackChunkName: "TrackingMap" */ '@/views/Public/Tracking/Map')
  }
];

<template>
  <div id="app">
    <router-view v-if="useBasicLayout" />
    <default-layout v-else>
      <router-view />
    </default-layout>
  </div>
</template>

<script>
export default {
  data() {
    return {
      useBasicLayout: true
    };
  },
  watch: {
    $route(value) {
      this.useBasicLayout = value.meta.useBasicLayout || value.meta.isPublic;
    }
  }
};
</script>

<style lang="sass"></style>

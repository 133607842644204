import { Permissions } from '@/utils/Secure';
const { Vehicles } = Permissions;
export default [
  {
    inMenu: true,
    component: () => import(/* webpackChunkName: "vehicles" */ '@/views/Vehicles/index'),
    meta: {
      i18n: 'menu.veh',
      secure: { permission: Vehicles.readAll, redirect: null },
      title: 'Vehicles'
    },
    name: 'vehicles',
    path: '/data/vehicles'
  },
  {
    component: () => import(/* webpackChunkName: "vehicle/new" */ '@/views/Vehicles/Vehicle'),
    secure: { permission: Vehicles.create, redirect: null },
    name: 'newVehicle',
    path: '/data/vehicles/add'
  },
  {
    component: () => import(/* webpackChunkName: "vehicle/edit" */ '@/views/Vehicles/Vehicle'),
    secure: { permission: Vehicles.update, redirect: null },
    name: 'vehicle',
    path: '/data/vehicles/:vehicleId(\\d+)'
  }
];

<template>
  <b-field :label="label" :class="classes">
    <p v-if="prefixText || $slots.prefixText" class="control s-control">
      <span class="button is-static">
        <slot name="prefixText" />
        {{ prefixText }}
      </span>
    </p>
    <div v-if="accentColor" class="accent-color flex" :style="{ background: accentColor }" />
    <template v-if="$slots.default">
      <slot name="default" />
    </template>
    <template v-else-if="simple && !mask">
      <b-input
        ref="inputSimple"
        v-bind="iProps"
        @input="onInput"
        @blur="onBlur"
        :pattern="fieldPattern"
        :validation-message="validationMessage || $t('validations.checkField')"
        :class="isErrorOrderNumber && 'order-number-field'"
      />
      <slot v-if="isErrorOrderNumber" name="validation-message" />
    </template>
    <CustomInput
      v-else
      :class="hasAddClass"
      v-bind="{ ...$props, ...$attrs }"
      @input="onInput"
      @primitiveInput="onPrimitiveInput"
      :pattern="fieldPattern"
    />
    <Tooltip
      class="btnAction"
      v-if="useActionBtn && hasPermission"
      :label="btnAction.label"
      :position="addPosition"
      :active="!btnAction.disabled && btnAction.needsTooltip"
    >
      <b-button
        :type="btnAction.type ? btnAction.type : 'is-primary'"
        :icon-right="btnAction.icon"
        :loading="btnAction.loading"
        :disabled="btnAction.disabled"
        outlined
        @click="$emit('actionClick')"
      />
    </Tooltip>

    <Tooltip
      v-if="$listeners.add && hasPermission"
      :label="addLabel || $t('addLabel')"
      :position="addPosition"
      :active="!btnAction.disabled"
    >
      <b-button
        :class="$listeners.edit ? 'edit-button-border' : 'add-button-border'"
        type="is-primary"
        icon-right="plus"
        outlined
        @click="$emit('add')"
        :disabled="isDisabledAddButton"
      />
    </Tooltip>

    <Tooltip
      v-if="$listeners.edit && hasPermission"
      :label="addLabel || $t('editLabel')"
      :position="addPosition"
      :active="!btnAction"
    >
      <b-button
        class="add-button-border"
        outlined
        type="is-primary"
        icon-right="pencil"
        @click="$emit('edit')"
        :disabled="isDisabledEditButton"
      />
    </Tooltip>
    <p v-if="sufixText" class="control s-control">
      <span class="button is-static">{{ sufixText }}</span>
    </p>
    <slot name="end" />
  </b-field>
</template>

<script>
import { CustomInput } from '.';
import { Tooltip } from '@/components';
import { Normalize } from '@/utils/Text';
import { emailRegex, numberAndLettersPlusSpaceRegex } from '@/utils/RegexValidations';

export default {
  components: {
    CustomInput,
    Tooltip
  },
  mounted() {
    if (this.type === 'email') this.isUpper = false;
    this.$nextTick(() => {
      if (this.focus) this.$refs?.inputSimple?.focus();
      this.fixBuefyInputParent();
    });
  },
  data() {
    return {
      primitiveInput: this.value,
      inputValueTimeout: 0,
      hasFocus: false,
      isUpper: this.upper,
      isErrorOrderNumber: false,
      buefyInputParent: null
    };
  },
  computed: {
    classes() {
      let classes = ['Field'];
      if (this.required) classes.push('required');
      if (!this.isUpper) classes.push('no-uppercase');
      if (this.displayInherit) classes.push('d-inherit');
      if (this.hasButtons) classes.push('hasButtons');
      return classes.join(' ');
    },
    disabled() {
      return this.btnActionDisabledEmpty ? !this.primitiveInput : false;
    },
    hasButtons() {
      const { actionClick, add, edit } = this.$listeners;
      return !!actionClick || !!add || !!edit;
    },
    hasPermission() {
      let isAllowed = false;
      if (this.permission?.length) {
        isAllowed = this.permission.some((permission) =>
          this.Secure.permissionValidator(permission)
        );
      }
      return isAllowed || this.Secure.permissionValidator(this.permission);
    },
    hasAddClass() {
      return this.$listeners.add || this.$listeners.edit ? 'hasAdd' : '';
    },
    fieldPattern() {
      if (this.type === 'email') return emailRegex;
      if (this.avoidSpecialChars && this.type === 'text' && !this.pattern)
        return numberAndLettersPlusSpaceRegex;
      return this.pattern;
    },
    moneyTypeProps() {
      const { min, max, step, placeholder } = this.$attrs;
      if (this.type === 'money')
        return {
          max: max || '100000.00',
          min: min || '0.00',
          placeholder: placeholder || '$0.00',
          step: step || '0.01',
          type: 'number'
        };
      return {};
    },
    iProps() {
      const props = { ...this.$props, ...this.$attrs, ...this.moneyTypeProps };
      return props;
    }
  },
  methods: {
    fixBuefyInputParent() {
      const Input = this.$refs.inputSimple;
      let Parent = Input?.$parent;
      if (!Parent) return;
      const isAddon = Parent.$el.classList.contains('has-addons');
      if (isAddon) {
        Parent = Parent?.$parent;
        for (var i = 0; i < 3; i++) {
          const isField = Parent?.$el.classList.contains('field');
          if (!isField) {
            Parent = parent?.$parent;
          }
        }
        this.buefyInputParent = Parent;
      }
    },
    checkHtml5Validity() {
      const { buefyInputParent, $refs } = this;
      const isValid = $refs.inputSimple?.checkHtml5Validity() || false;
      if (isValid && buefyInputParent) {
        this.buefyInputParent.newType = null;
        this.buefyInputParent.newMessage = null;
      }
    },
    onPrimitiveInput(input) {
      this.primitiveInput = this.type === 'number' ? parseFloat(input) : input;
      this.$emit('primitiveInput', this.primitiveInput);
    },
    onBlur() {
      let input = '';
      if (this.type === 'number') input = parseFloat(this.primitiveInput);
      else input = Normalize(this.primitiveInput, { upper: this.isUpper });
      this.$emit('input', input);
    },
    onInput(input) {
      this.primitiveInput = this.type === 'number' ? parseFloat(input) : input;
      this.$emit('input', this.primitiveInput);
      this.checkHtml5Validity();
    }
  },
  watch: {
    value(newValue) {
      if (newValue == this.primitiveInput) return;
      // if (newValue && !oldValue) this.primitiveInput = this.value;
      this.primitiveInput = this.value;
    },
    primitiveInput() {
      if (this.isErrorOrderNumber) this.isErrorOrderNumber = false;
    }
  },
  props: {
    accentColor: { type: String },
    addLabel: { type: String },
    addPosition: { type: String, default: 'top' },
    avoidSpecialChars: { type: Boolean, default: true },
    focus: { type: Boolean },
    btnAction: { type: Object, default: () => ({ label: 'Click me', icon: 'circle' }) },
    btnActionDisabledEmpty: { type: Boolean, default: true },
    displayInherit: { type: Boolean, default: false },
    isDisabledAddButton: { type: Boolean, default: false },
    isDisabledEditButton: { type: Boolean, default: false },
    label: { type: String, value: '' },
    mask: { type: String, default: null },
    pattern: { type: [String, RegExp], default: null },
    permission: { type: Object, default: () => {} },
    required: { type: Boolean, default: false },
    simple: { type: Boolean, default: true },
    prefixText: { type: String, default: null },
    sufixText: { type: String, default: null },
    upper: { type: Boolean, default: true },
    useActionBtn: { type: Boolean, default: false },
    type: { type: String, default: 'text' },
    validationMessage: { type: String, default: '' },
    value: { type: [String, Number], default: '' }
  }
};
</script>

<style lang="sass" scoped>
.hasButtons
  margin-right: 0
.btnAction
  z-index: 5
.order-number-field
  margin-bottom: 0.7rem
  ::v-deep
    input
      border-color: $red-800
.Field
  margin-bottom: .75rem
  &.d-inherit
    position: inherit!important
  .control
    width: 100%
  .s-control
    width: auto
  ::v-deep
    .a-field
      .control
        width: 100%!important
    .has-addons::not(.a-field)
      .control
        margin-right: 0
        width: calc(100% - 40px)!important
    .has-addons
      width: 100%
      display: flex!important
      button
        border-top-left-radius: 0
        border-bottom-left-radius: 0
      .v-popover
        &:nth-child(1n + 1):not(:last-child)
          button
            border-radius: 0
        &:last-child
          button
            border-top-left-radius: 0
            border-bottom-left-radius: 0

    .help.counter
      right: 0
      position: absolute
      bottom: -18px
    .has-icons-right
      .input
        padding-right: 1.7em
      .icon.is-right
        width: 2em
        i::before
          font-size: 18px
    .label
      color: $field-label
      margin-bottom: 0
      font-size: $f-md
      font-weight: bold
      margin-bottom: 3px
    .help.counter
      &.is-invisible
        display: none
  &.required
    ::v-deep
      label
        &:before
          content: '*'
          margin-right: 5px
          color: red
.accent-color
  width: .25rem
  height: 34px
  margin-top: 3px
  position: absolute
  margin-left: 3px
  z-index: 1
  border-radius: 4px
</style>

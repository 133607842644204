import store from '@/store';

class Secure {
  constructor() {}
  permissionValidator(permission) {
    // return routeId ? !!store.getters.permissions.find((id) =>  P === permission) : true;
    //!Remove below
    if (!permission) return true;
    const isAllowed = !!store.getters.permissions.find(
      ({ path, method }) => path === permission.path && method === permission.method
    );
    return isAllowed;
  }
}

export default new Secure();

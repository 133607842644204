<template>
  <Autocomplete
    :api="apiData"
    :label="$t('global.fundingSrc')"
    v-model="myModel"
    model="id"
    field="name"
    setFirst
    :required="required"
    :tabindex="tabindex"
    :awaitFirstValue="awaitFirstValue"
    :loading.sync="loading"
  />
</template>

<script>
import { Autocomplete } from '@/components';
export default {
  components: {
    Autocomplete
  },
  mounted() {
    this.$emit('update:loading', true);
  },
  data() {
    return {
      loading: true,
      myModel: this.value
    };
  },
  computed: {
    apiData() {
      return {
        url: '/funding_sources',
        params: [{ id: 'is_active', value: 1 }],
        full: true
      };
    }
  },
  watch: {
    loading(value) {
      this.$emit('update:loading', value);
    },
    value(value) {
      this.myModel = value;
    },
    myModel(value) {
      this.$emit('input', value);
    }
  },
  props: {
    awaitFirstValue: { type: Boolean, default: false },
    required: { type: Boolean, default: false },
    value: { type: String, default: '' },
    tabindex: { type: String, default: '' }
  }
};
</script>

<style lang="scss" scoped></style>

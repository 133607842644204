<template>
  <b-field v-if="hasPermission" :grouped="grouped" class="iSwitch">
    <b-field :label="label">
      <b-switch v-model="model" @input="onInput" :disabled="disabled || loading">
        {{ text }}
        <b-icon v-if="loading" icon="loading" custom-class="mdi-spin" />
      </b-switch>
    </b-field>
    <slot />
  </b-field>
</template>

<script>
export default {
  data() {
    const model = this.value === null ? this.valueIfNull : this.value;
    return {
      type: 'boolean',
      model: model ? true : false
    };
  },
  computed: {
    hasPermission() {
      let isAllowed = false;
      if (this.permission?.length) {
        isAllowed = this.permission.some((permission) =>
          this.Secure.permissionValidator(permission)
        );
      }
      return isAllowed || this.Secure.permissionValidator(this.permission);
    }
  },
  mounted() {
    if (this.value === null && !this.preventInitialEmit) this.onInput(this.valueIfNull);
    this.type = typeof this.value;
  },
  methods: {
    onInput(value) {
      if (!this.disabled) {
        this.$emit('input', this.boolean ? value : value ? 1 : 0);
      }
    }
  },
  watch: {
    value(value) {
      this.model = value ? true : false;
    }
  },
  props: {
    boolean: { type: Boolean, default: false },
    disabled: { type: Boolean, default: false },
    grouped: { type: Boolean, default: false },
    label: { type: String, default: '' },
    loading: { type: Boolean, default: false },
    preventInitialEmit: { type: Boolean, default: false },
    text: { type: String, default: '' },
    value: { default: null },
    valueIfNull: { type: Boolean, default: false },
    permission: { type: Object, default: () => {} }
  }
};
</script>
<style lang="sass" scoped>
.switch
  ::v-deep
    .control-label
      display: flex
</style>

<style lang="sass">
.dark
  .iSwitch .switch input[type=checkbox]:checked + .check
    background: $primary-dark
</style>

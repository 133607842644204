<template>
  <div :class="statusClass" class="flex eta-timing">
    <span>
      <DateTime
        :date="eta"
        :label="$t('schedule.manifest.eta.short')"
        format="hh:mm A"
        :offset="offset"
      />
    </span>
    <template v-if="useIcon">
      <b-icon v-if="statusClass == 'early'" icon="clock-fast" />
      <b-icon v-if="statusClass == 'late'" icon="clock-alert-outline" />
    </template>
  </div>
</template>

<script>
import { DateTime } from '@/components';
export default {
  components: {
    DateTime
  },
  computed: {
    statusClass() {
      let statusClass = '';
      const { eta, date } = this;
      if (eta && date) {
        const difference = this.$moment(eta).diff(this.$moment(date), 'minutes');
        const range = 10;
        if (difference === 0) statusClass = 'regular';
        else if (difference < range) statusClass = 'early';
        else if (difference > range) statusClass = 'late';
      }
      return statusClass;
    }
  },
  props: {
    date: { type: String, default: null },
    offset: { type: Number, default: null },
    eta: { type: String, default: null },
    useIcon: { type: Boolean, required: false }
  }
};
</script>

<style lang="sass" scoped>
.early
  color: $primary
.late
  color: $red-500
.icon
  margin-left: 5px
</style>

<template>
  <div class="item" :class="classes">
    <div class="ci-title">{{ title }}</div>
    <div :class="textTransform">
      <template v-if="!value && !$slots.default"> - </template>
      {{ value }}
      <slot />
    </div>
  </div>
</template>

<script>
export default {
  computed: {
    classes() {
      const expanded = this.expanded ? 'is-expanded' : '';
      const border = this.border ? `b-${this.border}` : '';
      return `${expanded} ${border}`;
    },
    textTransform() {
      return `ci-content ${this.upper ? 'is-uppercase' : ''}`;
    }
  },
  props: {
    title: {
      type: String,
      required: true,
      default: ''
    },
    value: {
      type: [String, Number],
      default: ''
    },
    expanded: {
      type: Boolean,
      default: false
    },
    border: {
      type: String,
      default: ''
    },
    upper: {
      type: Boolean,
      default: true
    }
  }
};
</script>

<style lang="sass" scoped>
.item
  margin: 0 30px 5px 0
  &.is-expanded
    width: 100%
  &.b-left
    padding-left: 10px
    border-left: 1px solid $main-separators
  .ci-title
    font-size: $f-md
    line-height: $f-md
    color: $gray-600
    margin-bottom: 0px
    font-weight: 500
  .ci-content
    font-size: $f-md
    color: $gray-700
  .is-uppercase
    text-transform: uppercase !important
</style>

<template>
  <Card
    v-if="hasPermission"
    class="pastServices"
    :title="$t('pastServices.title')"
    flexContent
    condensed
  >
    <slot />
  </Card>
</template>

<script>
import Card from '@/components/Card.vue';
import { Permissions } from '@/utils/Secure';

export default {
  computed: {
    hasPermission() {
      return this.Secure.permissionValidator(Permissions.ActivityLogger.read);
    }
  },
  data() {
    return {};
  },
  components: { Card }
};
</script>

<style lang="sass" scoped>
.pastServices
  margin: 0!important
  padding: 0!important
  ::v-deep
    .card-content
      padding: 15px 0 0!important
      margin: 0 -5px!important
</style>

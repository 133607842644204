<template>
  <Modal
    id="modalCopayment"
    :isOpen.sync="isModalOpen"
    :loading="loading"
    :isDisabled="isLoading"
    :header="$t('copayment.manage')"
    allowOverflow
    size="is-auto"
    @cancel="() => {}"
    @save="onSubmit"
  >
    <form ref="form" @submit.prevent="onSubmit">
      <CopaymentField focus v-model="copayment_amount" :disabled="isLoading" />
    </form>
    <template #buttons>
      <Button
        :label="$t('copayment.cancel')"
        @click="handleCancelCopayment"
        type="is-danger"
        outlined
        :loading="loading.cancel"
        :disabled="isLoading || !deliveryCopayment_amount"
      />
    </template>
  </Modal>
</template>

<script>
import { toast, confirm } from '@/utils/dialog';
import { Modal, Button, CopaymentField } from '.';

export default {
  components: {
    Modal,
    CopaymentField,
    Button
  },
  data() {
    return {
      isModalOpen: false,
      loading: { save: false, cancel: false },
      copayment_amount: this.delivery?.copayment_amount || null
    };
  },
  computed: {
    deliveryCopayment_amount() {
      return this.delivery?.copayment_amount;
    },
    isLoading() {
      const { save, cancel } = this.loading;
      return save || cancel;
    }
  },
  methods: {
    async handleCancelCopayment() {
      try {
        await confirm({
          title: this.$t('copayment.cancel'),
          message: this.$t('copayment.cancelMessage'),
          confirmText: this.$t('confirm.yes')
        });
        this.onSubmit(null, true);
      } catch (error) {
        console.log('err');
      }
    },
    async onSubmit(event, isCancelCopayment) {
      try {
        let { copayment_amount = 0 } = isCancelCopayment ? {} : this;
        isCancelCopayment ? (this.loading.cancel = true) : (this.loading.save = true);

        const { id, delivery_manifest_id } = this.delivery;
        const params = { copayment_amount };

        await this.Api.put(
          `/delivery_manifest/${delivery_manifest_id}/delivery_events/${id}/copayment`,
          params
        );

        toast('success', this.$t('messages.saved'), 5000);
        this.$emit('saved', copayment_amount);
        this.isModalOpen = false;
      } catch (error) {
        console.error(error);
      }
      this.loading = { save: false, cancel: false };
    }
  },
  watch: {
    isOpen(value) {
      this.isModalOpen = value;
    },
    isModalOpen(value) {
      this.$emit('update:isOpen', value);
    },
    delivery(value) {
      this.copayment_amount = value?.copayment_amount || null;
    }
  },
  props: {
    isOpen: { type: Boolean, default: false },
    delivery: { type: Object, default: () => {} }
  }
};
</script>

<style lang="sass" scoped>
.Field
  margin-left: auto
  margin-right: auto
  max-width: 250px
</style>

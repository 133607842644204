<template>
  <b-dropdown
    v-if="isLoading || allowedItems.length > 0 || !hideIfNotAllowedItems"
    :class="showLabel ? '' : 'dropdown-icon'"
    :scrollable="scrollable"
    :max-height="maxHeight"
    append-to-body
    v-model="currentItem"
    aria-role="list"
    :position="position"
    v-bind="{ ...$props, ...$attrs }"
  >
    <template #trigger>
      <b-icon v-if="loading" icon="loading" class="icons" loading custom-class="mdi-spin" />
      <Button
        v-else-if="useButton"
        class="dropdown-trigger-button"
        :tooltip="!showLabel && label ? label : null"
        type="is-primary"
        :icon-right="icon"
        :skeletonLoading="skeletonLoading"
        outlined
        :stopPropagation="false"
      >
        <template v-if="showLabel">
          {{ label }}
        </template>
      </Button>
      <Link v-else :label="label" :position="tooltipPosition">
        {{ currentLabel || placeholder }}
        <b-icon :icon="icon" class="icons" />
      </Link>
    </template>
    <template v-if="allowedItems.length">
      <slot v-if="useCustomItem" v-bind:items="allowedItems" />
      <b-dropdown-item
        v-else
        v-for="(item, index) in allowedItems"
        :key="index"
        :value="item"
        aria-role="listitem"
        @click="onClick(item)"
      >
        {{ item[model] }}
      </b-dropdown-item>
    </template>

    <Empty v-else isCompact />
  </b-dropdown>
</template>

<script>
import { Link, Empty, Button } from './';

export default {
  components: {
    Empty,
    Link,
    Button
  },
  mounted() {
    if (this.selectFirst && this.allowedItems[0]) this.currentItem = this.allowedItems[0];
  },
  data() {
    return {
      currentItem: { [this.model]: this.$t('global.select') }
    };
  },
  computed: {
    isLoading() {
      return this.skeletonLoading || this.loading;
    },
    currentLabel() {
      return this.showLabel ? this.currentItem[this.model] : '';
    },
    allowedItems() {
      return this.items.filter((item) => {
        return item.permission ? this.Secure.permissionValidator(item.permission) : item;
      });
    }
  },
  methods: {
    onClick(item) {
      this.$emit('click', item);
    }
  },
  watch: {
    currentItem(val) {
      this.$emit('input', val);
    },
    value(val) {
      this.currentItem = val;
    }
    // items(value) {
    //   this.items
    // }
  },

  props: {
    useCustomItem: { type: Boolean, default: false },
    icon: { type: String, default: 'menu-down' },
    items: { type: Array, default: () => [] },
    label: { type: String, default: '' },
    placeholder: { type: String, default: null },
    hideIfNotAllowedItems: { type: Boolean, default: true },
    useButton: { type: Boolean, default: false },
    position: { type: String, default: null },
    maxHeight: { type: Number, default: 250 },
    scrollable: { type: Boolean, default: false },
    selectFirst: { type: Boolean, default: true },
    loading: { type: Boolean, default: false },
    skeletonLoading: { type: Boolean, default: false },
    showLabel: { type: Boolean, default: true },
    tooltipPosition: { type: String },
    triggerSize: { type: String, default: 'is-medium' },
    value: { type: Object, default: () => {} },
    model: { type: String, default: 'value' }
  }
};
</script>

<style lang="sass" scoped>
.dropdown
  .dropdown-trigger-button
    min-width: 40px
    min-height: 40px
  &.dropdown-icon
    .button.is-primary:not(.dropdown-trigger-button)
      background: inherit
      color: $primary
      padding: 0px
      margin: 0 0.5rem
      height: 24px
    .button:focus:not(:active), .button.is-focused:not(:active)
      box-shadow: none!important
    .dropdown-menu
      background: white
    .dropdown-item.is-active
      background: inherit
      color: $disable-dark
.is-disabled
  ::v-deep
    .icons
      color: $disable-dark !important
</style>

<style lang="sass" scoped>
.dark
  .is-disabled
    ::v-deep
      .dropdown-trigger
        a
          color: $gray-600
  .dropdown
    &.dropdown-icon
      .dropdown-item.is-active
        color: $gray-200
</style>

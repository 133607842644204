import { showToastError } from '@/utils/Api.js';
import { toast } from '@/utils/dialog';

export const delayer = async (timeout) =>
  new Promise((resolve) => setTimeout(() => resolve(), timeout));

export const exceptionMessage = ({ error, messageError, callBack }) => {
  return new Promise((resolve) => {
    const { message, status, status_code } = error;
    const statusCodesDuplicateError = ['ud023', 'dsch037', 'di023', 'ORDER_NUMBER_EXIST'];

    if (status === 400 && statusCodesDuplicateError.includes(status_code)) {
      toast('error', messageError || message, 5000);
      callBack();
    } else showToastError(status, message);

    resolve();
  });
};

<template>
  <div id="EditParticipantModal">
    <Modal
      id="EditParticipant"
      size="is-medium"
      :isOpen.sync="isModalOpen"
      :header="$t('schedule.editParticipantInfo')"
      :loading="loading"
      :buttonLabel="$t('button.confirm')"
      @cancel="() => {}"
      @save="onSubmit"
    >
      <template #skeleton>
        <b-loading :active="true" :is-full-page="false" />
      </template>
      <form ref="form">
        <EditParticipant
          :value="currentParticipant"
          @change="updateParticipant"
          :participantId="participantId"
        />
        <div class="columns">
          <Autocomplete
            class="column"
            :label="$t('schedule.delivery')"
            :loading="loading.addresses"
            :data="addresses"
            :value="oDelivery.destination_full_address"
            field="full_address"
            @select="onSelectAddress"
            :isDisabledEditButton="buttonAddress"
            @edit="onEditAddress"
            showResultsCounter
          />
        </div>
        <AddressPicker
          ref="addressPicker"
          v-if="showAddressPicker"
          v-model="participantAddress"
          :isEdit="addressPickerIsEdit"
          @change="updateAddress"
        />
      </form>
    </Modal>
  </div>
</template>

<script>
import { Modal, EditParticipant, Autocomplete, AddressPicker } from '@/components';
import { toast } from '@/utils/dialog';

export default {
  components: { Modal, EditParticipant, Autocomplete, AddressPicker },
  data() {
    return {
      currentParticipant: {},
      newParticipant: null,
      newAddress: null,
      address: {},
      addresses: [],
      showAddressPicker: false,
      oDelivery: {},
      loading: { save: false, get: false, addresses: false, manifest: false },
      isModalOpen: false,
      buttonAddress: true,
      addressPickerIsEdit: false,
      participantAddress: {}
    };
  },
  computed: {
    participantId() {
      return this.delivery?.participant_id;
    }
  },
  methods: {
    async getData() {
      this.oDelivery = { ...this.delivery };
      this.loading.get = true;
      const { data } = await this.Api.get(`participants/${this.participantId}`);
      const participant = data;
      this.currentParticipant = participant;
      await this.getAddresses();
      this.loading.get = false;
    },
    async editPutAddress() {
      try {
        const {
          address_1,
          address,
          city,
          formatted_address,
          gate_code,
          latitude,
          longitude,
          state,
          zipcode
        } = this.newAddress;
        const {
          id,
          participant_address_id,
          participant_address_latitude,
          participant_address_longitude
        } = this.currentParticipant;

        const params = {
          address_1,
          address,
          city,
          formatted_address,
          gate_code,
          latitude: latitude || participant_address_latitude,
          longitude: longitude || participant_address_longitude,
          state,
          zipcode
        };

        await this.Api.put(`participants/${id}/addresses/${participant_address_id}`, params);
      } catch (error) {
        console.error(error);
        this.loading.save = false;
      }
    },
    async editPutParticipant() {
      const P = this.newParticipant;
      const params = {
        first_name: P.first_name,
        middle_name: P.middle_name,
        last_name: P.last_name,
        phone_number: P.phone_number,
        mobile_phone_number: P.mobile_phone_number
      };
      await this.Api.put(`participants/${P.id}/main_info`, params);
    },
    async onSelectAddress(currenAddress) {
      if (currenAddress) {
        const differentTo = [
          'created_at',
          'create_by',
          'geofence',
          'is_default',
          'name',
          'participant_id'
        ];

        for (const property in currenAddress) {
          if (!differentTo.includes(property)) {
            if (!this.currentParticipant[`participant_address_${property}`]) {
              this.currentParticipant[`participant_${property}`] = currenAddress[property];
            } else {
              this.currentParticipant[`participant_address_${property}`] = currenAddress[property];
            }
          }
        }
        this.currentParticipant.participant_address_id = currenAddress.id;
        if (this.showAddressPicker) this.onEditAddress();
      }
    },
    updateParticipant(participant) {
      this.newParticipant = participant;
    },
    updateAddress(address) {
      this.newAddress = address;
    },
    async updateDelivery() {
      const destinationId = this.currentParticipant.participant_address_id;
      await this.Api.post(
        `delivery_manifest/${this.oDelivery.delivery_manifest_id}/delivery_events/${this.oDelivery.id}/re_schedule_override`,
        {
          destination_id: destinationId
        }
      );
    },
    async onSubmit() {
      if (this.validation()) {
        this.loading.save = true;
        try {
          if (this.newParticipant) await this.editPutParticipant();
          if (this.addressPickerIsEdit && this.newAddress) await this.editPutAddress();
          await this.updateDelivery();
          toast('success', this.$t('messages.saved'), 5000);
          this.$emit('updateEvents');

          this.clean();
        } catch (error) {
          console.log(error);
        }
        this.loading.save = false;
      }
    },
    validateFrom() {
      if (!this.$refs.form.checkValidity()) {
        this.$refs.form.reportValidity();
        return false;
      }
      return true;
    },
    validation() {
      let isValid = false;
      if (this.validateFrom() && !this.addressPickerIsEdit) {
        isValid = true;
      } else if (
        this.validateFrom() &&
        this.$refs.addressPicker.validate() &&
        this.addressPickerIsEdit
      ) {
        isValid = true;
      }

      return isValid;
    },
    clean() {
      this.showAddressPicker = false;
      this.newParticipant = null;
      this.newAddress = null;
      this.buttonAddress = true;
      this.addressPickerIsEdit = false;
      this.isModalOpen = false;
      this.currentParticipant = {};
      this.address = {};
      this.addresses = [];
      this.oDelivery = {};
      this.participantAddress = {};
    },
    onEditAddress() {
      const {
        participant_address,
        participant_address_1,
        participant_address_city,
        participant_gate_code,
        participant_address_latitude,
        participant_address_longitude,
        participant_address_zipcode,
        participant_address_state
      } = this.currentParticipant;

      this.participantAddress = {
        address: participant_address,
        address_1: participant_address_1,
        city: participant_address_city,
        gate_code: participant_gate_code,
        zipcode: participant_address_zipcode,
        latitude: participant_address_latitude,
        longitude: participant_address_longitude,
        state: participant_address_state
      };

      this.showAddressPicker = true;
      this.addressPickerIsEdit = true;
    },
    setAddress(address_id, full_address) {
      this.currentParticipant.participant_address_id = address_id;
      this.currentParticipant.participant_full_address = full_address;
    },
    async getAddresses() {
      this.loading.addresses = true;
      this.addresses = [];
      try {
        const { data } = await this.Api.get(`participants/${this.participantId}/addresses`);
        this.addresses = data;
        if (!this.currentParticipant.participant_full_address) {
          if (this.addresses.length > 0) {
            this.setAddress(this.oDelivery.destination_id, this.oDelivery.destination_full_address);
            this.addresses.find((address) => {
              if (address.id === this.oDelivery.destination_id) {
                this.onSelectAddress(address);
              }
            });
            this.buttonAddress = false;
          } else this.setAddress(null, null);
        }
      } catch (error) {
        console.error(error);
      }
      this.loading.addresses = false;
    }
  },
  watch: {
    async isOpen(value) {
      this.isModalOpen = value;
      if (!value) this.clean();
      if (value) {
        this.getData();
      }
    },
    async isModalOpen(value) {
      this.$emit('update:isOpen', value);
    }
  },
  props: {
    isOpen: { type: Boolean, default: false },
    delivery: { type: Object, default: () => {} }
  }
};
</script>

<style lang="sass" scoped>
#EditParticipant
  ::v-deep
    .modal-content
      min-height: 432px
    .modal-content-container .slot
      overflow: visible!important
  .subTitle
    font-size: $f-lg
    font-weight: bold
    margin-bottom: 1rem
  .addOn-place
    width: calc(50% - .75rem)
  .addOn-sequence
    margin: 1.5rem 0 1.5rem 0
  .show
    display: flex
  .hide
    display: none
  .column
    width: 100%
  .address-picker
    ::v-deep
      .ap-address
        width: 100%
      .ap-address1,
      .ap-zipcode,
      .ap-state
        width: 30%
      .ap-city
        width: 67.8%
        margin-right: 0
    .ap-position
      padding-top: 20px
  ::v-deep
    .field .is-grouped
      flex-wrap: wrap
      .field
        margin-bottom: 10px
      .input
        padding-left: 0.75rem !important
    .autocomplete .dropdown-menu
      width: 100%
      p
        white-space: nowrap
        overflow: hidden
        text-overflow: ellipsis
</style>

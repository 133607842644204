import axios from 'axios';
import { apiEndPoint } from '@/config/constants.js';
import { toast } from '@/utils/dialog';
import store from '@/store';

export const showToastError = (status, error) => {
  const aDigitStatus = status && Math.trunc(status / 100);
  if (aDigitStatus === 4) toast('alert', error, 5000);
  if (!aDigitStatus || aDigitStatus === 5) toast('error', error, 5000);
  if (error === 'Network Error') {
    toast('error', error, 5000);
  }
};
export class ApiClass {
  constructor() {
    this.requests = [];
    this._axios = axios.create({
      baseURL: apiEndPoint || '',
      // timeout: 1000,
      headers: {}
    });
    this.proccessErrors = true;
    this._interceptors();
  }
  set ErrorProcess(value) {
    this.proccessErrors = value;
  }
  set Authorization(AUTH) {
    this._axios.defaults.headers.Authorization = AUTH;
  }
  set baseUrl(url) {
    this._axios.defaults.baseURL = url;
  }
  get baseURL() {
    return this._axios.defaults.baseURL;
  }
  get cancelToken() {
    return axios.CancelToken.source();
  }
  _clean(obj) {
    for (var propName in obj) {
      if (String(obj[propName]).replace(/ /g, '') === '' || obj[propName] === undefined) {
        // delete obj[propName];
        obj[propName] = null;
      }
    }
    return obj;
  }
  _interceptors() {
    this._axios.interceptors.request.use((request) => {
      if (request.data?.cancelToken) delete request.data.cancelToken;
      const language = store.getters.LANG === 'en' ? 'us' : store.getters.LANG;
      request.headers['Content-Language'] = language;
      request.data = this._clean(request.data);
      // console.warn('::::::', request.url, request?.data);⛔️❗️
      return request;
    });
    this._interceptorsResponse();
  }
  _validateStringDataError(data) {
    if (!data) data = [];
    if (typeof data == 'string') return data;
    if (data.length == undefined) data = [data];
    const aTexts = data.filter((message) => typeof message == 'string');
    if (aTexts.length) return aTexts.join('<br />');
    else return null;
  }
  _interceptorsResponse() {
    this._axios.interceptors.response.use(
      (response) => {
        if (String(response.status).match(/20[0-9]/g)) {
          return response?.data;
        } else {
          console.warn('❗️ Request', { response });
        }
        return response;
      },
      (err) => {
        const { response, message } = err || {};
        const aborted = axios.isCancel(err);
        if (aborted) return Promise.reject({ data: [], error: err, aborted });
        const { status, data } = response || {};
        const dataText = this._validateStringDataError(data?.data);
        const text = this._validateStringDataError(data?.text);
        const dataSourceError = data?.source_error || data?.status_code;
        const error = dataText || text || `${dataSourceError || ''} - ${message}`;

        if (this.proccessErrors) showToastError(status, error);
        else {
          this.proccessErrors = true;
          throw { message: error, status, status_code: err.response.data.status_code };
        }

        let core_response = { status, data, error, aborted };
        // if (status === 403) {
        // ? Validate another error codo to closed sesion
        // store.dispatch('deAUTH', { allowSignOut: false, redirect: true });
        // } else {
        return Promise.reject(core_response);
        // }
      }
    );
  }
  async _pendingRequests() {
    const statusPromises = [];
    this.requests.map((request) => {
      statusPromises.push(this._requestState(request));
    });
    const statutes = await Promise.all(statusPromises);
    return statutes.filter((r) => r.status !== 'fulfilled');
  }
  _requestState(request) {
    const t = {};
    return Promise.race([request.axios, t]).then(
      (v) => (v === t ? { request, status: 'pending' } : { request, status: 'fulfilled' }),
      () => ({ request, status: 'rejected' })
    );
  }
  async cancelRequest({ regexRoute }) {
    const pending = await this._pendingRequests();
    pending.map((p) => {
      if (regexRoute && !!p.request.url.match(regexRoute))
        p.request.cancelSource.cancel(`Avoid multiple by ${regexRoute}`);
    });
  }

  makeRequest(request) {
    // this.cancelRequest(request)
    const { method, data, url, headers = {} } = request;
    const cancelSource = this.cancelToken;
    const axiosRequest = {
      cancelToken: cancelSource.token,
      ...(data || {})
    };
    const R = { url: `${method}:${url}`, cancelSource };
    R.axios = this._axios[method](`${url}`, axiosRequest, { headers });
    this.requests.push(R);
    return R.axios;
  }
  delete(url, data = {}) {
    return this.makeRequest({ method: 'delete', url, data: { data } });
  }
  get(url, data = {}) {
    return this.makeRequest({ method: 'get', url, data });
  }
  post(url, data = {}, headers) {
    return this.makeRequest({ method: 'post', url, data, headers });
  }
  put(url, data = {}, headers) {
    return this.makeRequest({ method: 'put', url, data, headers });
  }
  patch(url, data = {}) {
    return this.makeRequest({ method: 'patch', url, data });
  }
}
export default new ApiClass();

import { google } from '../config/availableMapTypes';
import { autoZoomError, showError } from './errors';

export const isGoogleMap = () => {
  return window.localStorage.getItem('NMap_mapType') === google;
};

export const $maxZoom = (map, maxZoom) => {
  const zoom = map.getZoom();
  if (zoom > maxZoom) map.setZoom(Number(maxZoom));
};

export const $autoZoom = ({ map, bounds, maxZoom, adjustZoom }, onSuccess) => {
  try {
    if (!bounds) throw autoZoomError.requieredBounds;
    if (bounds.length < 2) throw autoZoomError.minBounds;
    onSuccess();
    if (adjustZoom !== 0) map.setZoom(map.getZoom() + adjustZoom);
    if (maxZoom) $maxZoom(map, maxZoom);
  } catch (error) {
    showError(error);
  }
};

export const $decodePath = (encoded) => {
  if (!encoded) {
    return [];
  }
  var poly = [];
  var index = 0,
    len = encoded.length;
  var lat = 0,
    lng = 0;
  while (index < len) {
    var b,
      shift = 0,
      result = 0;
    do {
      b = encoded.charCodeAt(index++) - 63;
      result = result | ((b & 0x1f) << shift);
      shift += 5;
    } while (b >= 0x20);
    var dlat = (result & 1) != 0 ? ~(result >> 1) : result >> 1;
    lat += dlat;
    shift = 0;
    result = 0;
    do {
      b = encoded.charCodeAt(index++) - 63;
      result = result | ((b & 0x1f) << shift);
      shift += 5;
    } while (b >= 0x20);
    var dlng = (result & 1) != 0 ? ~(result >> 1) : result >> 1;
    lng += dlng;
    var p = { lat: lat / 1e5, lng: lng / 1e5 };
    poly.push(p);
  }
  return poly;
};

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('Modal',{attrs:{"id":"ChangeStatusManifest-Modal","isOpen":_vm.isModalOpen,"header":_vm.fix ? _vm.$t('global.fixIssue') : _vm.$t('dispatch.changeStatus'),"size":"is-small","buttonLabel":_vm.$t('confirm.yes'),"loading":_vm.loading,"saveDisabled":_vm.loading.reason},on:{"update:isOpen":function($event){_vm.isModalOpen=$event},"update:is-open":function($event){_vm.isModalOpen=$event},"cancel":true,"save":_vm.onSubmit}},[_c('form',{ref:"form",staticClass:"change-form",on:{"submit":function($event){$event.preventDefault();return _vm.onSubmit.apply(null, arguments)}}},[_c('b-field',[_c('Autocomplete',{staticClass:"required mb-5 mt-3",attrs:{"placeholder":_vm.$t('global.select'),"icon":"label","api":{
          url: 'catalogs/delivery_event_status',
          params: [{ id: 'event_type_id', value: _vm.eventTypeId }],
          full: true
        },"label":_vm.$t('manifest.selectNewStatus'),"model":"id","field":"name","required":"","disabled":_vm.loading.save ? true : false,"dataPreProcessor":_vm.deliveriesDataPreProcessor},scopedSlots:_vm._u([{key:"results",fn:function(ref){
        var data = ref.data;
return [_vm._v(" "+_vm._s(_vm.statutes[data.name] || data.name)+" ")]}}]),model:{value:(_vm.newEventId),callback:function ($$v) {_vm.newEventId=$$v},expression:"newEventId"}})],1),(_vm.isUnsuccessfulStatus)?_c('b-field',[_c('Field',{attrs:{"label":_vm.$tc('global.comment', 0),"type":"textarea","upper":false,"rows":2},model:{value:(_vm.comments),callback:function ($$v) {_vm.comments=$$v},expression:"comments"}})],1):_vm._e(),(_vm.isUnsuccessfulStatus)?_c('b-field',[_c('Autocomplete',{staticClass:"required mb-5 mt-3",attrs:{"label":_vm.$t('manifest.selectReason'),"placeholder":_vm.$t('global.select'),"api":{
          url: '/catalogs/non_delivery_reason',
          full: true
        },"model":"id","field":"description","disabled":_vm.loading.save ? true : false,"loading":_vm.loading.reason,"required":""},on:{"update:loading":function($event){return _vm.$set(_vm.loading, "reason", $event)}},model:{value:(_vm.reasonId),callback:function ($$v) {_vm.reasonId=$$v},expression:"reasonId"}})],1):_vm._e(),(_vm.isUnsuccessfulStatus && _vm.reasonId === 4)?_c('b-field',[_c('Field',{attrs:{"label":_vm.$t('manifest.otherReason'),"type":"textarea","upper":false,"rows":2,"required":""},model:{value:(_vm.otherReason),callback:function ($$v) {_vm.otherReason=$$v},expression:"otherReason"}})],1):_vm._e()],1)])}
var staticRenderFns = []

export { render, staticRenderFns }
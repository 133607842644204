<template>
  <Tooltip
    v-if="isTag"
    :label="`${$t('global.is')} ${$t('global.refrigerated')}`"
    :active="!showText"
    :position="tooltipPosition"
    class="Tooltip"
  >
    <b-tag class="refrigerated-tag" rounded>
      <div class="flex f-ai">
        <b-icon class="refrigerated-icon" icon="snowflake" size="is-small" />
        <span v-if="showText" class="refrigerated-text">{{ $t('global.refrigerated') }}</span>
      </div>
    </b-tag>
  </Tooltip>
  <div v-else>
    <b-icon class="refrigerated-icon" icon="snowflake" size="is-small" />
    <span v-if="showText" class="refrigerated-text">{{ $t('global.refrigerated') }}</span>
  </div>
</template>

<script>
import Tooltip from '@/components/Tooltip';
export default {
  components: {
    Tooltip
  },
  props: {
    showText: { type: Boolean, default: false },
    isTag: { type: Boolean, default: true },
    tooltipPosition: { type: String, default: 'top' }
  }
};
</script>

<style lang="sass" scoped>
.Tooltip
  display: inline-flex
  ::v-deep
    & + *
      margin-left: 3px
.refrigerated-tag
  padding: 14px 10px
  background: $blue-100
  border: 1px solid $gray-100
  margin-bottom: 0.2rem
.refrigerated-icon,
.refrigerated-text
  font-size: 14px
  color: $blue-700
</style>
<style lang="sass">
.dark
  .refrigerated-icon,
  .refrigerated-text
    color: white
  .refrigerated-tag
    background: $blue-600
    border: none
</style>

<template>
  <component
    :is="markerType"
    v-bind="$props"
    @click="(e) => $emit('click', e)"
    @drag="(e) => $emit('drag', e)"
    @dragend="(e) => $emit('dragend', e)"
  >
    <!-- !PERFORMANCE WARNING -->
    <!-- !USE SLOTS ONLY IF THERE IS A FEW MARKERS -->
    <template #infowindow v-if="$slots.infowindow"><slot name="infowindow" /></template>
    <slot v-if="$slots.default" />
    <!-- !END PERFORMANCE WARNING -->
    <div v-if="iconImg || icon || infobox" class="markerContainer" :style="markerContainerStyle">
      <img v-if="iconImg" class="iconImg" :src="iconImg" />
      <IconMarker
        v-if="icon"
        :icon="icon"
        :color="iconColor"
        :secondaryColor="iconSecondaryColor"
        :size="iconSize"
      />
      <div ref="infobox" v-if="infobox" class="infobox">{{ infobox }}</div>
    </div>
  </component>
</template>
<script>
// import gMapMarker from './gMaps/gMapsMarker';
// import osMarker from './OSM/osMarker';
import { isGoogleMap } from './helper';
import { IconMarker } from '@/components';

export default {
  components: {
    gMapMarker: () => import('./gMaps/gMapsMarker'),
    osMarker: () => import('./OSM/osMarker'),
    IconMarker
  },
  mounted() {
    if (Object.keys(this.$slots).length) {
      console.warn('WARNING:', 'Use of marker slot may cause low performance');
    }
    this.infoboxAdjust();
  },
  beforeDestroy() {
    this.$emit('removed');
  },
  data() {
    return {
      markerContainerStyle: {}
    };
  },
  computed: {
    isGmaps() {
      return isGoogleMap();
    },
    markerType() {
      return this.isGmaps ? 'gMapMarker' : 'osMarker';
    }
  },
  methods: {
    infoboxAdjust() {
      setTimeout(() => {
        const height = this.$refs?.infobox?.clientHeight || 0;
        if (height) this.markerContainerStyle = { transform: `translateY(${height}px)` };
      }, 10);
    }
  },
  watch: {
    infobox() {
      this.infoboxAdjust();
      console.log(':::Infobox');
    }
  },
  props: {
    anchor: { type: Array, default: () => [16, 37] },
    autoOpenInfowindow: { type: Boolean, default: false },
    draggable: { type: Boolean, default: false },
    icon: { type: String },
    iconColor: { type: String, default: 'aaaaff' },
    iconSecondaryColor: { type: String, default: null },
    iconImg: { type: String },
    iconSize: { type: Number, default: 35 },
    infobox: { type: String, default: null },
    position: { type: Object, required: true },
    zIndex: { type: Number, default: 1 }
  }
};
</script>
<style lang="sass" scoped>
.markerContainer
  text-align: center
  .infobox
    background: #313B4A
    padding: 2px 5px
    border-radius: 5px
    color: white
    font-weight: bold
    max-width: 50px
</style>

<template>
  <b-skeleton v-if="skeletonLoading" class="button" />
  <Tooltip
    v-else-if="tooltip && !disabled && hasPermission"
    :label="tooltip"
    :position="tooltipPosition"
  >
    <b-button v-bind="{ ...$props, ...$attrs }" @click="onClick">
      <slot />
    </b-button>
  </Tooltip>
  <b-button v-else-if="hasPermission" v-bind="{ ...$props, ...$attrs }" @click="onClick">
    <slot />
  </b-button>
</template>

<script>
import Tooltip from '@/components/Tooltip';
import { confirm } from '@/utils/dialog';

export default {
  components: {
    Tooltip
  },
  computed: {
    hasPermission() {
      let isAllowed = false;
      if (this.permission?.length) {
        isAllowed = this.permission.some((permission) =>
          this.Secure.permissionValidator(permission)
        );
      }
      return isAllowed || this.Secure.permissionValidator(this.permission);
    }
  },
  methods: {
    onClick(e) {
      if (this.stopPropagation) e.stopPropagation();
      if (this.confirmProps) {
        confirm({
          ...this.confirmProps,
          onConfirm: () => {
            this.$emit('click', e);
          }
        });
      } else {
        this.$emit('click', e);
      }
    }
  },
  props: {
    stopPropagation: { type: Boolean, default: true },
    disabled: { type: Boolean, default: false },
    permission: { type: Object, default: () => {} },
    tooltip: { type: String, default: '' },
    tooltipPosition: { type: String, default: 'top' },
    skeletonLoading: { type: Boolean, default: null },
    confirmProps: {
      type: Object,
      default: null
    }
  }
};
</script>

<style lang="sass" scoped>
.b-skeleton
  border: none
  margin-top: 0px
  padding: 0
  width: auto
  ::v-deep
    .b-skeleton-item
      min-height: 40px
      min-width: 40px
</style>
<style lang="sass">
.dark
  .has-custom-container
    .modal-card
      .modal-card-body .media
        .media-content div
          color: $white
</style>

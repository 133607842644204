<template>
  <Field
    :label="label"
    :required="required"
    :message="message"
    :avoidSpecialChars="false"
    displayInherit
  >
    <VueTimepicker
      v-model="time"
      ref="timepicker"
      :minute-interval="minuteInterval"
      :format="format"
      :disabled="disabled"
      :hour-range="rangeByTime.hours || hourRange"
      :minute-range="rangeByTime.minutes || minuteRange"
      :lazy="lazy"
      input-width="100px"
      @change="onTimeChange"
      :drop-direction="dropDirection"
      :container-id="containerId"
      hide-disabled-hours
      hide-disabled-minutes
      hide-disabled-seconds
      auto-scroll
      hide-clear-button
      manual-input
      required
    >
      <template v-slot:icon>
        <b-icon icon="clock-outline" class="tp-icon" :class="disabled ? 'disabled' : ''" />
      </template>
    </VueTimepicker>
  </Field>
</template>

<script>
import VueTimepicker from 'vue2-timepicker';
import 'vue2-timepicker/dist/VueTimepicker.css';
import Field from '@/components/Field';
import moment from 'moment';
import store from '@/store';

export default {
  components: {
    VueTimepicker,
    Field
  },
  mounted() {
    if (this.required) this.$refs.timepicker.$el.children[0].required = true;
    if (this.value) {
      if (!this.convertUtc) moment.tz.setDefault();
      const currentDate = new Date();
      const date = this.$moment(this.value, 'HH:mm:ss')
        .add(this.addHours, 'hours')
        .format('HH:mm:ss');
      const dataTime = date.split(':');
      currentDate.setHours(dataTime[0], dataTime[1], dataTime[2]);
      this.dateTime = this.convertUtc ? currentDate : this.$moment(this.value, 'HH:mm:ss').toDate();
    }
  },
  data() {
    return {
      dateTime: null,
      time: {}
    };
  },
  computed: {
    addHours() {
      const hasOffset = this.offset !== 0;
      return hasOffset ? this.offset : this.$moment().utcOffset() / 60;
    },
    dateTimeToString() {
      if (this.dateTime) {
        if (!this.convertUtc) moment.tz.setDefault();
        return this.convertUtc
          ? this.$moment(this.dateTime.toTimeString().substr(0, 8), 'HH:mm:ss')
              .add(this.addHours * -1, 'hours')
              .format('HH:mm:ss')
          : this.$moment(this.dateTime, 'HH:mm:ss').format('HH:mm:ss');
      } else {
        return null;
      }
    },
    rangeByTime() {
      const ranges = { hours: null, minutes: null, seconds: null };
      if (!this.minTime) return ranges;
      const momentMinTime = this.$moment(this.minTime, 'HH:mm:ss');
      const momentCurrentTime = this.$moment(this.time, 'HH:mm:ss');
      const minHours = momentMinTime.hours();
      const minMinutes = momentMinTime.minutes();
      const currentHours = momentCurrentTime.hours();
      const minutesOfDifference = momentCurrentTime.diff(momentMinTime, 'minutes');

      if (minutesOfDifference < 0) {
        this.onTimeChange({
          data: { H: minHours, m: minMinutes },
          displayTime: this.minTime
        });
      }

      ranges.hours = [[minHours, 23]];
      ranges.minutes = minHours === currentHours ? [[minMinutes, 59]] : null;
      return ranges;
    }
  },
  methods: {
    clear() {
      this.$refs.timepicker.clearTime();
    },
    onInput() {
      this.$emit('input', this.dateTimeToString);
      this.$emit('display', this.time);
      if (!this.convertUtc) {
        moment.tz.setDefault(store.getters.timeZone || undefined);
      }
    },
    onTimeChange({ data, displayTime }) {
      if (displayTime) {
        const date = new Date();
        date.setHours(data.H, data.m, 0);
        this.dateTime = date;
      } else {
        this.dateTime = '';
      }
      this.onInput();
    }
  },
  watch: {
    value(value, oldVal) {
      if (!value && value !== oldVal) return this.clear();
      let currentDate = new Date();
      const date = this.convertUtc
        ? this.$moment(value, 'HH:mm:ss').add(this.addHours, 'hours').format('HH:mm:ss')
        : value;
      const dataTime = date.split(':');
      currentDate.setHours(dataTime[0], dataTime[1], dataTime[2]);
      if (value) this.dateTime = currentDate;
      else this.dateTime = null;
    },
    dateTime(value) {
      if (value) {
        const addZero = (x) => (x < 10 ? `0${x}` : x);
        const hours = addZero(value.getHours());
        const minutes = addZero(value.getMinutes());
        this.time = `${hours}:${minutes}`;
      }
    }
  },
  props: {
    label: { type: String, default: '' },
    value: { type: String, default: '' },
    required: { type: Boolean, default: false },
    convertUtc: { type: Boolean, default: true },
    offset: { type: Number, default: 0 },
    message: { type: String, default: null },
    disabled: { type: Boolean, default: false },
    format: { type: String, default: null },
    dropDirection: { type: String, default: 'auto' },
    containerId: { type: String, default: 'layout' },
    lazy: { type: Boolean, default: false },
    minuteRange: { type: Array, default: () => null },
    minTime: { type: String, default: () => null },
    hourRange: { type: Array, default: () => [[7, 22]] },
    minuteInterval: { type: [Number, String], default: 1 }
  }
};
</script>

<style lang="sass" scoped>
.time-picker
  .tp-icon
    opacity: .2!important
    &.disabled
      opacity: .2!important
  ::v-deep
    input
      border-radius: 3px
      &:focus
        border-color: rgba(82, 101, 247, 0.3)
        box-shadow: 0 0 15px rgb(82 101 247 / 20%)
      &:disabled
        border-color: $gray-200!important
        outline-color: $gray-200!important
    .select-list
      li.active
        background: $primary!important
</style>
<style lang="sass">
.dark
  .time-picker
    input
      background: #2f2c33
      border-color: $dark-500
      color: white
      &:disabled
        cursor: not-allowed
        opacity: .5!important
        border-color: $gray-800!important
        outline-color: $gray-800!important
        color: $gray-400!important
    .custom-icon
      color: $gray-500
    .select-list
      background: $dark-100
      li
        color: white!important
        &.active
          background: $primary-dark!important
</style>

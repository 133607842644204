<template>
  <component
    :is="componentType"
    class="addressViewer"
    :key="address.id"
    :margin-bottom="marginBottom"
  >
    <div v-if="loading" class="flex f-jsb loading">
      <span>
        <b-skeleton v-if="bigPicture" height="65" width="110" />
        <b-skeleton v-else height="50" width="90" />
      </span>
      <div class="fullAddress">
        <b-skeleton height="30" width="100%" />
      </div>
      <div class="actions flex f-ai">
        <b-skeleton height="30" width="30" />
        <b-skeleton height="30" width="30" />
        <b-skeleton height="30" width="30" />
      </div>
    </div>
    <div v-else class="flex" :class="isCard ? 'f-jsb' : ''">
      <Picture
        v-if="isCard"
        :preasigned="preasigned"
        :class="bigPicture ? 'isBig' : ''"
        :key="key"
        :alt="address.full_address"
      />
      <span :class="isCard ? 'fullAddress' : 'address'">
        {{ address.full_address || '-' }}
      </span>
      <div class="actions flex">
        <Link
          v-if="$listeners.setDefault"
          :label="$t('button.setDefault.ttp')"
          icon="star"
          @click="$emit('setDefault', address)"
          :permission="permissions.setDefault"
          :loading="loadingDefault"
        />
        <Link
          v-if="$listeners.edit && !isEditing"
          :label="$t('button.edit')"
          icon="square-edit-outline"
          @click="$emit('edit', address)"
          :permission="permissions.update"
          :disabled="isEditDisabled"
        />
        <Link
          v-if="($listeners.showPosition || showPosition) && address.full_address"
          :label="$t('button.showMap')"
          icon="map-marker"
          @click="onShowPosition(address)"
          :permission="permissions.showOnMap"
        />
        <Link
          v-if="$listeners.remove"
          :label="$t('button.delete')"
          icon="delete"
          @click="$emit('remove', address)"
          :permission="permissions.remove"
          :loading="loadingDelete"
        />
      </div>
    </div>
  </component>
</template>
<script>
import InsideCard from '@/components/InsideCard.vue';
import Picture from '@/components/Picture.vue';
import Link from '@/components/Link';
export default {
  components: {
    InsideCard,
    Picture,
    Link
  },
  destroyed() {
    if (this.status !== 'OK') {
      this.$store.commit('map/update', { action: 'restore' });
    }
  },
  computed: {
    loadingDefault() {
      return this.address.id === this.loadingAdvanced.default;
    },
    loadingDelete() {
      return this.address.id === this.loadingAdvanced.delete;
    },
    componentType() {
      return this.isCard ? 'Inside-card' : 'div';
    },
    preasigned() {
      const { id } = this.address;
      const preasigned = {
        apiUrl: '/presigned/address',
        fileName: `${id}.png`
      };
      return preasigned;
    }
  },
  data() {
    return {
      status: 'OK',
      key: 1
    };
  },
  methods: {
    onShowPosition(data) {
      this.status = 'SHOWN';
      this.$emit('showPosition', data);
      if (!this.$listeners.showPosition) {
        this.$store.commit('map/breadcumbs', this.mapBreadcumbs);
        this.$store.dispatch('map/updateMapWindow', {
          action: 'showPosition',
          data
        });
      }
    },
    updatePresigned() {
      this.key += 1;
    }
  },
  props: {
    address: { type: Object, required: true },
    bigPicture: { type: Boolean, default: false },
    isCard: { type: Boolean, default: false },
    isEditing: { type: Boolean, default: false },
    loading: { type: Boolean, default: false },
    loadingAdvanced: {
      type: Object,
      default: () => {
        return { delete: null, default: null };
      }
    },
    mapBreadcumbs: { type: Array, default: () => [] },
    marginBottom: { type: Number, default: 10 },
    permissions: {
      type: Object,
      default: () => ({ remove: null, setDefault: null, showOnMap: null, update: null })
    },
    showPosition: { type: Boolean, default: true },
    isEditDisabled: { type: Boolean, default: false }
  }
};
</script>

<style lang="sass" scoped>
.loading
  .actions
    .b-skeleton
      margin: 0 1px
.addressViewer
  padding-bottom: 10px
  .Picture
    min-width: 90px
    width: 90px
    height: 55px
    &.isBig
      min-width: 110px
      width: 110px
      height: 65px
  .fullAddress
    width: 100%
    padding: 0 12px
  .actions
    width: fit-content
.card
  border: 1px solid $gray-450
  border-radius: 8px
  ::v-deep
    .card-content>.flex
      align-items: center
  &.shadow
    box-shadow: 0px 0px 0px
</style>

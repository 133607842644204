<template>
  <b-tag
    v-if="isActive"
    class="c-tag"
    :class="type"
    :attached="attached"
    :closable="closable"
    @close="onClose"
  >
    <slot />
  </b-tag>
</template>

<script>
export default {
  data() {
    return {};
  },
  methods: {
    onClose() {
      this.$emit('close');
    }
  },
  props: {
    isActive: { type: Boolean, default: true },
    type: { type: String, default: 'is-neutral' },
    attached: { type: Boolean, default: false },
    closable: { type: Boolean, default: false }
  }
};
</script>

<style lang="sass" scoped>
$tagColors: []!default
.c-tag
  border-radius: 200px
  margin: 0px 5px 10px 0px
  height: 24px
  padding: 8px 16px
  color: $gray-700 !important
  @each $className, $colorObject in $tagColors
    &.is-#{$className}
      background-color: map-get($colorObject, "background") !important
</style>

<style lang="sass">
$tagDarkColors: []!default
.dark
  .c-tag
    color: $main-background !important
    @each $className, $colorObject in $tagDarkColors
      &.is-#{$className}
        background-color: map-get($colorObject, "background") !important
</style>

import router from '@/router';
import { handleLocation } from '@/utils/Geolocation';
import { setCookie, getCookie, removeCookie } from '@/utils/Cookies.js';

var mapWindow = null;
export default {
  namespaced: true,
  state: {
    breadcumbs: null,
    currentPosition: getCookie('CURRENT_POSITION') || null,
    driver: {},
    focusedDelivery: null,
    aMarker: null,
    hasAuth: !!getCookie('AUTH') || null,
    isOpen: () => Number(getCookie('MAP_ALIVE') || 0),
    manifestId: Number(getCookie('MAP_MANIFEST_ID')),
    response: {},
    timelineManifest: {},
    update: {}
  },
  getters: {
    aMarker: (state) => state.aMarker,
    breadcumbs: (state) => state.breadcumbs,
    currentPosition: (state) => state.currentPosition,
    driver: (state) => state.driver,
    focusedDelivery: (state) => state.focusedDelivery,
    hasAuth: (state) => state.hasAuth,
    isOpen: (state) => state.isOpen,
    manifestId: (state) => state.manifestId,
    response: (state) => state.response,
    timelineManifest: (state) => state.timelineManifest,
    update: (state) => state.update
  },
  mutations: {
    breadcumbs(state, data) {
      state.breadcumbs = data;
    },
    driver(state, data) {
      state.driver = data;
    },
    currentPosition(state, data) {
      state.driver = data;
    },
    showMarker(state, data) {
      state.aMarker = data;
    },
    focusedDelivery(state, data) {
      state.focusedDelivery = data;
    },
    hasAuth(state, data) {
      state.hasAuth = data;
    },
    manifestId(state, data) {
      state.manifestId = data;
    },
    response(state, data) {
      state.response = data;
    },
    timelineManifest(state, data) {
      state.timelineManifest = data;
    },
    update(state, data) {
      state.update = data;
    },
    driverStatusTagClick(state, data) {
      state.driverStatusTagClick = data;
    }
  },
  actions: {
    async handleCurrentPosition({ commit, getters }) {
      let currentPosition = '';
      const storedPosition = getters.currentPosition;
      if (storedPosition) {
        return storedPosition;
      }
      try {
        currentPosition = await handleLocation();
      } catch (error) {
        console.error(error);
      }
      if (currentPosition) {
        const { coords } = currentPosition;
        const freshPosition = { lat: coords.latitude, lng: coords.longitude };
        setCookie('CURRENT_POSITION', freshPosition, '8h');
        commit('currentPosition', freshPosition);
        return freshPosition;
      }
    },
    manifestId({ commit }, payload = '') {
      const cookieName = `MAP_MANIFEST_ID`;
      payload ? setCookie(cookieName, payload) : removeCookie(cookieName);

      commit('manifestId', payload);
    },
    isAlive(data, payload) {
      setCookie('MAP_ALIVE', payload ? 1 : 0, '30s');
    },
    setTimelineManifest({ commit }, payload) {
      commit('timelineManifest', payload);
    },
    async focusedDelivery({ commit, dispatch }, payload) {
      await dispatch('updateMapWindow', { view: 'dispatch' });
      commit('focusedDelivery', payload);
    },
    async showMarker({ commit, dispatch }, payload) {
      if (payload) await dispatch('updateMapWindow', { view: 'dispatch' });
      commit('showMarker', payload);
    },
    updateMapWindow({ commit, getters }, payload) {
      return new Promise((resolve) => {
        const isOpen = getters.isOpen();
        if (mapWindow && isOpen) {
          mapWindow.focus();
        } else if (!isOpen) {
          let { href } = router.resolve({ name: 'map', props: { ...payload } });
          if (payload.view) href = `${href}?view=${payload.view}`;
          mapWindow = window.open(href, '_blank');
        }
        const interval = setInterval(() => {
          if (getters.isOpen()) {
            clearInterval(interval);
            commit('update', payload);
            resolve();
          }
        }, 100);
      });
    }
  }
};

<template>
  <b-icon v-if="priority && priority !== 'normal'" class="priority-dot" icon="circle-small" />
</template>

<script>
export default {
  props: {
    priority: { type: String, default: '' }
  }
};
</script>

<style lang="sass" scoped>
.priority-dot
  overflow: hidden
  color: green
  width: 20px
  min-width: 20px
  margin-right: 5px
  ::v-deep
    .mdi
      line-height: 10px
      &:before
        font-size: 40px
        vertical-align: middle
</style>
<style lang="sass" scoped>
.dark
  .priority-dot
    color: $green
</style>

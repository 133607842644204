<template>
  <Field v-if="dragDrop" :label="label" class="file-picker">
    <div class="w-100">
      <b-upload class="upload-container w-100 mb-3" v-model="file" drag-drop>
        <section class="content p-4 has-text-centered">
          <div><b-icon class="uc-file-icon" :icon="uploadIcon" size="is-large" /></div>
          <div class="uc-file-title">{{ oTitle }}</div>
          <div class="uc-file-subTitle">{{ subTitle }}</div>
        </section>
      </b-upload>
    </div>
    <Tag v-if="file" class="fp-tag mb-3" attached closable @close="file = null">
      {{ file.name }}
    </Tag>
  </Field>
  <div v-else>
    <b-button v-if="loading && hasPermission" type="is-primary" :loading="loading">
      <b-icon class="file-icon" :icon="uploadIcon" />
    </b-button>
    <b-field class="file is-primary" v-else-if="hasPermission">
      <b-upload v-model="file" @input="onInput" :accept="accept" @click.native="file = null">
        <span class="file-cta">
          <b-icon class="file-icon" icon="upload"></b-icon>
          <span class="file-label" v-if="!!oTitle">
            {{ oTitle }}
          </span>
        </span>
      </b-upload>
    </b-field>
  </div>
</template>
<script>
import { uploadFile } from '@/utils/Presigned';
import { toast } from '@/utils/dialog';
import { Field, Tag } from '@/components';
export default {
  components: {
    Field,
    Tag
  },
  data() {
    return {
      loading: false,
      file: null
    };
  },
  computed: {
    oTitle() {
      return (
        this.title ?? (this.file ? this.$t('global.upload.replace') : this.$t('global.upload.file'))
      );
    },
    hasPermission() {
      let isAllowed = false;
      if (this.permission?.length) {
        isAllowed = this.permission.some((permission) =>
          this.Secure.permissionValidator(permission)
        );
      }
      return isAllowed || this.Secure.permissionValidator(this.permission);
    }
  },
  methods: {
    onInput(file) {
      if (this.autosave) this.save(file);
      else this.$emit('file', file);
    },
    async save(file) {
      const extension = this.file.name.split('.')[1];
      const fileName = typeof this.fileName === 'function' ? this.fileName() : this.fileName;
      try {
        this.loading = true;
        await uploadFile({
          fileName: `${fileName}.${extension}`,
          apiUrl: this.apiUrl,
          file
        });
        toast('success', this.$t('global.upload.fileSuccess'), 5000);
        this.file = null;
        this.$emit('success');
      } catch (error) {
        console.log(error);
        this.$emit('fail');
      }
      this.loading = false;
    }
  },
  watch: {
    file(value) {
      this.$emit('input', value);
      this.$emit('change', value);
    }
  },
  props: {
    accept: { type: String, default: '*' },
    apiUrl: { type: String, required: true },
    autosave: { type: Boolean, default: true },
    dragDrop: { type: Boolean, default: false },
    fileName: { type: [Function, String], default: `Upload-${Math.round(Math.random() * 1000)}` },
    label: { type: String, default: '' },
    permission: { type: Object, default: () => {} },
    subTitle: { type: String, default: null },
    title: { type: String, default: null },
    uploadIcon: { type: String, default: 'cloud-upload' }
  }
};
</script>

<style lang="sass" scoped>
.upload
  cursor: pointer
.file-icon
  margin: 0
.file-icon+.file-label
  margin-left: .5rem
.file-picker
  .upload-container
    .uc-file
      &-icon,
      &-title
        color: $blue-400
      &-title
        font-weight: bold
      &-subTitle
        opacity: .7
    ::v-deep
      .upload-draggable
        width: 100%
      .upload-draggable
        border: 2px dashed $gray-600
  .fp-tag
    ::v-deep
      .tag
        background: $blue-100
</style>
<style lang="sass">
.dark
  .file-cta
    color: $dark-mode-button-text!important
    &:hover
      color: white!important
  .file-picker
    .upload-container
      background: $dark-500
      .uc-file
        &-icon,
        &-title
          color: $blue-300
        &-subTitle
          color: $gray-400
      .upload-draggable
        border-color:$gray-700
    .fp-tag
      .tag
        color: white
        background: $blue-600
</style>

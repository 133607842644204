import Vue from 'vue';
import Vuex from 'vuex';
import modules from '@/store/modules/';
import sharedMutations from 'vuex-shared-mutations';
import { getCookie } from '@/utils/Cookies.js';

Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    breadcumbs: null,
    lang: getCookie('locale')
  },
  mutations: {
    setBreadcumbs(state, data) {
      state.breadcumbs = data;
    }
  },
  actions: {
    setBreadcumbs: ({ commit }, data) => {
      // const setMap = data?.setMap //? Auto update map breadcumbs???
      // const breadcumbs = data?.breadcumbs || data
      // if (setMap)
      // commit('map/breadcumbs', breadcumbs)
      commit('setBreadcumbs', data);
    }
  },
  modules,
  plugins: [
    sharedMutations({
      predicate: [
        'map/breadcumbs',
        'map/focusedDelivery',
        'map/hasAuth',
        'map/isOpen',
        'map/manifestId',
        'map/response',
        'map/showMarker',
        'map/update'
      ]
    })
  ]
});

import store from '../store';
import Api from '@/utils/Api.js';
import { config, vapidKey } from '@/config/firebase';
import { initializeApp } from 'firebase/app';
import { getMessaging, getToken, onMessage } from 'firebase/messaging';
export default class FirebaseMessaging {
  constructor(autoInit = true) {
    this._onMessage = null;
    this._token = null;
    this.Api = Api;
    this.firebaseApp = initializeApp(config);
    this.messaging = getMessaging();
    if (autoInit) this.initialize();
  }
  get userId() {
    return store.getters?.AUTH?.id;
  }
  set token(token) {
    this._token = token;
    this.suscribeNotifications();
  }
  get token() {
    return this._token;
  }
  set onMessage(func) {
    this._onMessage = func;
  }
  async initialize() {
    this.tokenInit();
    this.messagesInit();
    this.serviceWorkerInit();
  }
  async tokenInit() {
    getToken(this.messaging, { vapidKey })
      .then((token) => {
        if (token) {
          this.token = token;
        } else {
          console.log('No registration token available. Request permission to generate one.');
        }
      })
      .catch((err) => {
        console.log('An error occurred while retrieving token. ', err);
      });
  }

  async messagesInit() {
    onMessage(this.messaging, ({ data }) => {
      const jsonBody = JSON.parse(data['pinpoint.jsonBody'] || '{}');
      this._onMessage &&
        this._onMessage({
          body: data['pinpoint.notification.body'],
          id: jsonBody.notification_id,
          imageIconUrl: data['pinpoint.notification.imageIconUrl'],
          jsonBody,
          is_attended: 0,
          silentPush: data['pinpoint.notification.silentPush'],
          sound: data['pinpoint.notification.sound'],
          title: data['pinpoint.notification.title'],
          type: jsonBody.notification_type,
          url: data['pinpoint.url']
        });
    });
  }
  suscribeNotifications() {
    const { token, userId } = this;
    if (Api && token && userId) {
      Api.put(`users/${userId}/notification_suscribe`, {
        notification_suscribe: token
      });
    } else {
      console.warn('::Missings', { Api, token, userId });
    }
  }
  serviceWorkerInit() {
    //! experiment
    // navigator.serviceWorker.addEventListener('message', (event) => {
    //   console.log('__XXXXXXXX', event); // refresh code goes here
    // });
  }
}

<template>
  <div>
    <!-- <b-field grouped>
      <My-switch
        style="margin: auto 0"
        v-model="isALF"
        @input="(status) => onFacilityTypeChanged('ALF', status)"
        :text="$t('facility.ALF')"
        boolean
      />
      <My-switch
        style="margin: auto 0"
        v-model="isSNF"
        @input="(status) => onFacilityTypeChanged('SNF', status)"
        :text="$t('facility.SNF')"
        boolean
      />
    </b-field> -->
    <b-field grouped>
      <Autocomplete
        class="column is-8"
        :value="facility.name || ''"
        :data="filteredFacilities"
        :label="$tc('facility.label')"
        field="name"
        :loading="loading"
        @select="onFacilityChange"
        :readonly="!isALF && !isSNF"
      />
      <PhonePicker
        class="column"
        :value="facility.phone_number"
        :label="$t('contact.phone')"
        :loading="loading"
        readonly
      />
    </b-field>
    <Field
      class="column"
      :label="$t('address.address', [])"
      :value="facility.full_address"
      :loading="loading"
      readonly
    />
    <a @click="onAddNew">
      <FormatText capitalize>
        {{ $t('global.aNew', [$tc('facility.label', 1)]) }}
      </FormatText>
    </a>
  </div>
</template>

<script>
import { Autocomplete, Field, FormatText, PhonePicker } from '@/components';
export default {
  components: {
    Autocomplete,
    Field,
    PhonePicker,
    FormatText
  },
  computed: {
    filteredFacilities() {
      const type = this.isALF ? 'ALF' : 'SNF';
      return this.facilities.filter((f) => f.facility_type == type);
    }
  },
  created() {
    this.getFacilities();
  },
  data() {
    return {
      loading: true,
      facilities: [],
      facility: {},
      isALF: false,
      isSNF: false
    };
  },
  methods: {
    chooseDefault() {
      if (this.facilityId) {
        this.facility = this.facilities.filter((f) => f.id == this.facilityId)[0] || {};
        if (this.facility.facility_type == 'ALF') {
          this.isALF = true;
          this.isSNF = false;
        } else {
          this.isALF = false;
          this.isSNF = true;
        }
      }
    },
    async getFacilities() {
      try {
        const { data } = await this.Api.get(`facilities`);
        this.facilities = data;
        this.chooseDefault();
      } catch (error) {
        console.error(error);
      }
      this.loading = false;
    },
    onAddNew() {
      alert('new');
    },
    onFacilityChange(option) {
      this.facility = option || {};
      this.$emit('change', this.facility);
    },
    onFacilityTypeChanged(type, status) {
      if (this.isSNF || this.isALF) {
        if (type == 'ALF') this.isSNF = !status;
        if (type == 'SNF') this.isALF = !status;
      }
      this.onFacilityChange();
    }
  },
  watch: {
    facilityId(val) {
      if (val) this.chooseDefault();
    }
  },
  props: {
    facilityId: {
      type: Number,
      default: 0
    }
  }
};
</script>

<style lang="sass" scoped>
.column
  padding: 0
.field
  ::v-deep input
    background: transparent
</style>

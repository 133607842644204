<template>
  <Modal
    :isOpen.sync="isModalOpen"
    :header="$t('dispatch.addNewLunchBreak')"
    size="is-medium"
    :loading="loading"
    isFullButtonWidth
    @save="onSubmit"
    @cancel="true"
  >
    <MessageBadge :message="$t('schedule.lunchBreakHours')" type="is-warning" />
    <form ref="form" @submit.prevent="onSubmit">
      <Field :label="$t('dispatch.deliverySequence')">
        <b-radio
          v-for="(option, i) in sequenceOptions"
          :key="i"
          type="primary"
          :value="secuence > 1 ? 0 : secuence"
          :native-value="option.value"
          @input="
            (value) => {
              secuence = value == 0 ? 5 : value;
            }
          "
        >
          {{ option.label }}
        </b-radio>
      </Field>
      <Field :label="$t('global.place')" v-if="secuence > 1">
        <b-select placeholder="" v-model="secuence">
          <option v-for="option in [2, 3, 4, 5, 6, 7, 8, 9]" :value="option" :key="`opt-${option}`">
            {{ `${$t('global.place')} ${option}` }}
          </option>
        </b-select>
      </Field>
    </form>
  </Modal>
</template>

<script>
import { toast } from '@/utils/dialog';
import { Field, Modal, MessageBadge } from '@/components';

export default {
  components: {
    Field,
    Modal,
    MessageBadge
  },
  created() {
    this.unsubscribe = this.$store.subscribe(({ type }, { dispatch: { manifest } }) => {
      if (type === 'dispatch/manifest') {
        this.manifestId = manifest.id;
      }
    });
  },
  destroyed() {
    this.unsubscribe();
  },
  data() {
    return {
      secuence: 1,
      loading: { save: false },
      isModalOpen: false,
      unsubscribe: null
    };
  },
  computed: {
    sequenceOptions() {
      return [
        { value: 1, label: this.$t('dispatch.moveQueue') },
        { value: 0, label: this.$t('dispatch.moveSpecificPlace') }
      ];
    }
  },
  watch: {
    isOpen(value) {
      this.isModalOpen = value;
    },
    isModalOpen(value) {
      this.$emit('update:isOpen', value);
    }
  },
  methods: {
    async onSubmit() {
      this.loading.save = true;
      try {
        const params = { delivery_event_type_id: 4 };
        if (this.secuence != 1) params.secuence = this.secuence;
        await this.Api.post(`delivery_manifest/${this.manifestId}/delivery_events_addon`, params);
        toast('success', this.$t('messages.saved'), 5000);
        this.isModalOpen = false;
        this.$store.commit('dispatch/updateManifestEvents');
      } catch (error) {
        console.error(error);
      }
      this.loading.save = false;
    }
  },
  props: {
    isOpen: { type: Boolean, default: false }
  }
};
</script>

<style lang="sass" scoped>
.sub-title
  font-size: $f-lg
  font-weight: bold
  margin-top: 24px

.b-radio
  margin-top: 25px
  margin-bottom: 34px
</style>

export const vapidKey =
  'BJqMkdaeIyPfCtR2V3heG9JYuxwYptIWrHNApI0p01D1yutSuN_3aGM2lpy6rREciD3w-_DEIt73qa5zMZc-Zh0';
export const config = {
  apiKey: 'AIzaSyD7WWCMsDQgsS5vsAPAm0eqirvs0GxIQbY',
  authDomain: 'staging-nemt-rx-deliveries.firebaseapp.com',
  projectId: 'staging-nemt-rx-deliveries',
  storageBucket: 'staging-nemt-rx-deliveries.appspot.com',
  messagingSenderId: '234773365060',
  appId: '1:234773365060:web:e9d5a15c1f924c12e49711',
  measurementId: 'G-KS62FG4667'
};
import { setCookie, getCookie } from '@/utils/Cookies.js';

export default {
  namespaced: false,
  state: {
    LANG: getCookie('LANG') || null
  },
  getters: {
    LANG: (state) => state.LANG
  },
  mutations: {
    LANG(state, data) {
      setCookie('LANG', data, Infinity);
      state.LANG = data;
    }
  },
  actions: {
    LANG: ({ commit }, LANG_data) => {
      const lang = (LANG_data || '').toLowerCase();
      commit('LANG', lang === 'us' ? 'en' : lang);
    }
  }
};

<template>
  <div id="date-contraint" class="flex f-ai" :class="hasRestriction ? 'show' : 'hide'">
    <b-field class="restriction-options" grouped>
      <b-radio
        v-for="(option, i) in restrictionOptions"
        :key="i"
        type="primary"
        v-model="restriction.option"
        :native-value="option.option"
      >
        {{ option.label }}
      </b-radio>
    </b-field>
    <div>
      <div v-for="(option, i) in restrictionOptions" :key="'tp' + i" class="flex">
        <TimePicker
          class="ml-3 mr-3"
          inline
          size="is-small"
          :convertUtc="convertUtc"
          :disabled="restriction.option != option.option"
          v-model="option[option.option == 1 ? 'delivery_end_time' : 'delivery_start_time']"
          :offset="offset"
        />
        <div v-if="option.option == 2" class="flex f-ai">
          <p class="mb-2 mr-3">{{ $t('global.and') }}</p>
          <TimePicker
            v-model="option.delivery_end_time"
            size="is-small"
            inline
            :offset="offset"
            :min-time="getMinTime(option)"
            :disabled="restriction.option != option.option"
            :convertUtc="convertUtc"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { TimePicker } from '@/components';
import moment from 'moment';
import store from '@/store';

export default {
  components: {
    TimePicker
  },
  destroyed() {
    moment.tz.setDefault(store.getters.timeZone || undefined);
  },
  data() {
    let { after, before } = this.defaultValues || {};
    if (this.convertUtc) {
      const offset = Math.abs(this.$moment().utcOffset()) / 60;
      after = this.$moment(after, 'HH:mm:ss').add(offset, 'hours').format('HH:mm:ss');
      before = this.$moment(before, 'HH:mm:ss').add(offset, 'hours').format('HH:mm:ss');
    }

    const defaultUTCValues = { after, before };

    return {
      restriction: {
        option: null,
        delivery_start_time: null,
        delivery_end_time: null
      },
      restrictionOptions: [
        {
          option: 0,
          label: this.$t('deliveries.deliverAfter'),
          delivery_start_time: this.dateStart || defaultUTCValues.after
        },
        {
          option: 1,
          label: this.$t('deliveries.deliverBefore'),
          delivery_end_time: this.dateEnd || defaultUTCValues.before
        },
        {
          option: 2,
          label: this.$t('deliveries.deliverBetween'),
          delivery_start_time: this.dateStart || defaultUTCValues.after,
          delivery_end_time: this.dateEnd || defaultUTCValues.before
        }
      ]
    };
  },
  mounted() {
    console.log('_________________');
    console.log(':::::THIS COMPONENT IS DEPRECATED:::::');
    console.log(':::USE "DeliverRestriction" INSTEAD:::');
    console.log('_________________');
    if (this.dateEnd && this.dateStart) {
      this.restriction.option = 2;
    } else if (this.dateEnd) {
      this.restriction.option = 1;
    } else {
      this.restriction.option = 0;
    }
  },
  methods: {
    getMinTime({ delivery_start_time: minTime, delivery_end_time: endTime }) {
      let offsetMin = Math.abs(this.$moment().utcOffset());
      let offsetEnd = -(Math.abs(this.$moment().utcOffset()) / 60);

      if (!this.convertUtc) {
        offsetMin = 0;
        offsetEnd = 0;
        moment.tz.setDefault();
      }

      const restrictionMin = this.$moment
        .duration(
          this.$moment(minTime, 'HH:mm:ss')
            .add(-(offsetMin / 60), 'hours')
            .format('HH:mm:ss')
        )
        .asSeconds();

      const restrictionEnd = this.$moment
        .duration(this.$moment(endTime, 'HH:mm:ss').add(offsetEnd, 'hours').format('HH:mm:ss'))
        .asSeconds();

      const currentDate = new Date();
      const date = this.$moment(minTime, 'HH:mm:ss')
        .add(-(Math.abs(this.$moment().utcOffset()) / 60), 'hours')
        .format('HH:mm:ss');
      const dataTime = date.split(':');
      currentDate.setHours(dataTime[0], dataTime[1], dataTime[2]);

      const dateWithoutUTC = new Date();
      const dateUTC = this.$moment(minTime, 'HH:mm:ss').format('HH:mm:ss');
      const dataTimeUTC = dateUTC.split(':');
      dateWithoutUTC.setHours(dataTimeUTC[0], dataTimeUTC[1], dataTimeUTC[2]);

      const updateEndTime = this.$moment(minTime, 'HH:mm:ss').format('HH:mm:ss');

      if (restrictionMin + 30 * 60 >= restrictionEnd && this.restriction.option === 2)
        this.restrictionOptions[2].delivery_end_time = this.$moment(updateEndTime, 'HH:mm:ss')
          .add(30, 'minutes')
          .format('HH:mm:ss');

      if (!this.convertUtc) return this.$moment(dateWithoutUTC).add(30, 'minutes').toDate();

      return this.$moment(currentDate).add(30, 'minutes').toDate();
    },
    setData() {
      let { restriction } = this;
      restriction.delivery_start_time = null;
      restriction.delivery_end_time = null;

      this.restrictionOptions.map((opt) => {
        if (restriction.option == opt.option) {
          if (opt['delivery_start_time'])
            restriction.delivery_start_time = opt['delivery_start_time'];
          if (opt['delivery_end_time']) restriction.delivery_end_time = opt['delivery_end_time'];
        }
      });

      return {
        delivery_start_time: restriction.delivery_start_time || null,
        delivery_end_time: restriction.delivery_end_time || null
      };
    },
    checkDates(value) {
      this.$emit('change', null, this.restriction.option);
      const { restrictionOptions } = this;
      let data = {};
      if (value === 2) {
        if (
          restrictionOptions[value].delivery_start_time &&
          restrictionOptions[value].delivery_end_time
        ) {
          data = this.setData();
          this.$emit('change', data, this.restriction.option);
        }
      } else {
        if (
          restrictionOptions[value].delivery_start_time ||
          restrictionOptions[value].delivery_end_time
        ) {
          data = this.setData();
          this.$emit('change', data, this.restriction.option);
        }
      }
    },
    validateStartEnd({ dateEnd, dateStart }) {
      if (dateEnd && dateStart) {
        this.restriction.option = 2;
        this.restrictionOptions[2].delivery_start_time = dateStart;
        this.restrictionOptions[2].delivery_end_time = dateEnd;
      } else if (dateStart) {
        this.restriction.option = 0;
        this.restrictionOptions[0].delivery_start_time = dateStart;
        this.restrictionOptions[1].delivery_end_time = this.defaultValues.before;
      } else if (dateEnd) {
        this.restriction.option = 1;
        this.restrictionOptions[0].delivery_start_time = this.defaultValues.after;
        this.restrictionOptions[1].delivery_end_time = dateEnd;
      }
    }
  },
  watch: {
    restrictionOptions: {
      handler() {
        const { restriction, restrictionOptions } = this;
        if (restriction.option === 0) {
          restrictionOptions[2].delivery_start_time = restrictionOptions[0].delivery_start_time;
        } else if (restriction.option === 1) {
          restrictionOptions[2].delivery_end_time = restrictionOptions[1].delivery_end_time;
        } else {
          restrictionOptions[0].delivery_start_time = restrictionOptions[2].delivery_start_time;
          restrictionOptions[1].delivery_end_time = restrictionOptions[2].delivery_end_time;
        }
        this.checkDates(restriction.option);
      },
      deep: true
    },
    'restriction.option'(value) {
      this.checkDates(value);
    },
    dateEnd(dateEnd) {
      const { dateStart } = this;
      this.validateStartEnd({ dateEnd, dateStart });
    },
    dateStart(dateStart) {
      const { dateEnd } = this;
      this.validateStartEnd({ dateStart, dateEnd });
    }
  },
  props: {
    format: { type: String, default: 'HH:mm:ss' },
    offset: { type: Number, default: 0 },
    hasRestriction: { type: Boolean, default: true },
    dateStart: { type: String, default: null },
    dateEnd: { type: String, default: null },
    convertUtc: { type: Boolean, default: true },
    defaultValues: {
      type: Object,
      default: () => ({
        after: '08:00:00',
        before: '10:00:00'
      })
    }
  }
};
</script>

<style lang="sass" scoped>
#date-contraint
  border-top: 1px solid $gray-100
  margin-top: 1rem
  padding-top: .5rem
  ::v-deep
    .field
      margin-bottom: 5px
    .timepicker
      .dropdown-content
        padding: 0
  .restriction-options
    ::v-deep
      .field.is-grouped
        display: flex
        flex-direction: column
        padding-left: 3.5rem
  .radio
    margin: 1rem 0
.show
  display: flex
.hide
  display: none
</style>

<style lang="sass">
.dark
  #date-contraint
    .radio
      color: $white
</style>

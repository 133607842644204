<template>
  <div class="phone-selector">
    <Autocomplete
      v-if="isSelect"
      :label="label"
      :data="filteredPhones"
      model="phone_number"
      field="phone_number"
      :maxlength="10"
      :required="required"
      :dropdown-position="dropdownPosition"
      :loading="oLoading"
      :value="phone"
      @select="onSelectPhone"
      :disabled="!hasPhones"
      showResultsCounter
    />
    <PhonePicker
      v-else
      :label="label"
      :value="phone"
      @input="onInput"
      :required="required"
      :loading="oLoading"
    />
    <div class="buttons-container">
      <Button
        v-if="isSelect || isAdd"
        class="first-btn"
        type="is-primary"
        icon-right="plus"
        :tooltip="$t('contact.addPhone')"
        :tooltipPosition="buttonTooltipPosition"
        :disabled="isAdd || loading"
        outlined
        @click="handleAddPhone"
      />

      <Button
        v-if="isSelect || isEdit"
        :class="isEdit ? 'first-btn' : 'last-btn'"
        type="is-primary"
        icon-right="pencil"
        :tooltip="$t('contact.editPhone')"
        :tooltipPosition="buttonTooltipPosition"
        :disabled="isEdit || notValidEdit || loading"
        outlined
        @click="handleEditPhone"
      />

      <Button
        v-if="isAdd || isEdit"
        class="last-btn"
        type="is-danger"
        icon-right="close"
        :tooltip="$t('global.cancel')"
        :tooltipPosition="buttonTooltipPosition"
        outlined
        :disabled="(!hasPhones && !phone) || loading"
        @click="handleCancel"
      />
    </div>
  </div>
</template>

<script>
import { Autocomplete, PhonePicker } from '@/components';
import Button from '../Button.vue';
export default {
  components: {
    Autocomplete,
    PhonePicker,
    Button
  },
  mounted() {
    if (this.url) this.getPhones(this.url);
    if (!this.value) this.currentState = 'ADD';
    // if (this.phone) {
    //   this.oPhones = [{ phone_number: this.phone }];
    // }
  },
  data() {
    return {
      phone: this.value,
      previousPhone: null,
      currentState: this.url ? 'SELECT' : 'ADD',
      oPhones: this.phones,
      oLoading: this.loading
    };
  },
  computed: {
    isSelect() {
      return this.currentState === 'SELECT';
    },
    isAdd() {
      return this.currentState === 'ADD';
    },
    isEdit() {
      return this.currentState === 'EDIT';
    },
    notValidEdit() {
      return String(this.value).length === 0 || !this.hasPhones;
    },
    hasPhones() {
      return !!this.filteredPhones.length;
    },
    filteredPhones() {
      return this.oPhones?.filter((phone) => phone.number_type === this.type) || [];
    }
  },
  methods: {
    handleEditPhone() {
      this.currentState = 'EDIT';
      this.previousPhone = this.phone;
    },
    handleAddPhone() {
      this.previousPhone = this.phone;
      this.phone = '';
      this.currentState = 'ADD';
    },
    handleCancel() {
      this.currentState = 'SELECT';
      this.phone = this.previousPhone;
      this.onInput(this.previousPhone);
    },
    filterPhones(data) {
      return data?.filter((phone) => phone.number_type === this.type) || [];
    },
    updateStateByPhones() {
      if (this.hasPhones) this.currentState = 'SELECT';
      else this.currentState = 'ADD';
    },
    async getPhones(url) {
      this.oLoading = true;
      try {
        const { data } = await this.Api.get(url);
        const phones = this.filterPhones(data);
        this.oPhones = phones;
        this.$emit('onPhonesReady', this.filteredPhones);
        this.updateStateByPhones();
      } catch (error) {
        console.error(error);
      }
      this.oLoading = false;
    },
    onSelectPhone(phone) {
      this.phone = phone;
      this.onInput(phone);
    },
    onInput(phone) {
      this.$emit('input', phone);
    }
  },
  watch: {
    value(phone) {
      this.phone = phone;
      // if (this.previousPhone === null) this.previousPhone = phone;
    },
    phones(value) {
      this.oPhones = value;
      this.updateStateByPhones();
    },
    url(value) {
      this.getPhones(value);
    },
    oLoading(value) {
      this.$emit('update:loading', value);
    },
    loading(value) {
      this.oLoading = value;
    }
  },
  props: {
    buttonTooltipPosition: { type: String, default: 'top' },
    dropdownPosition: { type: String, default: 'auto' },
    isDeletablePhone: { type: Boolean, defualt: false },
    label: { type: String, default: '' },
    loading: { type: Boolean, default: false },
    phones: { type: Array, default: () => [] },
    required: { type: Boolean, default: false },
    url: { type: String, defualt: null },
    value: { type: String, default: null },
    type: {
      default: 'mobile',
      validator(value) {
        return ['mobile', 'land'].includes(value);
      }
    }
  }
};
</script>

<style lang="sass" scoped>
.phone-selector
  margin-bottom: 0.75rem
  display: flex!important
  position: relative
  .first-btn
    border-size: 2px
  ::v-deep
    input
      border-right: none
    .field, .a-field
      margin: 0!important
      input
        border-top-right-radius: 0
        border-bottom-right-radius: 0
    .buttons-container
      width: 80px
      display: flex
      align-self: flex-end
      &>.button:first-child
        border-right: 0
        border-radius: 0
      &>.button:last-child
          border-radius: 0 5px 5px 0
      &>span,
      &>.b-tooltip
        &:first-child .button
          border-right: 0
          border-radius: 0
      &>span,
      &>.b-tooltip
        &:last-child .button
          border-radius: 0 5px 5px 0
      .first-btn
        .button
          border-radius: 0
          border-right: none
      .last-btn
        .button
          border-radius: 0 5px 5px 0
    .autocomplete,
    .phone-picker
      width: 100%
</style>

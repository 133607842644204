<template>
  <b-skeleton v-if="skeletonLoading" />
  <Field
    v-else
    class="input-search"
    v-bind="{ ...$props, ...$attrs }"
    type="search"
    @input="onInput"
    :icon-right="icon ? '' : iconRight"
    :inputTimeout="500"
    :simple="false"
  />
</template>
<script>
import { Field } from './';
export default {
  components: {
    Field
  },
  methods: {
    onInput(value) {
      const hasMinValues = value?.length >= this.minToSearch;
      value = hasMinValues ? value : '';
      if (this.value != value) {
        this.$emit('input', value);
      }
    }
  },
  props: {
    clearable: { type: Boolean, default: false },
    expanded: { type: Boolean, default: true },
    icon: { type: String, default: 'magnify' },
    iconRight: { type: String, default: '' },
    skeletonLoading: { type: Boolean, default: false },
    timeout: { type: Number, default: 300 },
    value: { type: String, default: '' },
    minToSearch: { type: Number, default: 3 }
  }
};
</script>

<style lang="sass" scoped>
.input-search
  margin-bottom: 0
.b-skeleton
  border: none
  margin-top: 0px
  padding: 0
  width: 100%
  ::v-deep
    .b-skeleton-item
      min-height: 40px
</style>
<style lang="sass" scoped>
.dark
  .input-search
    ::v-deep
      .control
        input
          &::placeholder
            color: $gray-600
            opacity: 1
</style>

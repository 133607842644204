// import { Permissions } from '@/utils/Secure';
export default [
  {
    inMenu: true,
    component: () => import(/* webpackChunkName: "ALF/SNF" */ '@/views/Home/'),
    meta: {
      i18n: 'menu.alf',
      secure: { permission: {}, redirect: null },
      title: 'ALF/SNF'
    },
    name: 'alf_snf',
    path: '/data/alf_snf'
  }
];

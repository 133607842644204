import { Permissions } from '@/utils/Secure';
const { DeliveryInbox, DeliveryInboxProcessCSV, Imports, Scheduling } = Permissions;

export default [
  {
    inMenu: true,
    meta: {
      i18n: 'menu.sch',
      icon: 'calendar-month',
      isParent: true,
      secure: { permission: null, redirect: null },
      items: ['scheduling', 'imports', 'deliveriesInbox'],
      type: 'section',
      title: 'Schedule'
    },
    name: 'schedule',
    path: '/schedule',
    redirect: '/schedule/scheduling'
  },
  {
    inMenu: true,
    component: () => import(/* webpackChunkName: "scheduling" */ '@/views/Schedule/Schedule.vue'),
    meta: {
      i18n: 'menu.scheduling',
      secure: { permission: Scheduling.readAll, redirect: null },
      title: 'Scheduling'
    },
    name: 'scheduling',
    path: '/schedule/scheduling'
  },
  {
    inMenu: true,
    component: () =>
      import(/* webpackChunkName: "imports" */ '@/views/Schedule/Imports/Imports.vue'),
    meta: {
      i18n: 'menu.imp',
      secure: { permission: Imports.readAll, redirect: null },
      title: 'Imports'
    },
    name: 'imports',
    path: '/schedule/imports'
  },
  {
    inMenu: true,
    component: () =>
      import(/* webpackChunkName: "imports" */ '@/views/Schedule/Imports/CreateCSV.vue'),
    meta: {
      i18n: 'menu.imp',
      secure: { permission: DeliveryInboxProcessCSV.create, redirect: null },
      title: 'Imports'
    },
    name: 'create-csv',
    path: '/schedule/imports/create-csv'
  },
  {
    component: () =>
      import(/* webpackChunkName: "details" */ '@/views/Schedule/Imports/Details.vue'),
    meta: { secure: { permission: Imports.read, redirect: null } },
    name: 'details',
    path: '/schedule/imports/:lotId'
  },
  {
    inMenu: true,
    component: () =>
      import(/* webpackChunkName: "deliveriesInbox" */ '@/views/Schedule/DeliveriesInbox.vue'),
    meta: {
      i18n: 'menu.inb',
      secure: { permission: DeliveryInbox.readAll, redirect: null },
      title: 'Deliveries Inbox'
    },
    name: 'deliveriesInbox',
    path: '/schedule/deliveries-inbox'
  }
];

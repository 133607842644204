<template>
  <Modal
    id="ChangeStatusManifest-Modal"
    :isOpen.sync="isModalOpen"
    :header="fix ? $t('global.fixIssue') : $t('dispatch.changeStatus')"
    size="is-small"
    :buttonLabel="$t('confirm.yes')"
    :loading="loading"
    :saveDisabled="loading.reason"
    @cancel="true"
    @save="onSubmit"
  >
    <form class="change-form" ref="form" @submit.prevent="onSubmit">
      <b-field>
        <Autocomplete
          :placeholder="$t('global.select')"
          icon="label"
          :api="{
            url: 'catalogs/delivery_event_status',
            params: [{ id: 'event_type_id', value: eventTypeId }],
            full: true
          }"
          :label="$t('manifest.selectNewStatus')"
          v-model="newEventId"
          model="id"
          field="name"
          class="required mb-5 mt-3"
          required
          :disabled="loading.save ? true : false"
          :dataPreProcessor="deliveriesDataPreProcessor"
        >
          <template #results="{ data }">
            {{ statutes[data.name] || data.name }}
          </template>
        </Autocomplete>
      </b-field>
      <b-field v-if="isUnsuccessfulStatus">
        <Field
          :label="$tc('global.comment', 0)"
          type="textarea"
          v-model="comments"
          :upper="false"
          :rows="2"
        />
      </b-field>
      <b-field v-if="isUnsuccessfulStatus">
        <Autocomplete
          class="required mb-5 mt-3"
          :label="$t('manifest.selectReason')"
          :placeholder="$t('global.select')"
          v-model="reasonId"
          :api="{
            url: '/catalogs/non_delivery_reason',
            full: true
          }"
          model="id"
          field="description"
          :disabled="loading.save ? true : false"
          :loading.sync="loading.reason"
          required
        />
      </b-field>

      <b-field v-if="isUnsuccessfulStatus && reasonId === 4">
        <Field
          :label="$t('manifest.otherReason')"
          type="textarea"
          v-model="otherReason"
          :upper="false"
          :rows="2"
          required
        />
      </b-field>
    </form>
  </Modal>
</template>

<script>
import { Modal, Field } from '@/components';
import Autocomplete from '@/components/Autocomplete.vue';
import { confirm, toast } from '@/utils/dialog';

export default {
  components: {
    Modal,
    Field,
    Autocomplete
  },
  created() {
    this.unsubscribe = this.$store.subscribe(({ type }, { dispatch: { manifest } }) => {
      if (type === 'dispatch/manifest') {
        this.manifestId = manifest.id;
      }
    });
  },
  destroyed() {
    this.unsubscribe();
  },
  data() {
    return {
      eventTypeId: null,
      newEventId: null,
      reasonId: null,
      otherReason: null,
      comments: null,
      isModalOpen: false,
      manifestId: null,
      loading: { save: false, reason: false }
    };
  },
  computed: {
    statutes() {
      return {
        Scheduled: this.$tc('global.scheduled'),
        'In Progress': this.$tc('global.inProgress'),
        Attempted: this.$tc('global.attempted'),
        Delivered: this.$tc('global.delivered'),
        'Re Scheduled': this.$tc('global.reschedule', 2),
        Rejected: this.$tc('global.rejected'),
        Canceled: this.$tc('global.canceled'),
        'Picked Up': this.$tc('global.pickedUp'),
        Missing: this.$tc('global.missing'),
        Damaged: this.$tc('global.damaged'),
        Failed: this.$tc('global.failed')
      };
    },
    defaultBodyParams() {
      let bodyParams = {
        delivery_event_status_id: this.newEventId,
        datetime: this.$moment().utc().format('Y-MM-DD HH:mm:ss')
      };

      if (![1, 2, 3, 8, 12, 13, 14, 19].includes(this.newEventId)) {
        bodyParams = {
          ...bodyParams,
          ...([4, 18].includes(this.newEventId) && {
            receptor_name: this.$store.getters.AUTH.full_name
          }),
          ...([4, 9, 10, 18].includes(this.newEventId) && {
            signature: 'x'
          }),
          ...([4, 5, 6, 11, 15, 17, 18].includes(this.newEventId) && {
            latitude: '32.66400675476582'
          }),
          ...([4, 5, 6, 11, 15, 17, 18].includes(this.newEventId) && {
            longitude: '-117.11107285682836'
          }),
          ...([4, 5, 6, 7, 11, 15, 16, 17, 18].includes(this.newEventId) && {
            delivered_date: this.$moment().utc().format('Y-MM-DD HH:mm:ss')
          }),
          ...([4, 5, 6, 7, 11, 15, 16, 17, 18].includes(this.newEventId) && {
            comments: this.comments || ''
          }),
          ...([5, 6, 7, 11, 15, 16, 17].includes(this.newEventId) && {
            other_reason: this.reasonId === 4 ? this.otherReason : ''
          }),
          ...([9, 10].includes(this.newEventId) && {
            full_name: this.$store.getters.AUTH.full_name,
            remarks: ''
          }),
          ...([5, 6, 7, 11, 15, 16, 17].includes(this.newEventId) && {
            non_delivery_reason_id: this.reasonId
          })
        };
      } else if (this.newEventId === 8)
        bodyParams = { ...bodyParams, delivery_event_ids: [this.eventId] };
      else if (this.newEventId === 19)
        bodyParams = { delivery_event_ids: [this.eventId], signature: 'x' };

      return bodyParams;
    },
    isUnsuccessfulStatus() {
      return [6, 7, 11, 16, 17].includes(this.newEventId);
    }
  },
  methods: {
    deliveriesDataPreProcessor(deliveries) {
      const deliveriesProcessed = deliveries.map((deliverie) => {
        if (deliverie.name === 'Attempted') deliverie.name = this.$tc('global.attempted');
        return deliverie;
      });

      if (this.delivery.delivery_event_status_id === 18) {
        return deliveriesProcessed;
      } else return deliveriesProcessed.filter((RD) => RD.id !== 19);
    },
    onSubmit() {
      if (this.validate()) {
        this.saveChangeStatus();
      }
    },
    async saveChangeStatus(isOverride = false) {
      this.loading.save = true;
      try {
        const url =
          this.newEventId === 19
            ? `/delivery_manifest/${this.manifestId || this.manifest.id}/return_delivery`
            : `/delivery_manifest/${this.manifestId || this.manifest.id}/delivery_events/${
                this.eventId
              }/change_status${isOverride ? '_override' : ''}`;

        await this.Api.post(url, this.defaultBodyParams);
        this.$emit('update:isOpen', false);
        this.$store.commit('dispatch/updateManifestEvents');
        this.$emit('save');
        toast('success', this.$t('messages.saved'), 5000);
        this.loading.save = false;
      } catch (err) {
        if (err.data.status_code === 'br003' || err.data.status_code === 'br006') {
          confirm({
            title: this.$t('confirms.continue'),
            message: this.$t('confirms.changeStateManifest'),
            cancelText: this.$t('confirm.no'),
            confirmText: this.$t('confirm.force'),
            onConfirm: async () => {
              this.saveChangeStatus(true);
            }
          });
        }
      }
      this.loading.save = false;
    },
    validate() {
      let htmlValidator = false;
      if (this.$refs.form) {
        htmlValidator = this.$refs.form.checkValidity();
        if (!htmlValidator) this.$refs.form.reportValidity();
      }
      return htmlValidator;
    }
  },
  watch: {
    isOpen(value) {
      if (this.newEventId) this.newEventId = null;
      this.isModalOpen = value;
    },
    isModalOpen(value) {
      if (!value) {
        this.newEventId = null;
        this.reasonId = null;
        this.otherReason = null;
        this.comments = null;
      }
      this.$emit('update:isOpen', value);
    },
    newEventId(value) {
      if (value && !this.isUnsuccessfulStatus) this.reasonId = null;
    },
    delivery(value) {
      this.eventTypeId = value.delivery_event_type_id;
    }
  },
  props: {
    isOpen: { type: Boolean, default: false },
    eventId: { type: Number, default: null },
    manifest: { type: Number, default: null },
    fix: { type: Boolean, default: false },
    delivery: { type: Object, default: () => {} }
  }
};
</script>

<style lang="sass" scoped>
#ChangeStatusManifest-Modal
  ::v-deep
    .modal-title
      i
        padding-left: 3px
    .modal-content-container .slot
      overflow: visible
    .modal-footer button
      &:last-child
        margin-right: 0px
.change-form
  width: 100%
</style>

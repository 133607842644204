<template>
  <Modal
    id="ReSchedule-Modal"
    :isOpen.sync="isModalOpen"
    :header="$t('dispatch.reScheduleDelivery')"
    size="is-medium"
    :buttonLabel="$t('confirm.yes')"
    :loading="loading"
    @cancel="true"
    @save="onSubmit"
  >
    <div
      v-if="rescheduledMessage.messageBody && rescheduledMessage.reason"
      class="mb-5 reschedule-information"
    >
      <div class="sub-title mb-5">{{ $t('deliveries.requestInformation') }}</div>
      <p class="mb-2">{{ rescheduledMessage.messageBody }}.</p>
      <p class="reason">
        Reason: <span> {{ rescheduledMessage.reason }}. </span>
      </p>
    </div>
    <div class="alert-advice">
      <b-icon icon="alert-circle-outline" class="icon" />
      {{ $t('dispatch.reScheduleWarning') }}
    </div>
    <form class="address-form" ref="form" @submit.prevent="onSubmit">
      <div class="sub-title">{{ $t('deliveries.deliveryAddress') }}</div>
      <Autocomplete
        :label="$t('address.address', [])"
        :data="addresses"
        :loading="loading.addresses"
        :value="oDelivery.destination_id"
        model="id"
        field="full_address"
        @select="onSelectAddress"
        required
        class="addressSelect mb-5"
      />
      <b-field>
        <AddressPicker ref="addressPicker" v-model="address" dropdownPosition="top" />
      </b-field>
      <div>
        <iSwitch class="mt-3 mb-4" v-model="hasRestriction" :text="$t('deliveries.restriction')" />
        <DeliverRestrictions
          v-if="hasRestriction"
          :value="constraintDates"
          @change="onDeliveryTimesChange"
          dropDirection="up"
        />
      </div>
    </form>
  </Modal>
</template>

<script>
import { AddressPicker, Modal, DeliverRestrictions, iSwitch } from '@/components';
import { toast, confirm } from '@/utils/dialog';
import Autocomplete from '@/components/Autocomplete.vue';
import { dashboardUpdateCommit } from '../../../store/commits';

export default {
  components: {
    Modal,
    AddressPicker,
    Autocomplete,
    DeliverRestrictions,
    iSwitch
  },
  created() {
    this.unsubscribe = this.$store.subscribe(
      ({ type }, { dispatch: { manifest, rescheduleDelivery } }) => {
        if (type === 'dispatch/rescheduleDelivery') {
          this.oDelivery = rescheduleDelivery || {};
          const {
            id,
            delivery_manifest_id,
            body,
            reason,
            participant_id,
            delivery_end_time,
            delivery_start_time
          } = rescheduleDelivery;

          this.isModalOpen = true;
          this.rescheduleIds.eventId = id;
          this.rescheduleIds.manifestId = delivery_manifest_id;
          this.rescheduleIds.participantId = participant_id;

          this.hasRestriction = !!(delivery_end_time || delivery_start_time);

          if (body && reason) {
            this.rescheduledMessage.messageBody = body;
            this.rescheduledMessage.reason = reason;
          }
        }
        if (type === 'dispatch/manifest') {
          this.rescheduleIds.manifestId = manifest.id;
        }
      }
    );
  },
  destroyed() {
    this.unsubscribe();
  },
  data() {
    return {
      address: {},
      oDelivery: {},
      currentAddress: {},
      hasRestriction: null,
      addresses: [],
      dateEnd: null,
      dateStart: null,
      deliveryTimes: {
        end_time: null,
        start_time: null
      },
      isModalOpen: false,
      loading: { save: false, addresses: false },
      participant_full_name: null,
      rescheduledMessage: {
        reason: null,
        messageBody: null
      },
      rescheduleIds: {
        eventId: null,
        manifestId: null,
        participantId: null
      }
    };
  },
  computed: {
    offset() {
      return this.oDelivery?.time_offset || 0;
    },
    constraintDates() {
      const { delivery_start_time, delivery_end_time } = this.oDelivery || {};
      const convertTime = (time) =>
        time ? this.$moment(time, 'HH:mm:ss').add(this.offset, 'hours').format('HH:mm:ss') : time;
      return {
        start_time: convertTime(delivery_start_time),
        end_time: convertTime(delivery_end_time)
      };
    },
    fullAddress() {
      const { address, city, zipcode } = this.address;
      return `${address}, ${city}, ${zipcode}`;
    }
  },
  methods: {
    addressById(addressId) {
      return this.addresses.find(({ id }) => id === addressId);
    },
    onSelectAddress(addressId) {
      if (addressId) {
        const address = this.addressById(addressId);
        this.address = address;
        this.currentAddress = this.createAddress(address);
      }
    },
    createAddress(value) {
      return {
        address_1: value.address_1,
        address: value.address,
        city: value.city,
        formatted_address: value.formatted_address,
        gate_code: value.gate_code,
        latitude: value.latitude,
        longitude: value.longitude,
        state: value.state,
        zipcode: value.zipcode
      };
    },
    async getAddresses() {
      this.loading.addresses = true;
      this.addresses = [];
      try {
        const { data } = await this.Api.get(
          `participants/${this.rescheduleIds.participantId}/addresses`
        );
        this.addresses = data;
        if (this.addresses.length > 0) {
          const address = this.addressById(this.oDelivery.destination_id);
          this.participant_full_name = address.full_address;
          this.address = address;

          this.currentAddress = this.createAddress(this.address);
        } else {
          this.address = null;
        }
      } catch (error) {
        console.error(error);
      }
      this.loading.addresses = false;
    },
    prepareData() {
      const { start_time, end_time } = this.hasRestriction ? this.deliveryTimes : {};
      const delivery_start_time = start_time || null;
      const delivery_end_time = end_time || null;
      if (this.isEdit()) {
        return {
          destination_id: this.address.id,
          delivery_start_time,
          delivery_end_time
        };
      } else {
        return {
          participant_id: this.rescheduleIds.participantId,
          formatted_address: this.fullAddress,
          address: this.address.address,
          address_1: this.address.address_1,
          gate_code: this.address.gate_code,
          latitude: this.address.latitude,
          longitude: this.address.longitude,
          zipcode: this.address.zipcode,
          state: this.address.state,
          city: this.address.city,
          delivery_start_time,
          delivery_end_time
        };
      }
    },
    onDeliveryTimesChange(times) {
      this.deliveryTimes = times;
    },
    async reSchedule(isOverride) {
      this.loading.save = true;
      try {
        const data = this.prepareData();
        await this.Api.post(
          `delivery_manifest/${this.rescheduleIds.manifestId}/delivery_events/${
            this.rescheduleIds.eventId
          }/re_schedule${isOverride ? '_override' : ''}`,
          data
        );
        toast('success', this.$t('messages.saved'), 5000);
        dashboardUpdateCommit();
        this.$store.commit('dispatch/updateManifestEvents');
        this.isModalOpen = false;
        this.deliveryTimes = null;
      } catch (error) {
        if (error.data.status_code === 'OVERTIME_WORK') {
          confirm({
            title: this.$t('confirms.continue'),
            message: this.$t('schedule.confirm.overtime'),
            cancelText: this.$t('confirm.no'),
            confirmText: this.$t('confirm.yes'),
            onConfirm: async () => {
              this.reSchedule(true);
            }
          });
        }
      }
      this.loading.save = false;
    },
    async onSubmit() {
      if (this.validate()) {
        if (this.deliveryTimes) {
          this.reSchedule();
        } else {
          toast('warning', this.$t('messages.deadlines'), 5000);
        }
      }
    },
    validate() {
      let dateValidation = false;

      if (this.hasRestriction) {
        if (this.deliveryTimes) {
          dateValidation = true;
        } else {
          toast('warning', this.$t('messages.deadlines'), 5000);
        }
      } else {
        this.deliveryTimes = { end_time: null, start_time: null };
        dateValidation = true;
      }

      return this.$refs.addressPicker.validate() && dateValidation;
    },
    isEdit() {
      const oldAddress = JSON.stringify(this.createAddress(this.currentAddress));
      const newAddress = JSON.stringify(this.createAddress(this.address));
      return oldAddress === newAddress;
    }
  },
  watch: {
    isOpen(value) {
      this.isModalOpen = value;
    },
    isModalOpen(value) {
      this.address = {};
      this.getAddresses();
      this.$emit('update:isOpen', value);
    }
  },
  props: {
    isOpen: { type: Boolean, default: false }
  }
};
</script>

<style lang="sass" scoped>
#ReSchedule-Modal
  ::v-deep
    .modal-title
      i
        padding-left: 3px
    .modal-footer button
      &:last-child
        margin-right: 0px
    .slot
      overflow-x: hidden
  .show
    display: flex
  .hide
    display: none
  .reschedule-information
    p:nth-child(3)
      color: $gray-700
      font-weight: 700
      span
        font-weight: normal
  .sub-title
    font-size: 18px
    font-weight: bold
    margin-bottom: 0.5rem
  .alert-advice
    align-content: center
    background-color: $orange-100
    border-left: 4px solid $orange-800
    border-radius: 0px 4px 4px 0px
    color: $orange-800
    display: flex
    font-size: 16px
    font-weight: 400
    max-width: 381px
    padding: 0.563rem
    .icon
      margin-right: 0.25rem
      display: flex
      align-content: center
  .address-form
    margin-top: 1rem
    width: 100%
    .addressSelect
      width: calc(100% - .75rem)
    ::v-deep
      .ap-address
        width: 100%
      .ap-city,
      .ap-address1,
      .ap-state
        width: calc(50% - .75rem)
      .ap-zipcode
        width: calc(42% - .75rem)

    .radio-form
      margin-left: 4rem
      .radio-section
        display: flex
        p
          margin: auto 1rem auto auto
        p:nth-child(3)
          margin: auto 1rem auto
        .timepicker
          width: 5.549rem
          ::v-deep
            .input
              font-size: 1rem
              text-align: center !important
</style>

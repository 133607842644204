import { setCookie, getCookie } from '@/utils/Cookies.js';

export default {
  namespaced: false,
  state: {
    STATE: getCookie('STATE') || 'CA'
  },
  getters: {
    STATE: (state) => state.STATE
  },
  mutations: {
    STATE(state, data) {
      setCookie('STATE', data, Infinity);
      state.STATE = data;
    }
  },
  actions: {
    STATE: ({ commit }, STATE_data) => {
      commit('STATE', STATE_data ? STATE_data : 'CA');
    }
  }
};

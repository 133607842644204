<template>
  <div>
    <div class="columns">
      <Field
        :label="$t('contact.last')"
        class="column is-4"
        required
        v-model="oParticipant.last_name"
        :focus="focus"
      />
      <Field
        :label="$t('contact.name')"
        class="column is-4 participant-input name"
        required
        v-model="oParticipant.first_name"
      />
      <Field
        :label="$t('contact.middle')"
        class="column is-4 participant-input middle-name"
        v-model="oParticipant.middle_name"
      />
    </div>
    <slot name="afterNames" />
    <PhonesSelector
      :participantId="oParticipant.id"
      :mobileId="oParticipant.mobile_phone_number_id"
      :landId="oParticipant.phone_number_id"
      :mobile="oParticipant.mobile_phone_number"
      :land="oParticipant.phone_number"
      :phones="oParticipant.participant_phones"
      @updatedPhones="onUpdatedPhones"
      @select="onPhoneSelect"
      :loading.sync="loading.phones"
    />
    <div v-if="extraDetails">
      <div class="columns">
        <Field
          class="column is-4"
          :label="$t('participant.clientCode')"
          v-model="oParticipant.client_code"
          placeholder="AAA000000"
          :validation-message="$t('validations.clientCode')"
          :minlength="9"
          :maxlength="9"
          :pattern="clientCodeRegex"
        />
        <Field
          class="column is-8"
          :label="$t('contact.email')"
          v-model="oParticipant.email"
          :validation-message="$t('validations.email')"
          type="email"
        />
      </div>
      <div class="columns">
        <FundingSourceSelector
          class="column is-full"
          v-model="oParticipant.funding_source_id"
          required
          tabindex="-1"
          :awaitFirstValue="!!participantId"
          :loading.sync="loading.fundingSource"
        />
      </div>
    </div>
  </div>
</template>

<script>
import { Field, PhonesSelector } from '@/components';
import { Normalize } from '@/utils/Text';
import { clone, equals, omit } from 'ramda';
import FundingSourceSelector from '../Selectors/FundingSourceSelector.vue';
import { clientCodeRegex } from '@/utils/RegexValidations';

export default {
  components: {
    Field,
    PhonesSelector,
    FundingSourceSelector
  },
  mounted() {
    this.$emit('input', this.oParticipant);
  },
  data() {
    const participant = this.extractParams(this.value);
    return {
      oParticipant: participant,
      originalData: clone(participant),
      clientCodeRegex,
      loading: { phones: false, fundingSource: false },
      loadingTimeout: 0
    };
  },
  computed: {
    isSomethingLoading() {
      const { loading } = this;
      return Object.keys(loading).some((key) => loading[key]);
    },
    hasChanged() {
      const oParticipant = omit(['phone_number_id', 'mobile_phone_number_id'], this.oParticipant);
      const originalData = omit(['phone_number_id', 'mobile_phone_number_id'], this.originalData);
      Object.keys(oParticipant).map((key) => !oParticipant[key] && (oParticipant[key] = null));
      Object.keys(originalData).map((key) => !originalData[key] && (originalData[key] = null));
      return !equals(oParticipant, originalData);
    }
  },
  methods: {
    extractParams(D) {
      const normalizeText = (text) => Normalize(text, { upper: true });
      return {
        id: D.id,
        email: D.participant_email,
        client_code: D.participant_client_code,
        funding_source_id: D.participant_funding_source_id,
        last_name: normalizeText(D.participant_last_name || ''),
        first_name: normalizeText(D.participant_first_name || ''),
        middle_name: normalizeText(D.participant_middle_name || ''),
        phone_number: normalizeText(D.participant_phone_number || ''),
        phone_number_id: normalizeText(D.participant_phone_number_id || ''),
        mobile_phone_number: normalizeText(D.participant_mobile_phone_number || ''),
        mobile_phone_number_id: normalizeText(D.participant_mobile_phone_number_id || '')
      };
    },
    onUpdatedPhones(data) {
      this.$emit('updatedPhones', data);
    },
    onPhoneSelect(phoneObject) {
      const { phoneNumber, type, phone } = phoneObject;
      if (type === 'land') {
        this.oParticipant.phone_number = phoneNumber;
        this.oParticipant.phone_number_id = phone?.id || null;
      } else {
        this.oParticipant.mobile_phone_number = phoneNumber;
        this.oParticipant.mobile_phone_number_id = phone?.id || null;
      }
    }
  },
  watch: {
    isSomethingLoading(value) {
      if (value) {
        this.$emit('update:loading', value);
      } else {
        clearTimeout(this.loadingTimeout);
        this.loadingTimeout = setTimeout(() => {
          this.$emit('update:loading', value);
        }, 500);
      }
    },
    value(value) {
      const participant = this.extractParams(value);
      this.oParticipant = participant;
      this.originalData = clone(participant);
    },
    oParticipant: {
      deep: true,
      handler() {
        this.$emit('update:hasChanges', this.hasChanged);
        this.$emit('change', this.hasChanged ? this.oParticipant : null);
        // ?Verificar funcionamiento donde se use
      }
    }
  },
  props: {
    focus: { type: Boolean, default: false },
    participantId: { type: Number, default: null },
    participantPhones: { type: Number, default: null },
    extraDetails: { type: Boolean, default: false },
    value: { type: Object, default: () => {} }
  }
};
</script>

<style lang="sass" scoped></style>

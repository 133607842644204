<template>
  <Modal :isOpen.sync="open" :header="$t('global.log')" id="changesHistoryModal" fullScreen>
    <Table
      :loading.sync="loading"
      :perPage="20"
      :show-detail-icon="false"
      apiUrl="driver_runs"
      infiniteScroll
    >
      <template #empty>
        <Empty :title="$t('dispatch.table.empty.title')" :text="$t('dispatch.table.empty.text')" />
      </template>
      <b-table-column :label="$t('global.date')" width="130" v-slot="props">
        {{ props.row.to_date }}
      </b-table-column>
      <b-table-column :label="$t('global.user')" v-slot="props">
        {{ props.row.name }}
      </b-table-column>
      <b-table-column :label="$t('global.field')" v-slot="props">
        {{ props.field }}
        { field }
      </b-table-column>
      <b-table-column :label="$t('global.before')" v-slot="props">
        {{ props.row.from_date }}
      </b-table-column>
      <b-table-column :label="$t('global.new', [$tc('')])" v-slot="props">
        {{ props.row.to_date }}
      </b-table-column>
    </Table>
  </Modal>
</template>

<script>
import { Empty, Modal, Table } from '@/components';

export default {
  components: {
    Empty,
    Modal,
    Table
  },
  data() {
    return {
      open: this.isOpen,
      loading: true
    };
  },
  watch: {
    open(value) {
      this.$emit('update:isOpen', value);
    },
    isOpen(value) {
      this.open = value;
    }
  },
  props: {
    eventId: { type: Number, default: null },
    isOpen: { type: Boolean, default: false }
  }
};
</script>

<style lang="sass" scoped></style>

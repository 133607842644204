export default {
  namespaced: true,
  state: {
    lastRoute: null
  },
  getters: {
    lastRoute: (state) => state.lastRoute
  },
  mutations: {
    setLastRoute(state, lastRoute) {
      state.lastRoute = lastRoute;
    }
  },
  actions: {}
};

import { render, staticRenderFns } from "./ManifestStatusSignUI.vue?vue&type=template&id=890c8280&scoped=true&"
import script from "./ManifestStatusSignUI.vue?vue&type=script&lang=js&"
export * from "./ManifestStatusSignUI.vue?vue&type=script&lang=js&"
import style0 from "./ManifestStatusSignUI.vue?vue&type=style&index=0&id=890c8280&lang=sass&scoped=true&"
import style1 from "./ManifestStatusSignUI.vue?vue&type=style&index=1&lang=sass&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "890c8280",
  null
  
)

export default component.exports
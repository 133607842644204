<template>
  <div
    class="imageCropper"
    @mouseover="mouseOver = true"
    @mouseleave="mouseOver = false"
    @contextmenu="
      (e) => {
        copyPaste && contextmenu(e);
      }
    "
  >
    <div class="ic-container" :class="`${!image.ready ? 'processing' : ''}`" v-if="image.file">
      <div class="ic-processing" v-if="!image.ready">Processing...</div>
      <!-- :auto-zoom="true" -->
      <!-- https://norserium.github.io/vue-advanced-cropper/introduction/getting-started.html -->
      <cropper
        ref="cropper"
        class="cropper"
        :canvas="vCanvas"
        :src="image.base64"
        :stencil-component="rounded ? 'circle-stencil' : 'rectangle-stencil'"
        :stencil-size="vStencilSize"
        :stencil-props="vStencilProps"
        image-restriction="fit-area"
        @change="change"
        @ready="image.ready = true"
      />
      <div class="c-control">
        <span @click="onEdit">
          <b-icon class="c-icon" icon="square-edit-outline" />
        </span>
      </div>
    </div>
    <b-field v-else class="dragDrop">
      <b-upload
        ref="upload"
        v-model="image.file"
        :accept="'image/*'"
        drag-drop
        @input="onDragDropInput"
        expanded
      >
        <slot v-if="$slots.default" />
        <div v-else class="dd-area">
          <b-icon icon="camera-enhance" size="is-large" />
          <p>{{ $t('global.photo') }}</p>
        </div>
      </b-upload>
    </b-field>
  </div>
</template>

<script>
import { Cropper } from 'vue-advanced-cropper';
import 'vue-advanced-cropper/dist/style.css';

export default {
  components: {
    Cropper
  },
  mounted() {
    this.copyPaste && this.initCopyAndpaste();
  },
  data() {
    const hasBase64 = this.base64 ? true : null;
    return {
      mouseOver: false,
      image: {
        file: hasBase64,
        ready: hasBase64,
        base64: this.base64
      }
    };
  },
  methods: {
    dataURItoBlob(dataURI) {
      var byteString;
      if (dataURI.split(',')[0].indexOf('base64') >= 0) byteString = atob(dataURI.split(',')[1]);
      else byteString = unescape(dataURI.split(',')[1]);
      var mimeString = dataURI.split(',')[0].split(':')[1].split(';')[0];

      var ia = new Uint8Array(byteString.length);
      for (var i = 0; i < byteString.length; i++) {
        ia[i] = byteString.charCodeAt(i);
      }
      return new Blob([ia], { type: mimeString });
    },
    initCopyAndpaste() {
      document.onpaste = (event) => {
        if (this.mouseOver) {
          var items = (event.clipboardData || event.originalEvent.clipboardData).items;
          Object.keys(items).map((key) => {
            const item = items[key];
            if (item.kind === 'file' && item.type.indexOf('image') >= 0) {
              try {
                var blob = item.getAsFile();
                var reader = new FileReader();
                reader.onload = (event) => {
                  this.image.file = true;
                  this.image.base64 = event.target.result;
                }; // data url!
                reader.readAsDataURL(blob);
              } catch (error) {
                console.error(error);
              }
            } else {
              console.warn('An Image File is required to paste');
            }
          });
        }
      };
    },
    contextmenu({ target }) {
      target.contentEditable = true;
      setTimeout(() => (target.contentEditable = false), 500);
    },
    onDragDropInput() {
      const { file } = this.image;
      var reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => {
        this.image.base64 = reader.result;
      };
    },
    vStencilSize({ boundaries }) {
      return {
        width: boundaries.width - 0,
        height: boundaries.height - 0
      };
    },
    onEdit() {
      this.onReset(false);
      setTimeout(() => {
        this.$refs.upload.$el.children[1].click();
        this.$emit('edit');
      }, 50);
    },
    onReset(emit = true) {
      this.image = { file: null, ready: false, base64: null };
      if (emit) this.$emit('update');
    },
    change({ canvas }) {
      this.$emit('canvas', canvas);
      const base64 = canvas.toDataURL();
      const file = this.dataURItoBlob(base64);
      this.$emit('change', { base64, file });
    }
  },
  computed: {
    vStencilProps() {
      return {
        handlers: {},
        movable: false,
        resizable: false,
        scalable: false
      };
    },
    vCanvas() {
      0;
      const { maxSize } = this;
      return {
        minHeight: maxSize,
        minWidth: maxSize,
        maxHeight: maxSize,
        maxWidth: maxSize
      };
    }
  },
  props: {
    rounded: { type: Boolean, default: false },
    base64: { type: String, default: null },
    copyPaste: { type: Boolean, default: false },
    maxSize: { type: Number, default: 250 }
  }
};
</script>
<style lang="sass" scoped>
.processing
  .footer,
  .cropper
    visibility: hidden
.cropper
  height: 100%
.imageCropper
  background: $main-separators
  color: $primary
  position: relative
  display: flex
  flex-flow: column
  justify-content: center
  height: 200px
  width: 200px
  margin: 0 auto 10px
  border-radius: 10px
  overflow: hidden
.ic-container
  height: 100%
  .ic-processing
    display: flex
    justify-content: center
    align-items: center
    height: 100%
  .c-icon
    color: white
    cursor: pointer
    &:hover
      text-shadow: 0 0 5px white
  .c-control
    position: absolute
    right: 10px
    bottom: 0
    z-index: 2
    color: white
    cursor: pointer
    transition: all .3s ease
    text-shadow: 0 0 10px rgba(0, 0, 0, .3)
    &:hover
      text-shadow: 0 0 10px rgba(0, 0, 0, .7)
.dragDrop
  padding: 5px
  border-radius: 20px
  height: 100%
  ::v-deep
    .upload-draggable
      border: none
      display: flex
      justify-content: center
      align-items: center
    .is-expanded
      height: 100%
  .dd-area
    text-align: center
    padding: 10px
    border-radius: 10px
    width: fit-content
    height: fit-content
    .icon
      margin-bottom: 10px
</style>

<template>
  <NMarker :position="position" :zIndex="zIndex" @click="() => $emit('click', delivery)">
    <DeliveryStatusMarker :showNumbers="showNumbers" :deliveryEvent="delivery" />
  </NMarker>
</template>

<script>
import { DeliveryStatusMarker } from '@/components';
import { NMarker } from '@/components/Map/Plugins';

export default {
  components: {
    DeliveryStatusMarker,
    NMarker
  },
  computed: {
    zIndex() {
      return this.isImportant ? 2 : 1;
    },
    position() {
      return {
        lat: this.delivery.destination_latitude,
        lng: this.delivery.destination_longitude
      };
    },
    showNumbers() {
      return (
        this.delivery.delivery_event_type_id === 3 || this.delivery.delivery_event_type_id === 8
      );
    }
  },
  props: {
    delivery: { type: Object, default: () => {} },
    isImportant: { type: Boolean, default: true }
  }
};
</script>

<style lang="sass" scoped></style>

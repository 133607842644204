import { Permissions } from '@/utils/Secure';
const { Participants, ParticipantAddresses } = Permissions;
export default [
  {
    inMenu: true,
    component: () => import(/* webpackChunkName: "participants" */ '@/views/Participants/index'),
    meta: {
      i18n: 'menu.par',
      secure: { permission: Participants.readAll, redirect: null },
      title: 'Participants'
    },
    name: 'participants',
    path: '/data/participants'
  },
  {
    component: () =>
      import(/* webpackChunkName: "participant/new" */ '@/views/Participants/Participant'),
    meta: { secure: { permission: Participants.create, redirect: null } },
    name: 'newParticipant',
    path: '/data/participants/add'
  },
  {
    component: () =>
      import(/* webpackChunkName: "participant/edit" */ '@/views/Participants/Participant'),
    meta: { secure: { permission: Participants.update, redirect: null } },
    name: 'participant',
    path: '/data/participants/:participantId(\\d+)'
  },
  {
    component: () =>
      import(/* webpackChunkName: "participant/location" */ '@/views/Participants/Participant'),
    meta: {
      current: 2,
      secure: { permission: ParticipantAddresses.read, redirect: '/data/participants' }
    },
    name: 'participant/location',
    path: '/data/participants/:participantId(\\d+)/location'
  },
  {
    component: () =>
      import(/* webpackChunkName: "participant/contacts" */ '@/views/Participants/Participant'),
    meta: {
      current: 3,
      secure: { permission: Participants.update, redirect: '/data/participants' }
    },
    name: 'participant/contacts',
    path: '/data/participants/:participantId(\\d+)/contacts'
  },
  {
    component: () =>
      import(/* webpackChunkName: "participant/mailing" */ '@/views/Participants/Participant'),
    meta: {
      current: 4,
      secure: { permission: Participants.update, redirect: '/data/participants' }
    },
    name: 'participant/mailing',
    path: '/data/participants/:participantId(\\d+)/mailing'
  }
];

import { render, staticRenderFns } from "./PhonesSelector.vue?vue&type=template&id=30d8269c&scoped=true&"
import script from "./PhonesSelector.vue?vue&type=script&lang=js&"
export * from "./PhonesSelector.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "30d8269c",
  null
  
)

export default component.exports
import Api from '../utils/Api';

export const getCDPsService = async () => {
  const { data } = await Api.get('cdp?per_page=99999');
  return data;
};
export const getActiveCDPsService = async () => {
  try {
    const { data } = await Api.get('cdp?per_page=99999&is_active=1');
    return data;
  } catch (error) {
    return [];
  }
};

import { Permissions } from '@/utils/Secure';
const { CDPs } = Permissions;

export default [
  {
    inMenu: true,
    component: () => import(/* webpackChunkName: "cdps" */ '@/views/CDP/index'),
    meta: {
      i18n: 'menu.cdp',
      secure: { permission: CDPs.readAll, redirect: null },
      title: 'CDP'
    },
    name: 'cdps',
    path: '/data/cdps'
  },
  {
    component: () => import(/* webpackChunkName: "cdp/new" */ '@/views/CDP/CDP'),
    meta: { secure: { permission: CDPs.create, redirect: null } },
    name: 'newCDP',
    path: '/data/cdps/add'
  },
  {
    component: () => import(/* webpackChunkName: "cdp/edit" */ '@/views/CDP/CDP'),
    meta: { secure: { permission: CDPs.update, redirect: null } },
    name: 'editCDP',
    path: '/data/cdps/:cdpId(\\d+)'
  }
];

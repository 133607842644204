<template>
  <div v-if="info.length > 0" class="elastic-search-highlight-icons">
    <v-popover
      v-for="highlight in info"
      :key="highlight.icon"
      trigger="hover"
      :delay="{ show: 100, hide: 0 }"
      :offset="5"
      :popoverWrapperClass="`highlight-wrapper-popover ${
        isDarkMode ? 'highlight-wrapper-popover-dark' : ''
      }`"
      popoverClass="highlight-popover"
      popoverInnerClass="highlight-inner-popover"
      :disabled="disabled"
    >
      <b-icon class="tooltip-target highlight-icon" :icon="highlight.icon" />
      <template slot="popover">
        <div class="highlight-tooltip-container">
          <p class="highlight-title">Matching instances</p>
          <div class="flex highlight-detail">
            <p class="highlight-description">
              {{ `${highlight.title}: ` }}
            </p>
            <div class="highlight-notation">
              <p v-html="highlight.description" />
            </div>
            <Link icon="content-copy" @click="copyToClipboard(highlight.description)" />
          </div>
        </div>
      </template>
    </v-popover>
  </div>
</template>

<script>
import Link from '@/components/Link';
import { highlightsIdentifier } from './highlightsTypes';
export default {
  components: {
    Link
  },
  mounted() {
    if (this.highlights) this.info = highlightsIdentifier(this.highlights);
  },
  computed: {
    isDarkMode() {
      return this.$store.getters.DARK;
    }
  },
  data() {
    return {
      info: []
    };
  },
  methods: {
    copyToClipboard(text) {
      let copy = '';
      copy = text.replace('<em>', '');
      copy = copy.replace('</em>', '');
      const el = document.createElement('textarea');
      el.value = copy;
      document.body.appendChild(el);
      el.select();
      document.execCommand('copy');
      document.body.removeChild(el);
      this.$buefy.notification.open({
        message: this.$t('global.copiedToClipboard'),
        type: 'is-success'
      });
    }
  },
  props: {
    highlights: {
      type: Object,
      required: true,
      default: () => {}
    },
    disabled: { type: Boolean, default: false }
  }
};
</script>

<style lang="sass" scoped>
.elastic-search-highlight-icons
  display: flex
  z-index: 99
  .highlight-icon
    margin-right: 10px
    color: $primary
.highlight-notation
  max-width: 400px
</style>
<style lang="sass" scoped>
.dark
  .highlight-icon
    color: $primary-dark !important
</style>

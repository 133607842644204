<template>
  <b-dropdown
    aria-role="list"
    class="notifications"
    position="is-bottom-left"
    :can-close="canCloseMenu && ['outside']"
    :close-on-click="false"
  >
    <template #trigger>
      <b-button class="btn-notifications" type="is-primary" :icon-right="config.icon">
        <b-icon v-if="hasUnreaded" icon="circle-medium" class="unreaded-dot" />
      </b-button>
    </template>
    <div class="n-header flex f-jsb f-ai">
      <div>
        <strong>{{ config.title }}</strong>
      </div>
      <Link underlined @click="onMarkAllAttended" :disabled="!hasUnreaded" :loading="loading">
        {{ $t('notification.asRead') }}
      </Link>
    </div>
    <div>
      <Infinite-scroll
        class="n-container"
        api="notification"
        v-model="notifications"
        :perPage="20"
        :internalParams="config.endPointParams"
        :refreshTopDataRate="refreshRate"
      >
        <Notification
          v-for="notification in notifications"
          :key="notification.id"
          :notification="NewNotificationParsed(notification)"
          :setNotificationAttendedFunction="setNotificationAttended"
          :isAsynchronous="isAsynchronous"
        />
      </Infinite-scroll>
    </div>
  </b-dropdown>
</template>

<script>
import { InfiniteScroll, Link } from '@/components';
import Notification from './Notification';
import store from '@/store';

export default {
  components: {
    InfiniteScroll,
    Link,
    Notification
  },
  created() {
    this.$store.subscribe(
      (
        { type },
        { dispatch: { multipleDeliveries }, notification: { async_processing, message } }
      ) => {
        if (this.isAsynchronous && type == 'notification/setAsyncProcess')
          this.notifications.unshift(async_processing[0]);

        if (type === 'dispatch/multipleDeliveries') {
          setTimeout(() => {
            this.canCloseMenu = !!multipleDeliveries.isClosed;
          }, 100);
        }
        if (type == 'notification/onMessage') this.onMessage(message);
      }
    );
  },
  mounted() {
    this.init();
  },
  beforeDestroy() {
    clearInterval(this.interval);
  },
  data() {
    return {
      canCloseMenu: true,
      interval: null,
      loading: false,
      notifications: []
    };
  },
  computed: {
    config() {
      const { isAsynchronous } = this;
      return {
        title: isAsynchronous
          ? this.$t('asynchronousRequest.panelTitle')
          : this.$t('notification.title'),
        icon: isAsynchronous ? 'progress-alert' : 'bell',
        endPointParams: [`show_async_request=${isAsynchronous ? 1 : 0}`]
      };
    },
    hasUnreaded() {
      return !!this.notifications.filter((n) => !n.is_attended)[0];
    }
  },
  methods: {
    init() {
      this.$pushNotifications.onMessage = this.dispatchMessage;
    },
    dispatchMessage(message) {
      this.$store.dispatch('notification/onMessage', message);
    },
    onMessage(notification) {
      notification.dispatch = true;
      this.setPushNotification(notification);
      this.doMessageActions(notification);
      if (notification.type === 'override_sign_in') {
        store.dispatch('deAUTH', { allowSignOut: false, redirect: true });
      }
    },
    doMessageActions({ type }) {
      switch (type) {
        case 'file_import':
          return this.$store.commit('schedule/fileImports', { type: 'update' });
        default:
          return console.log(`__No default action for "${type}" notification__`);
      }
    },
    NewNotificationParsed(notification) {
      if (notification.statusId === undefined) {
        notification.statusId = 0;
      }
      if (typeof notification.jsonBody == 'string')
        notification.jsonBody = JSON.parse(notification.jsonBody);
      return notification;
    },
    setPushNotification(data) {
      const notification = this.NewNotificationParsed(data);
      if (!!notification.jsonBody?.is_async !== !!this.isAsynchronous) return;
      if (!notification.created_at) notification.created_at = new Date();
      this.notifications.unshift(notification);
    },
    async setNotificationAttended(notificationId) {
      if (!notificationId) return;
      try {
        await this.Api.patch(`/notification/${notificationId}/attended`);
        return true;
      } catch (err) {
        return;
      }
    },

    onMarkAllAttended() {
      const unattended = this.notifications.filter((notification) => !notification.is_attended);
      this.loading = true;
      let promises = [];
      unattended.map(async (notification) => {
        if (!notification.id) return (notification.is_attended = 1);
        const myPromise = this.setNotificationAttended(notification.id);
        promises.push(myPromise);
        const result = await myPromise;
        if (result) notification.is_attended = 1;
      });
      Promise.all(promises).finally(() => {
        this.loading = false;
      });
    }
  },
  props: {
    isAsynchronous: { type: Boolean, default: false },
    refreshRate: { type: Number, default: 600000 }
  }
};
</script>

<style lang="sass" scoped>
.notifications
  ::v-deep
    .dropdown-menu
      z-index: 999
    .dropdown-content
      padding-bottom: 0
    .btn-notifications
      .icon.is-small
        margin-left: calc(-0.5em - 1px)
        margin-right: calc(-0.5em - 1px)
    .dropdown-menu
      width: 440px
.n-header
  margin-top: -8px
  border-radius: $br-sm $br-sm 0 0
  padding: 0 10px
  min-height: 45px
  background: $main-background
.n-container
  max-height: 500px
  overflow: auto
.unreaded-dot
  position: absolute
  top: 0px
  right: 8px
  color: $red-300
  z-index: 1
.dark
  .n-header
    background: $dark-500
    strong
      color: white
</style>

<style lang="sass">
.dark .notifications .unreaded-dot
  color: $blue-800
</style>

<template>
  <div class="pastServiceCard flex f-column f-jsb">
    <b-modal v-if="hasModal" v-model="isModalActive">
      <Card>
        <template v-if="modalOptions.title" #header>{{ modalOptions.title }}</template>
        <slot name="modal-body" />
      </Card>
    </b-modal>
    <template v-if="loading">
      <div>
        <b-skeleton />
        <b-skeleton height="50" />
        <b-skeleton />
        <b-skeleton />
      </div>
    </template>
    <template v-else>
      <div class="psc-header flex f-jsb f-ai" :class="`psc-${theme}`">
        <span v-if="title" class="psc-title mr-2">{{ title }}</span>
        <b-icon v-if="icon" class="psc-icon" :icon="icon" />
      </div>
      <div v-if="text || subText || $slots.default" class="psc-body">
        <div class="psc-message">
          {{ text }}
        </div>
        <div v-if="subText" class="psc-subText">
          {{ subText }}
        </div>
        <slot />
      </div>
      <div v-if="hasModal" class="psc-footer">
        <Link @click="isModalActive = true">
          {{ $t('global.viewDetails') }}
        </Link>
      </div>
    </template>
  </div>
</template>

<script>
import Card from '@/components/Card.vue';
import Link from '@/components/Link';
export default {
  components: {
    Card,
    Link
  },
  computed: {
    hasModal() {
      return this.modalOptions ? true : false;
    }
  },
  data() {
    return {
      isModalActive: false
    };
  },
  props: {
    icon: { type: String, default: '' },
    modalOptions: { type: Object, default: () => null },
    loading: { type: Boolean, default: false },
    subText: { type: String, default: '' },
    text: { type: [String, Number], default: '' },
    theme: { type: String, default: 'default' },
    title: { type: String, default: '' }
  }
};
</script>
<style lang="sass">
.dark
  .pastServiceCard
    background: $dark-mode-card-items-background
    .psc-header
      color: $gray-500
    .psc-body
      color: white
</style>

<style lang="sass" scoped>
.b-skeleton
  margin-top: 0
  margin-bottom: 0
.pastServiceCard
  min-height: 149px
  width: calc(25% - 10px)
  border: 1px solid $main-separators
  border-radius: $br-md
  padding: 10px
  margin: 0 5px 10px 5px
  color: $field-label
  @media screen and ( max-width: $bp-xxl )
    width: calc(50% - 10px)
  .psc-success
    color: $green-dark
  .psc-danger
    color: $red-dark
  .psc-warning
    color: $yellow-dark
  .psc-icon
    margin-right: 10px
  .psc-title
    font-weight: bold
  .psc-body
    font-size: 24px
    font-weight: bold
    margin-top: 10px
    display: flex
    flex-flow: column
    height: 100%
    justify-content: center
    .psc-message
      height: 100%
      display: flex
      align-items: center
    .psc-subText
      margin-top: 10px
      font-size: 16px
      font-weight: normal
  .psc-footer
    display: flex
    margin-top: 5px
</style>

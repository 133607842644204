<template>
  <Modal
    id="billing-modal"
    :isOpen.sync="isModalOpen"
    @cancel="resetModal"
    :header="$t('billing.billing_report')"
  >
    <Field
      class="input-datepicker"
      v-model="monthSelected"
      :label="$t('billing.selectMonth')"
      icon-right="calendar-blank"
      readonly
    />
    <DatePicker
      v-model="monthSelected"
      type="month"
      :maxDate="maxDate"
      :minDate="minDate"
      responseFormat="M/Y"
      inline
    />
    <template #buttons>
      <Button
        :label="$t('billing.sendReport')"
        @click="onSendReport"
        type="is-primary"
        :loading="loading"
      />
    </template>
  </Modal>
</template>
<script>
import { toast } from '@/utils/dialog';
import { Modal, DatePicker, Button, Field } from '@/components';
export default {
  components: {
    Modal,
    DatePicker,
    Button,
    Field
  },
  data() {
    return {
      loading: false,
      monthSelected: this.$moment(new Date()).format('M/Y'),
      isModalOpen: this.isOpen
    };
  },
  computed: {
    rangeDate() {
      let from_date = this.$moment(this.monthSelected, 'M/Y').format('Y-M-D');
      let to_date = this.$moment(from_date, 'Y-M-D').endOf('month').format('Y-M-D');
      let rangeDate = {
        from_date,
        to_date
      };
      return rangeDate;
    },
    maxDate() {
      return this.$moment().toDate();
    },
    minDate() {
      return this.$moment('2022-1-1', 'Y-M-D').subtract(1, 'days').toDate();
    }
  },
  methods: {
    async onSendReport() {
      this.loading = true;
      try {
        await this.Api.post(`/employees/reports/billing`, this.rangeDate);
        toast(
          'success',
          `${this.$t('billing.billingSend')} <br/> <br/> ${this.$t('toast.checkSpamNote')}`,
          3000
        );
        this.resetModal();
      } catch (error) {
        console.log(error);
      }
      this.loading = false;
    },
    resetModal() {
      this.monthSelected = this.$moment(new Date()).format('M/Y');
      this.isModalOpen = false;
    }
  },
  watch: {
    isOpen(value) {
      this.isModalOpen = value;
    },
    isModalOpen(value) {
      this.$emit('update:isOpen', value);
      if (!value) this.resetModal();
    }
  },
  props: {
    isOpen: { type: Boolean, default: false }
  }
};
</script>
<style lang="sass" scoped>
#billing-modal
  ::v-deep
    .modal-content-container
      .slot
        display: flex
        flex-direction: column
        justify-content: center
        align-items: center
        .input-datepicker
          min-width: 304px
</style>
<style lang="sass">
.dark
    #billing-modal
      .modal-content-container
        .slot
          .datepicker-body
            .is-selectable
              color: $white
              &:hover
                background-color: $blue-500
            .is-unselectable
              color: $disable-dark
</style>

export function handleLocation() {
  return new Promise((resolve) => {
    if (!('geolocation' in navigator)) {
      throw new Error('Geolocation is not available.');
    }
    navigator.geolocation.getCurrentPosition(
      (location) => {
        resolve(location);
      },
      (err) => {
        throw err;
      }
    );
  });
}

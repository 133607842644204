<template>
  <!-- //! Will Notifications be in store?  -->
  <b-collapse
    animation="slide"
    :open="active"
    @open="active = true"
    @close="active = false"
    v-if="hasChildren"
  >
    <template #trigger>
      <menu-item :i18n="i18n" to="/" :icon="icon" :active="active" routerIs="span">
        <b-icon v-if="active" class="icon-dropdown" icon="chevron-up" />
        <b-icon v-else class="icon-dropdown" icon="chevron-down" />
      </menu-item>
    </template>
    <div ref="menuItems" class="menuItems">
      <menu-item
        v-for="({ path, meta }, i) of menuItems"
        :key="i"
        :to="path"
        inSection
        :i18n="meta.i18n"
        :permission="meta.secure && meta.secure.permission"
      />
    </div>
  </b-collapse>
</template>
<script>
import menuItem from './menuItem';
export default {
  components: {
    menuItem
  },
  methods: {
    checkInRouter(path = this.$route.path) {
      return path.indexOf(this.route) >= 0;
    }
  },
  mounted() {
    const { childElementCount } = this.$refs?.menuItems || {};
    this.hasChildren = childElementCount > 0;
  },
  computed: {
    menuItems() {
      const routes = this.$router.getRoutes();
      const menuItems = [];
      this.items.map((name) =>
        routes.map((r) => {
          if (r.name == name) {
            menuItems.push(r);
          }
        })
      );
      return menuItems;
    }
  },
  data() {
    return {
      hasChildren: true,
      active: this.checkInRouter()
    };
  },
  watch: {
    $route(value) {
      this.active = this.checkInRouter(value.path);
    }
  },
  props: {
    route: { type: String, default: '' },
    i18n: { type: String, default: '' },
    icon: { type: String, default: '' },
    items: { type: Array, default: () => [] }
  }
};
</script>
<style lang="sass" scoped>
.icon-dropdown
  margin-right: -10px
.menuItems
  display: flex
  flex-flow: column
</style>

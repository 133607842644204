<template>
  <div id="shareRxModal">
    <Modal
      :isOpen.sync="isModalOpen"
      :header="$t('dispatch.shareRxLink')"
      :loading="loading"
      size="is-large"
      @cancel="() => {}"
      @save="onSubmit"
      :buttonLabel="$t('button.share')"
    >
      <div class="flex">
        <div id="share-link" class="flex f-column f-jc">
          <p>{{ $t('global.shareLink') }}:</p>
          <Link underlined :href="shareLink" target="_blank" ref="mylink">{{ shareLink }}</Link>

          <div>
            <b-button icon-left="content-copy" type="is-primary" outlined @click="copyToClipboard">
              {{ $t('button.copy') }}
            </b-button>
          </div>
        </div>

        <div id="share-details">
          <form ref="form" @submit.prevent="onSubmit">
            <Field id="send-to-text" :label="$t('global.sendTo') + ':'">
              <b-radio
                v-model="sendTo.type"
                native-value="sms"
                type="primary"
                @input="clearField"
                :disabled="loading.phones"
              >
                SMS
              </b-radio>

              <b-radio
                v-model="sendTo.type"
                native-value="email"
                type="primary"
                @input="clearField"
              >
                {{ $t('global.email') }}
              </b-radio>
            </Field>

            <div v-if="sendTo.type === 'email'" class="flex filed-add-btn">
              <Field
                class="email-field"
                type="email"
                :label="$t('global.email')"
                v-model="sendTo.value"
                :disabled="isEditable ? false : true"
                :validation-message="$t('validations.email')"
                @add="allowEditing"
                required
              />
            </div>
            <div v-else class="flex f-column">
              <PhoneSelector
                :label="$t('contact.mobile')"
                dropdown-position="top"
                @input="handlePhoneChange"
                @onPhonesReady="setPhones"
                :loading.sync="loading.phones"
                v-model="sendTo.value"
                :url="phonesUrl"
                type="is-mobile"
              />
              <template v-if="sendTo.value">
                <div class="flex mt-3" v-if="isDefaultPhone">
                  <b-icon icon="star" class="land-icon" />
                  <p class="ml-2">{{ $t('global.defaultPhone') }}</p>
                </div>
                <div class="flex mt-3" v-else>
                  <b-icon icon="check-decagram" class="mobile-icon" />
                  <p class="ml-2">{{ $t('dispatch.phoneAssociated') }}</p>
                </div>
              </template>
            </div>
          </form>
        </div>
      </div>
    </Modal>
  </div>
</template>

<script>
import { toast } from '@/utils/dialog';
import { Field, Link, Modal, PhoneSelector } from '@/components';

export default {
  components: {
    Field,
    Link,
    Modal,
    PhoneSelector
  },
  data() {
    return {
      loading: { save: false, get: false, phones: false },
      isModalOpen: false,
      keys: { phoneKey: 0 },
      sendTo: { type: 'email', value: null },
      isEditable: false,
      rxUrl: null,
      isDefaultPhone: true,
      phonesUrl: null,
      phones: []
    };
  },
  computed: {
    shareLink() {
      return `${location.origin}/#/tracking/${this.trackingToken}`;
    }
  },
  methods: {
    async onSubmit() {
      this.loading.save = true;
      if (this.validate()) {
        try {
          await this.Api.post(`/delivery_manifest/${this.deliveryId}/request_url`, {
            send_by: this.sendTo.type,
            email: this.sendTo.value
          });
          this.sendTo = {};
          toast('success', this.$t('messages.saved'), 5000);
          this.isModalOpen = false;
          this.$emit('save');
        } catch (error) {
          console.error(error);
        }
      }
      this.loading.save = false;
    },
    validate() {
      let htmlValidator = false;
      if (!this.isEditable) {
        this.isEditable = true;
        return false;
      }
      if (this.$refs.form) {
        htmlValidator = this.$refs.form.checkValidity();
        if (!htmlValidator) this.$refs.form.reportValidity();
      }
      return htmlValidator;
    },
    copyToClipboard() {
      const link = this.shareLink;
      const el = document.createElement('textarea');
      el.value = link;
      document.body.appendChild(el);
      el.select();
      document.execCommand('copy');
      document.body.removeChild(el);
      this.$buefy.notification.open({
        message: this.$t('global.copiedToClipboard'),
        type: 'is-success'
      });
    },
    allowEditing() {
      this.isEditable = !this.isEditable;
    },
    clearField() {
      this.isEditable = false;
      this.sendTo.value = '';
    },
    handlePhoneChange(value) {
      this.isDefaultPhone = false;
      this.phones.forEach((e) => {
        if (e.phone_number === value) {
          this.isDefaultPhone = true;
        }
      });
    },
    onPhonesLoadingChange(value) {
      this.loading.phones = value;
    },
    setPhones(value) {
      this.phones = [...value];
    }
  },
  watch: {
    isOpen(value) {
      this.isModalOpen = value;
      if (!value) this.sendTo = { type: 'email', value: null };
    },
    isModalOpen(value) {
      this.$emit('update:isOpen', value);
    },
    delivery(value) {
      this.phonesUrl = `/participants/${value.participant_id}/phone_numbers?sort=-is_default`;
    }
  },
  props: {
    isOpen: {
      type: Boolean,
      default: false
    },
    deliveryId: { type: Number, default: null },
    trackingToken: { type: String, default: null },
    delivery: { type: Object, default: () => {} }
  }
};
</script>

<style lang="sass" scoped>
$separatorColor: $gray-100
#shareRxModal
  #share-link
    width: 55%
    word-wrap: break-word
    padding-right: 2em
    border-right: 1px solid $separatorColor
    a
      display: contents
    button
      margin-top: 0.75em
  #share-details
    margin-right: 0.5em
    padding-left: 1em
    width: 45%
    #send-to-text
      margin-bottom: 1.5em
      ::v-deep .label
        font-weight: normal
        margin-bottom: 1em
    ::v-deep input
      width: 100%
      border-top-right-radius: 0!important
      border-bottom-right-radius: 0!important
    .land-icon
      color: $blue-600
    .mobile-icon
      color: $green-600
    .email-field
      width: 214px
    .filed-add-btn
      margin-right: 0!important
      .button.addBtn
        border-radius: 0px 4px 4px 0px
        margin-bottom: 5px
      ::v-deep .b-tooltip
        align-self: flex-end
        margin-bottom: 7px
</style>

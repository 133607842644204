<template>
  <div class="message-badge">
    <div class="message-content" :class="type + ' ' + size">
      <b-icon :icon="icon" class="icon" />
      <slot v-if="$slots.default" />
      <template v-else>
        {{ message }}
      </template>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    message: { type: String, default: 'Warning!' },
    size: { type: String, default: 'is-small' },
    type: { type: String, default: 'is-danger' },
    icon: { type: String, default: 'alert-circle-outline' }
  }
};
</script>

<style lang="sass" scoped>
.message-badge
  .message-content
    align-content: center
    border-radius: 4px 4px 4px 4px
    display: flex
    font-size: 16px
    font-weight: 400
    margin-bottom: 0.8rem
    padding: 0.563rem
    @each $className, $colorObject in $messageBadgeColors
      &.is-#{$className}
        background: map-get($colorObject, "background")
        border-left: 4px solid map-get($colorObject, "color")
        color: map-get($colorObject, "color")
    .icon
      align-content: center
      display: flex
      margin-right: 0.25rem

.is-small
  max-width: 490px
.is-medium
  max-width: 690px
.is-large
  max-width: 890px
.is-fit-content
  max-width: fit-content
</style>
<style lang="sass">
.dark
  .message-badge
    .message-content
      @each $className, $colorObject in $messageBadgeColorsDark
        &.is-#{$className}
          background: map-get($colorObject, "background")
          border-left: 4px solid map-get($colorObject, "color")
          color: map-get($colorObject, "color")
</style>

<template>
  <div class="countdown">
    <div v-if="hours > 0" class="countdown__block">
      <div class="countdown__digit">{{ hours }}</div>
      <div v-if="defaultLabel" class="countdown__text">Min</div>
    </div>
    <div class="countdown__block">
      <div class="countdown__digit">{{ minutes }}</div>
      <div v-if="defaultLabel" class="countdown__text">Min</div>
    </div>
    <div class="countdown__block">
      <div class="countdown__digit">{{ seconds }}</div>
      <div v-if="defaultLabel" class="countdown__text">Seg</div>
    </div>
  </div>
</template>
<script>
export default {
  mounted() {
    if (this.startOnMounted) {
      if (this.duration >= 0) this.setCountdown();
    }
  },
  data() {
    return {
      seconds: 0,
      minutes: 0,
      hours: 0,
      duration: null,
      limitRef: this.limit,
      counter: null
    };
  },
  methods: {
    setCountdown() {
      const initial = this.$moment(this.time).format('YYYY-MM-DD HH:mm:ss');
      const diff = this.$moment(this.$moment()).diff(initial);
      this.duration = this.limit * 60000 - diff;

      this.counter = setInterval(() => {
        this.duration = this.$moment.duration(this.duration - this.interval, 'milliseconds');
        this.hours = this.duration.hours();
        if (this.hours < 10) this.hours = String(this.hours).padStart(2, '0');
        this.minutes = this.duration.minutes();
        if (this.minutes < 10) this.minutes = String(this.minutes).padStart(2, '0');
        this.seconds = this.duration.seconds();
        if (this.seconds < 10) this.seconds = String(this.seconds).padStart(2, '0');
      }, this.interval);
    }
  },
  watch: {
    duration(value) {
      if (value <= 0) {
        clearInterval(this.counter);
        this.duration = this.limitRef;
        this.$emit('onTimeUp', this.time);
      }
    }
  },
  props: {
    time: { type: String, default: null },
    defaultLabel: { type: Boolean, default: false },
    limit: { type: Number, default: null },
    interval: { type: Number, default: 1000 },
    startOnMounted: { type: Boolean, default: false }
  }
};
</script>
<style lang="sass">
.countdown
  display: flex
  justify-content: center
  &__block
    text-align: center
    padding: 0px 8px
    position: relative
    &:first-child
      padding-left: 0
      .countdown__digit
        &:before
          display: none
    &:last-child
      padding-right: 0
  &__text
    text-transform: uppercase
    margin-bottom: 5px
  &__digit
    font-weight: bold
    line-height: 1
    &:before
      content: ':'
      position: absolute
      left: -5px
</style>

<template>
  <component :is="markerType" v-bind="$props" @click="() => $emit('click')">
    <slot />
  </component>
</template>

<script>
import { availableMapTypes } from '@/components/Map/Plugins';

export default {
  components: {
    gMapCluster: () => import('./gMaps/gMapsCluster.vue'),
    osCluster: () => import('./OSM/osCluster.vue')
  },
  updated() {
    console.log('::Cluster ⏱️');
  },
  data() {
    return {};
  },
  computed: {
    mapType() {
      return window.localStorage.getItem('NMap_mapType');
    },
    isOSMap() {
      return this.mapType === availableMapTypes.OSM;
    },
    markerType() {
      switch (this.mapType) {
        case availableMapTypes.google:
          return 'gMapCluster';
        case availableMapTypes.OSM:
          return 'osCluster';
      }
      return 'gMapCluster';
    }
  },
  methods: {},
  props: {
    map: { type: Object },
    gridSize: { type: Number, default: 20 },
    minimumClusterSize: { type: Number, default: 3 },
    imagePath: { type: String },
    zoomOnClick: { type: Boolean, default: true }
  }
};
</script>

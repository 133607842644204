<template>
  <Tooltip
    v-if="showTooltipOnDisabled || (label && !disabled && hasPermission)"
    :label="label"
    :position="position"
    class="tooltip-link"
    :baseClass="tooltipClass"
  >
    <router-link v-if="isRouter" v-bind="{ ...$props, ...$attrs }">
      <slot />
    </router-link>
    <Link v-else v-bind="{ ...$props, ...$attrs }" @click="onClick">
      <slot />
    </Link>
  </Tooltip>
  <router-link v-else-if="isRouter && hasPermission" v-bind="{ ...$props, ...$attrs }">
    <slot />
  </router-link>
  <Link v-else-if="hasPermission" v-bind="{ ...$props, ...$attrs }" @click="onClick">
    <slot />
  </Link>
</template>

<script>
import Link from './_link.vue';
import Tooltip from '@/components/Tooltip';
export default {
  components: {
    Tooltip,
    Link
  },
  computed: {
    hasPermission() {
      return this.Secure.permissionValidator(this.permission);
    }
  },
  methods: {
    onClick(e) {
      this.$emit('click', e);
    }
  },
  props: {
    disabled: { type: [Boolean, Number], default: false },
    isRouter: { type: Boolean, default: false },
    label: { type: String, default: '' },
    permission: { type: Object, default: () => {} },
    position: { type: String, default: 'top' },
    showTooltipOnDisabled: { type: Boolean, default: false },
    tooltipClass: { type: String }
  }
};
</script>

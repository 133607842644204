const RingCentral = require('@ringcentral/sdk').SDK;
import { clientId, clientSecret, server, username, password, extension } from './constants';
var rcsdk = new RingCentral({ server, clientId, clientSecret });
const platform = rcsdk.platform();
platform.login({ username, password, extension });
platform.on(platform.events.loginSuccess, function (response) {
  console.log('📞', server, { response });
});
export const send_sms = async (recipient, text) => {
  try {
    var resp = await platform.post('/restapi/v1.0/account/~/extension/~/sms', {
      from: { phoneNumber: username },
      to: [{ phoneNumber: recipient }],
      text
    });
    var jsonObj = await resp.json();
    console.log('SMS sent. Message status: ' + jsonObj.messageStatus);
    return jsonObj;
  } catch (e) {
    console.log(e.message);
  }
};
export const call_ringout = async (recipient) => {
  console.log('call_ringout', recipient);
  try {
    var resp = await platform.post('/restapi/v1.0/account/~/extension/~/ring-out', {
      from: { phoneNumber: username },
      to: { phoneNumber: recipient },
      playPrompt: false
    });
    var jsonObj = await resp.json();
    console.log('Call placed. Call status: ' + jsonObj.status.callStatus);
  } catch (e) {
    console.log(e.message);
  }
};

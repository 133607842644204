<template>
  <component :is="type" v-bind="$props" ref="NMap" class="NMap" @ready="handleReady">
    <slot />
  </component>
</template>
<script>
export default {
  created() {
    this.saveMapType();
  },
  updated() {
    console.log('::🗺️ ⏱️');
  },
  components: {
    gMap: () => import('./gMaps/gMap'),
    osMap: () => import('./OSM/osMap')
  },
  data() {
    return {
      key: 1,
      isReady: false
    };
  },
  computed: {
    mapObject() {
      this.key;
      return this.$refs.NMap.mapObject;
    },
    NMap() {
      this.key;
      return this.$refs.NMap;
    }
  },
  methods: {
    setZoom(zoom) {
      this.mapObject.setZoom(zoom);
    },
    panTo(position) {
      this.setCenter(position);
    },
    setCenter(position) {
      this.mapObject.panTo(position);
    },
    autoZoom() {
      this.NMap.autoZoom.apply(this, arguments);
    },
    handleReady(map) {
      this.isReady = true;
      this.$emit('ready', map);
    },
    saveMapType(type = this.type) {
      window.localStorage.setItem('NMap_mapType', type);
    }
  },
  watch: {
    type(value) {
      this.key++;
      this.saveMapType(value);
    }
  },
  props: {
    center: { type: Object, default: () => {} },
    darkMode: { type: Boolean, default: false },
    options: { type: Object, default: () => {} },
    type: { type: String, default: 'gMap' },
    zoom: { type: Number, default: 10 }
  }
};
</script>
<style lang="sass" scoped>
.NMap
  min-height: 300px
  z-index: 0
</style>

<template>
  <div id="history-information">
    <!-- Participant main information -->
    <div id="participant-information">
      <div class="flex f-jsb mb-3">
        <strong class="subTitle"> {{ eventsData.participant_name }} </strong>
        <div v-if="eventsData.tracking_number" class="gray-font">
          {{ $t('global.order') }}: #{{ eventsData.tracking_number }}
        </div>
      </div>
      <p v-if="eventsData.full_address">
        {{ $t('contact.address') }}: {{ eventsData.full_address }}
      </p>
      <p v-if="eventsData.phone_number" class="flex">
        {{ $t('contact.phone') }}: <Phone-viewer :phone="eventsData.phone_number" class="ml-1" />
      </p>
      <p v-if="eventsData.email">{{ `${$t('contact.email')}: ${eventsData.email}` }}</p>
      <p v-if="eventsData.notes">{{ $t('global.notes') }}: {{ eventsData.notes }}</p>
    </div>
    <!-- Transitions by date -->
    <template v-if="!showAttachment">
      <div
        class="daily-activity"
        v-for="(event, index) in eventsData.life_cycle"
        :key="'dsc-ch-' + index"
      >
        <div class="title-container mb-2">
          <strong class="subTitle">
            <DateTime :date="event.date" format="MMM/DD/Y" class="mt-2" />
          </strong>
          <hr />
        </div>
        <div
          class="event-container flex f-jsb mb-4 pl-3"
          v-for="(transition, i) in event.transitions"
          :key="'dsc-ch-t-' + i"
        >
          <div class="flex">
            <p class="t-time gray-font">
              <DateTime
                :date="transition.datetime"
                :offset="transition.time_offset"
                format="hh:mm A"
              />
            </p>
            <b-icon icon="circle-small" class="light-gray-font" />
            <div>
              <p class="gray-font mb-2">
                {{ statusName(transition.delivery_event_status_id, transition.status_name) }}
              </p>
              <div v-if="getMessage(transition)" class="flex mb-2">
                {{ getMessage(transition) }}
              </div>
              <div v-if="needsReason(transition.delivery_event_status_id)" class="flex mb-2">
                {{ $t('global.reason') }}: {{ getReason(transition) }}
              </div>
              <div class="new-address" v-if="transition.previous_participant_full_address">
                <p>
                  {{ $t('address.previous') }}: {{ transition.previous_participant_full_address }}
                </p>
              </div>
              <div
                v-if="
                  !transition.path_evidence &&
                  (transition.delivery_attempt_id > 0 || transition.non_picked_up_details_id > 0)
                "
                class="unavailable mb-2"
              >
                {{ $t('deliveries.attachmentsUnavailable') }}
              </div>
              <div
                v-if="!signatureExists(transition.signature) && transition.return_manifest_id"
                class="unavailable mb-2"
              >
                {{ $t('deliveries.signatureUnavailable') }}
              </div>
              <Link
                v-if="
                  transition.return_manifest_id ||
                  transition.path_evidence ||
                  signatureExists(transition.signature)
                "
                class="mb-2"
                @click="getAttachments(transition)"
                underlined
                :loading="transition.loading"
              >
                <strong>{{ $t('global.viewDetails') }}</strong>
              </Link>
            </div>
          </div>
          <DeliveryStatusSignUI
            class="mb-2"
            :delivery="transition"
            :isCollect="oDelivery.delivery_event_type_id === 8"
          />
        </div>
      </div>
    </template>

    <!-- Transitions attachments -->
    <div v-if="showAttachment" class="attachment-detail">
      <div class="flex f-ai mb-4">
        <Link icon="chevron-left" @click="showAttachment = false" />
        <strong class="ml-4">{{ $t('global.attachmentDetail') }}</strong>
      </div>

      <div
        v-for="(attachment, i) in attachments"
        :key="'dsc-ch-t-attch' + i"
        class="event-container pl-3 mb-5"
      >
        <div class="flex f-jsb">
          <div class="flex">
            <p class="gray-font">
              <DateTime
                :date="attachment.datetime"
                :offset="attachment.time_offset"
                format="MMM/DD/Y hh:mm A"
              />
            </p>
            <b-icon icon="circle-small" class="light-gray-font" />
            <p class="gray-font">{{ attachment.status_name }}</p>
          </div>
          <DeliveryStatusSignUI
            :delivery="attachment"
            :isCollect="oDelivery.delivery_event_type_id === 8"
          />
        </div>
        <hr />
        <p>{{ getMessage(attachment) }}</p>
        <div v-if="attachment.return_manifest_id" class="flex mt-2">
          <div class="mr-5">
            <strong>{{ $t('deliveries.report.returnHour') }}</strong>
            <DateTime
              :date="attachment.return_created_at"
              :offset="attachment.time_offset"
              format="hh:mm A"
            />
          </div>
          <div>
            <strong>{{ $t('deliveries.report.returnDestination') }}</strong>
            <p class="mb-2">{{ attachment.cdp_address || '-' }}</p>
          </div>
        </div>
        <div v-else>
          <div v-if="attachment.path_evidence" class="attachment-signature flex f-ai f-jc mt-4 p-4">
            <Picture :src="attachment.path_evidence" />
          </div>
          <div
            v-if="signatureExists(attachment.signature)"
            class="attachment-signature flex f-ai f-jc mt-4 p-4"
          >
            <Picture :src="'data:image/png;base64,' + attachment.signature" />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { PhoneViewer, DateTime, DeliveryStatusSignUI, Link, Picture } from '@/components';
import { getTypeData, eventStatusAsEventType } from '../DeliveryTypes';

export default {
  components: {
    DeliveryStatusSignUI,
    DateTime,
    Link,
    Picture,
    PhoneViewer
  },
  data() {
    return {
      attachments: [],
      delivery: {},
      loading: { get: false },
      open: false,
      showAttachment: false,
      unsubscribe: null
    };
  },
  methods: {
    async getAttachments(transition) {
      transition.loading = true;
      this.attachments = [];
      if (transition.return_manifest_id) {
        let cdp_address = await this.getCDP(transition.return_destination_id);
        this.attachments.push({ ...transition, cdp_address });
      } else if (this.signatureExists(transition.signature) || transition.path_evidence) {
        this.attachments.push({ ...transition });
      }
      transition.loading = false;
      this.showAttachment = true;
    },
    async getCDP(id) {
      try {
        const { data } = await this.Api.get(`cdp/${id}`);
        return data.full_address;
      } catch (error) {
        console.error(error);
      }
    },
    getMessage(transition) {
      let status = this.getStatus(transition);
      let msg = '';
      if (transition.delivery_event_status_id == 6) {
        msg = status + transition.receptor_name ? transition.receptor_name : '';
      } else {
        msg = `${
          transition.created_by_name
            ? transition.created_by_name
            : this.$t('global.driver').toUpperCase()
        } ${
          transition.receptor_name
            ? this.$t('deliveries.to').toLowerCase() + ' ' + transition.receptor_name
            : ''
        }`;
      }

      return msg ? `${status} ${msg}` : '';
    },
    getReason(transition) {
      if (!transition.non_delivery_reason_description) return '-';
      return transition.non_delivery_reason_id === 4
        ? transition.other_non_delivery_reason_description
        : transition.non_delivery_reason_description;
    },
    useEventTypes(delivery) {
      const { event_type_is_mandatory, delivery_event_type_id } = delivery;
      return (
        event_type_is_mandatory || !!(eventStatusAsEventType.indexOf(delivery_event_type_id) + 1)
      );
    },
    getStatus(delivery) {
      const useEventTypes = this.useEventTypes(delivery);
      const { delivery_event_status_id, delivery_event_type_id } = delivery;
      let id = useEventTypes ? delivery_event_type_id : delivery_event_status_id;
      const status = getTypeData({ id, useEventTypes: useEventTypes });

      return status.actionMsg;
    },
    needsReason(eventStatusId) {
      return [5, 6, 7, 11].includes(eventStatusId); // Rescheduled, Rejected, Canceled, Failed
    },
    statusName(status_id, status_name) {
      const statuses = {
        1: this.$tc('global.scheduled'),
        2: this.$tc('global.inProgress'),
        3: this.$tc('global.attempted'),
        4: this.$tc('global.delivered'),
        5: this.$tc('global.reschedule', 2),
        6: this.$tc('global.rejected'),
        7: this.$tc('global.canceled'),
        8: this.$tc('global.pickedUp'),
        9: this.$tc('global.missing'),
        10: this.$tc('global.damaged'),
        11: this.$tc('global.failed')
      };

      return statuses[status_id] || status_name;
    },
    signatureExists(signature) {
      return signature && (signature || '').toUpperCase() !== 'X';
    }
  },
  props: {
    eventsData: { type: Object, default: () => {} },
    oDelivery: { type: Object, default: () => {} }
  }
};
</script>

<style lang="sass" scoped>
#history-information
  #participant-information
    margin-bottom: 32px
  .hide
    display: none
  .show
    display: inherit
  .gray-font
    color: $gray-700
    font-weight: 700
  .light-gray-font
    color: $gray-400
  .subTitle
    font-size: $f-lg
  .unavailable
    color: $gray-600
    font-size: 14px
    font-weight: bold
  hr
    position: relative
    top: -1rem
    margin: 0
    background-color: $gray-200
  .daily-activity hr
    margin-left: 8rem
  .attachment-detail hr
    margin-left: 18rem
    margin-right: 6.5rem
  .attachment-signature
    border: 1px solid $gray-200
    border-radius: $br-md
    min-height: 250px
  .t-time
    min-width: 75px
::v-deep
  p
    margin: 0
  .Picture
    .pe-container.loaded
      position: relative
    .letters.hidden
      display: none
</style>

<template>
  <Modal
    :isOpen.sync="open"
    :header="title"
    id="multipleDetailsModal"
    size="is-medium"
    :close="onClose"
  >
    <div id="main-container">
      <div id="driver-info">
        <strong class="subTitle"> {{ $t('run.driver') }}: {{ employee }} </strong>
        <div v-if="isMultipleReturn && !loading">
          <Link
            v-if="deliveries.length && signature && !showAttachment"
            class="mb-2"
            @click="showAttachment = true"
            underlined
          >
            <strong>{{ $t('global.viewAttachment') }}</strong>
          </Link>
          <div v-if="!signature" class="unavailable mb-2">
            {{ $t('deliveries.signatureUnavailable') }}
          </div>
        </div>
      </div>
      <template v-if="loading">
        <b-skeleton height="40" v-for="x in 3" :key="`sk-${x}`" />
      </template>
      <div v-else-if="deliveries.length" class="delivery">
        <div
          v-if="isMultipleReturn"
          class="attachment-detail"
          :class="showAttachment ? 'show' : 'hide'"
        >
          <div class="flex f-ai mb-4">
            <Link icon="chevron-left" @click="showAttachment = false" />
            <strong class="ml-4">{{ $t('global.attachmentDetail') }}</strong>
          </div>
          <div class="flex">
            <p class="gray-bold">
              <DateTime
                :date="deliveries[0].signature_created_at"
                :offset="deliveries[0].time_offset"
                format="MMM/DD/Y hh:mm A"
              />
            </p>
          </div>
          <hr />
          <p>{{ $t('dispatch.status.succesfullyReturned') }}</p>
          <div v-if="signature" class="attachment-signature flex f-ai f-jc mt-4 p-4">
            <Picture :src="'data:image/png;base64,' + signature" />
          </div>
        </div>
        <div :class="showAttachment ? 'hide' : 'show'">
          <div class="title-container">
            <strong class="subTitle">
              <DateTime
                :date="deliveries[0].delivery_date"
                format="MM/DD/Y"
                :offset="deliveries[0].time_offset"
              />
            </strong>
            <hr />
          </div>
          <div
            class="delivery-container flex pl-3 mb-5"
            v-for="(delivery, i) in deliveries"
            :key="i"
          >
            <div class="delivery-time">
              <p class="gray-bold">
                <DateTime :date="delivery.eta" format="MMM/DD/Y" :offset="delivery.time_offset" />
              </p>
              <p class="gray-bold">
                <DateTime :date="delivery.eta" format="hh:mm A" :offset="delivery.time_offset" />
              </p>
            </div>
            <b-icon icon="circle-small" class="gray-font" />
            <div class="delivery-info">
              <p class="gray-bold">{{ delivery.participant_name }}</p>
              <p>({{ $t('global.trackingNumber') }} {{ delivery.tracking_number }})</p>
            </div>
          </div>
        </div>
      </div>
      <Empty v-else />
    </div>
  </Modal>
</template>

<script>
import { DateTime, Link, Modal, Empty, Picture } from '@/components';

export default {
  components: { DateTime, Empty, Link, Modal, Picture },
  created() {
    this.unsubscribe = this.$store.subscribe(({ type }, { dispatch: { multipleDeliveries } }) => {
      if (type === 'dispatch/multipleDeliveries' && multipleDeliveries?.type) {
        this.prepareModal(multipleDeliveries);
      }
    });
  },
  destroyed() {
    this.unsubscribe();
  },
  data() {
    return {
      deliveries: [],
      employee: '',
      isMultipleReturn: false,
      loading: false,
      open: false,
      showAttachment: false,
      title: ''
    };
  },
  computed: {
    signature() {
      return this.deliveries.length && (this.deliveries[0].signature || '').toUpperCase() !== 'X'
        ? this.deliveries[0].signature
        : false;
    }
  },
  methods: {
    onClose() {
      this.$store.commit('dispatch/multipleDeliveries', { isClosed: true });
    },
    dataByTypes(type) {
      const dataTypes = {
        multiple_package_picked_up: {
          getEvents: this.getPickUpEvents,
          title: this.$t('dispatch.pickupDetails')
        },
        multiple_package_return: {
          getEvents: this.getReturnedEvents,
          title: this.$t('dispatch.returnDetails')
        }
      };
      return dataTypes[type] || { title: 'Missing type' };
    },
    async getPickUpEvents({ delivery_manifest_id, created_at, created_by }) {
      try {
        const { data } = await this.Api.get(
          `delivery_manifest/${delivery_manifest_id}/delivery_events/picked_up?created_at=${created_at}&created_by=${created_by}`
        );
        return data;
      } catch (Err) {
        throw Error(Err);
      }
    },
    async getReturnedEvents({ delivery_manifest_id, return_manifest_id }) {
      try {
        const { data } = await this.Api.get(
          `delivery_manifest/${delivery_manifest_id}/delivery_events/returns?return_manifest_id=${return_manifest_id}`
        );
        return data;
      } catch (Err) {
        throw new Error(Err);
      }
    },
    async prepareModal(multipleDeliveries) {
      this.open = true;
      const { data, type } = multipleDeliveries;
      const dataByType = this.dataByTypes(type);
      this.isMultipleReturn = type == 'multiple_package_return';
      this.title = dataByType.title;
      if (dataByType.getEvents) {
        try {
          this.loading = true;
          this.employee = data.employee;
          this.deliveries = await dataByType.getEvents(data);
        } catch (error) {
          console.log('::Error', error);
        }
        this.loading = false;
      } else {
        console.log('::NO DATA');
      }
    }
  },
  watch: {
    open(value) {
      if (!value) this.onClose();
    }
  }
};
</script>

<style lang="sass" scoped>
#multipleDetailsModal
  #main-container
    #driver-info
      margin-top: 10px
      margin-bottom: 32px
  .subTitle
    font-size: $f-lg
  .gray-bold
    font-size: $f-md
    color: $gray-700
    font-weight: bold
  .unavailable
    color: $gray-600
    font-size: 14px
    font-weight: bold
  .gray-font
    color: $gray-400
  hr
    position: relative
    top: -1rem
    margin: 0
    background-color: $gray-200
  .delivery hr
    margin-left: 8rem
  .attachment-detail
    hr
      top: -.9rem
      margin-left: 13em
    .gray-bold
      font-size: $f-lg
    p
      color: $gray-700
  .attachment-signature
    border: 1px solid $gray-200
    border-radius: $br-md
    min-height: 250px
    ::v-deep
      .Picture
        .pe-container.loaded
          position: relative
        .letters.hidden
          display: none
  .delivery-container
    margin-top: 24px
  .hide
    display: none
  .show
    display: inherit
</style>
<style lang="sass" scoped>
.dark
  .gray-bold,
  .subTitle
    color: $gray-400 !important
</style>

import Vue from 'vue';
import VueRouter from 'vue-router';
import store from '@/store';
import aRoutes from './routes';
import { Secure } from '@/utils/Secure';
const AppName = process.env.VUE_APP_TITLE;
Vue.use(VueRouter);

let routes = [
  {
    path: '/map',
    name: 'map',
    meta: { useBasicLayout: true, isPublic: false, title: 'Map' },
    component: () => import(/* webpackChunkName: "Map" */ '../views/Map/Map.vue')
  },
  {
    path: '/delivery-report/:eventId',
    name: 'deliveryReport',
    meta: { useBasicLayout: true, isPublic: false },
    component: () =>
      import(/* webpackChunkName: "deliveryReport" */ '../views/Dispatch/DeliveryReport.vue')
  },
  {
    path: '/delivery-evidence',
    name: 'deliveryEvidence',
    meta: { useBasicLayout: true, isPublic: false },
    component: () =>
      import(/* webpackChunkName: "deliveryEvidence" */ '@/views/Dispatch/DeliveryEvidence')
  },
  ...aRoutes,
  {
    path: '*',
    meta: { isPublic: true, title: '404' },
    component: () => import(/* webpackChunkName: "Map" */ '../views/Errors/404')
  }
];
const router = new VueRouter({
  mode: 'hash',
  base: process.env.BASE_URL,
  routes
});
router.beforeEach(async (to, from, next) => {
  window.scrollTo(0, 0);
  store.commit('navigation/setLastRoute', from);
  store.dispatch('checkAUTH');
  const { isPublic, title, secure } = to.meta;
  const { hasAuth } = store.getters;
  const isLogin = to.name && to.name.toLocaleLowerCase() === 'login';
  const isAllowed = Secure.permissionValidator(secure?.permission);

  document.title = title ? `${title} - ${AppName}` : AppName;
  if (hasAuth && isLogin) next('/home');
  if (hasAuth && !isAllowed) next(secure?.redirect || '/home');
  else if (isPublic || hasAuth) next();
  else next('/login');
});
export default router;

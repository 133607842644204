<template>
  <div ref="iScroll" class="infinite-scroll">
    <slot v-if="data.length" :data="data" />
    <div
      class="loading"
      :class="oLoading ? 'full-height' : ''"
      v-if="!paused && ((totalLoaded < total_items && totalLoaded >= perPage) || oLoading)"
    >
      <b-loading :is-full-page="false" :active="loading || oLoading" />
    </div>
    <Empty v-else-if="!oLoading && !data.length" />
  </div>
</template>

<script>
import Empty from '@/components/Empty.vue';
import { findIndex, propEq, union } from 'ramda';
export default {
  components: {
    Empty
  },
  mounted() {
    this.prepareParams();
    if (this.autoInit) this.getData();
    this.$refs.iScroll.addEventListener('scroll', this.handleScroll);
    if (this.refreshTopDataRate) setInterval(this.refreshTopData, this.refreshTopDataRate);
  },
  destroyed() {
    if (this.$refs.iScroll) this.$refs.iScroll.removeEventListener('scroll', this.handleScroll);
    this.cancelRequest('Component destroyed');
  },
  data() {
    return {
      cancelToken: null,
      data: this.value || [],
      iCurrentPage: this.currentPage,
      oLoading: this.loading,
      scrollTimeout: 0,
      totalLoaded: 0,
      total_items: 0,
      myParams: ''
    };
  },
  methods: {
    cancelRequest(message = 'Avoid multiple') {
      if (this.cancelToken) this.cancelToken.cancel(message);
      if (message != 'Avoid multiple') this.oLoading = false;
    },
    /** Get newest data at top of dataset */
    async refreshTopData() {
      const totalItemsPrev = this.total_items;
      await this.getTotalItems();
      const totalItems = this.total_items;
      if (totalItemsPrev === totalItems) return;
      this.prepareParams({ page: 1, perPage: totalItems - totalItemsPrev });
      this.getData(true);
    },
    handleScroll(e) {
      if (this.paused) return;
      clearTimeout(this.scrollTimeout);
      const { scrollHeight, offsetHeight, scrollTop } = e.target;
      const scroll = scrollTop + offsetHeight;
      this.scrollTimeout = setTimeout(() => {
        if (scroll > scrollHeight - 200) this.nextPage();
      }, 300);
    },
    async getTotalItems() {
      const { api, internalParams, params } = this;
      this.total_items = 0;
      const myParams = [...params, ...internalParams].join('&');
      const { data } = await this.Api.get(`${api}/total_items?${myParams}`, {
        cancelToken: this.cancelToken.token
      });
      this.total_items = data.total_items;
      return data.total_items;
    },
    async getData(isAutoUpdate = false) {
      const { myParams, api } = this;
      if (!api) return (this.oLoading = false);
      this.cancelRequest();
      if (!isAutoUpdate) this.oLoading = true;
      let isAborted = false;
      try {
        this.cancelToken = this.Api.cancelToken;
        if (!this.total_items) this.getTotalItems();
        const { data } = await this.Api.get(`${api}${myParams}`, {
          cancelToken: this.cancelToken.token
        });
        this.totalLoaded = this.totalLoaded + data.length;
        let resultData = data;
        if (this.dataPreProcesor) resultData = this.dataPreProcesor(resultData);
        if (isAutoUpdate) {
          const data = union(resultData, this.data);
          this.data = data;
        } else {
          this.data = [...this.data, ...resultData];
        }
        this.$emit('update', { page: this.iCurrentPage, items: this.data });
      } catch (error) {
        isAborted = error.aborted;
      }
      if (!isAborted) this.oLoading = false;
    },
    onRemoveById(id, key = 'id') {
      let newData = this.data.filter((e) => e[key] !== id);
      let difference = this.data.filter((e) => e[key] === id);
      const findIndexByObjectKey = findIndex(propEq(key, id));
      const index = findIndexByObjectKey(this.data);
      this.totalLoaded = this.totalLoaded - difference.length;
      this.total_items = this.total_items - difference.length;
      this.data = newData;
      return this.data[index];
    },
    nextPage() {
      if (!this.oLoading && this.totalLoaded < this.total_items) {
        this.iCurrentPage += 1;
        this.prepareParams();
        this.getData();
      }
    },
    prepareParams(params) {
      const page = params?.page || this.iCurrentPage;
      const per_page = params?.perPage || this.perPage;
      const myParams = this.params.concat([
        `page=${page}`,
        `per_page=${per_page}`,
        ...this.internalParams
      ]);
      this.myParams = `?${myParams.join('&')}`;
    },
    reset() {
      this.iCurrentPage = 1;
      this.totalLoaded = 0;
      this.total_items = 0;
      this.data = [];
      this.prepareParams();
      this.$emit('update', { page: 1, items: [] });
      this.$emit('input', []);
    }
  },
  watch: {
    oLoading(value) {
      this.$emit('update:loading', value);
    },
    data(value) {
      this.$emit('input', value);
    },
    value(value) {
      this.data = value;
    },
    params(value) {
      console.log('::::', value);
      this.myParams = value;
      this.reset();
      this.getData();
    },
    totalLoaded(value) {
      this.$emit('update:totalLoaded', value);
    },
    total_items(value) {
      this.$emit('update:totalItems', value);
    }
  },
  props: {
    api: { type: String, default: null },
    autoInit: { type: Boolean, default: () => true },
    currentPage: { type: Number, default: 1 },
    dataPreProcesor: { type: Function, default: null },
    injectThisProps: { type: Object, default: () => {} },
    loading: { type: Boolean, default: () => true },
    params: { type: Array, default: () => [] },
    internalParams: { type: Array, default: () => [] },
    paused: { type: Boolean, default: false },
    perPage: { type: Number, default: 10 },
    refreshTopDataRate: { type: Number, default: 0 },
    value: { type: Array, default: () => [] }
  }
};
</script>

<style lang="sass" scoped>
.loading
  position: relative
  min-height: 90px
  ::v-deep
    .loading-overlay
      z-index: 0
.full-height
  height: 100%
.loading-sm
  .loading
    height: fit-content
.infinite-scroll
  height: 100%
  overflow: auto
.results
  color: $gray-500
  margin-bottom: 10px
</style>

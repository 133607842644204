<template>
  <Tooltip v-if="isTag" :active="hiddenText" :position="tooltipPosition" class="Tooltip">
    <b-tag class="package-tag" rounded>
      <div class="flex f-ai">
        <b-icon class="package-icon m-0" icon="package-variant" size="is-small" />
        <span v-if="!hiddenText" class="package-text">{{ $t('global.collect') }}</span>
      </div>
    </b-tag>
  </Tooltip>
  <div v-else>
    <b-icon class="package-icon" icon="package-variant" size="is-small" />
    <span v-if="!hiddenText" class="package-text">{{ $t('global.collect') }}</span>
  </div>
</template>

<script>
import Tooltip from '@/components/Tooltip';
export default {
  components: {
    Tooltip
  },
  props: {
    hiddenText: { type: Boolean, default: false },
    isTag: { type: Boolean, default: false },
    tooltipPosition: { type: String, default: 'top' }
  }
};
</script>

<style lang="sass" scoped>
.Tooltip
  display: inline-flex
  ::v-deep
    & + *
      margin-left: 3px
.package-text
  font-size: 14px
  color: $blue-700
  margin-left: 8px
.package-tag
  padding: 14px 10px
  background: #FDEDE4
  border: 1px solid $gray-100
  margin-bottom: 0.2rem
  & .package-text
    color: $orange-800 !important
.package-icon
  color: $orange-650
  font-size: 1rem
</style>
<style lang="sass">
.dark
  .package-icon
    color: $orange-600
  .package-text
    color: white
  .package-tag
    color: white
    background: $orange-700
    border: none
    .package-icon, .package-text
      color: white !important
</style>

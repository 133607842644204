<template>
  <b-dropdown id="preferences" aria-role="list" position="is-bottom-left" :close-on-click="false">
    <template #trigger>
      <b-button class="settings" icon-right="menu-down" :loading="loading.userType">
        <b>{{ user.full_name }}</b>
        <p>{{ user.user_type_name }}</p>
      </b-button>
    </template>

    <div id="main-title">{{ $t('global.preferences') }}</div>
    <b-dropdown-item aria-role="listitem" class="items-title" custom>
      {{ $t('settings.language') }}
    </b-dropdown-item>
    <b-dropdown-item aria-role="listitem" custom>
      <Autocomplete
        :data="languages"
        :value="language"
        model="id"
        field="label"
        @select="onSelectLang"
      />
    </b-dropdown-item>

    <b-dropdown-item aria-role="listitem" class="items-title" custom>
      {{ $t('settings.screenAccessibility') }}
    </b-dropdown-item>
    <b-dropdown-item aria-role="listitem" custom>
      <b-field>
        <b-radio-button
          v-model="isDarkMode"
          size="is-small"
          :native-value="0"
          type="is-primary"
          expanded
        >
          <b-icon icon="white-balance-sunny" class="mr-2" />
          <span>{{ $t('settings.lightMode') }}</span>
        </b-radio-button>
        <b-radio-button
          v-model="isDarkMode"
          size="is-small"
          :native-value="1"
          type="is-primary "
          expanded
        >
          <b-icon icon="weather-night" class="mr-2" /> <span>{{ $t('settings.darkMode') }}</span>
        </b-radio-button>
      </b-field>
    </b-dropdown-item>

    <!-- <b-dropdown-item aria-role="listitem" class="items-title" custom>
      {{ $t('settings.defaultState') }}
    </b-dropdown-item>
    <b-dropdown-item aria-role="listitem">
      <Autocomplete
        :api="{
          url: 'catalogs/states',
          full: true
        }"
        v-model="defaultState"
        :validationMessage="$t('address.state')"
        field="code"
        model="code"
        storeResults
      />
    </b-dropdown-item> -->

    <b-dropdown-item aria-role="listitem" class="items-title" custom>
      {{ $t('settings.account') }}
    </b-dropdown-item>
    <b-dropdown-item aria-role="listitem">
      <Button
        @click="changePassword"
        :label="$t('password.change')"
        :loading="loading.changePassword"
        type="is-primary"
        icon-left="lock"
        outlined
        expanded
      />
    </b-dropdown-item>
    <b-dropdown-item aria-role="listitem">
      <Button
        @click="logout"
        :label="$t('global.logout')"
        :loading="loading.logout"
        type="is-danger"
        icon-left="logout-variant"
        outlined
        expanded
      />
    </b-dropdown-item>
  </b-dropdown>
</template>

<script>
import { toast } from '@/utils/dialog';
import { Autocomplete, Button } from '@/components';

export default {
  components: {
    Autocomplete,
    Button
  },
  mounted() {
    this.getUserTypes();
    setTimeout(() => {
      this.isFast = false;
    }, 2000);
  },
  data() {
    return {
      isFast: true,
      isDarkMode: this.$store.getters.DARK,
      language: this.$i18n.locale,
      defaultState: this.$store.getters.STATE,
      loading: { changePassword: false, userType: false, logout: false },
      user: {
        user_type_id: this.$store.getters.AUTH.user_type_id,
        full_name: this.$store.getters.AUTH.full_name
      }
    };
  },
  computed: {
    classes() {
      const classes = [];
      if (this.isDarkMode) classes.push('dark');
      if (this.isFast) classes.push('dark-fast');
      return classes.join(' ');
    },
    languages() {
      // NO TRANSLATION REQUIRED
      return [
        { id: 'en', label: 'English' },
        { id: 'es', label: 'Español' },
        { id: 'ar', label: 'عربى' }
      ];
    }
  },
  watch: {
    isDarkMode(value) {
      this.$store.dispatch('DARK', value);
      this.$emit('update:isDark', value);
    },
    language(value) {
      this.$i18n.locale = value;
      this.$store.dispatch('LANG', value);
    },
    defaultState(value) {
      this.$store.dispatch('STATE', value);
      this.$emit('STATE', value);
    }
  },
  methods: {
    async changePassword() {
      this.loading.changePassword = true;
      try {
        const data = {
          email: this.$store.getters.AUTH.email
        };
        const Authorization = new Date().toDateString();
        await this.Api.post(`/users/reset_password`, data, { Authorization });
        toast('success', this.$t('settings.emailSent'), 5000);
        this.$store.dispatch('deAUTH', { redirect: true });
      } catch (error) {
        console.error(error);
      }
      this.loading.changePassword = false;
    },
    async getUserTypes() {
      this.loading.userType = true;
      try {
        const { data } = await this.Api.get(`/user_types/${this.user.user_type_id}`);
        this.user.user_type_name = data.name;
      } catch (error) {
        console.error(error);
      }
      this.loading.userType = false;
    },
    async logout() {
      this.loading.logout = true;
      await this.$store.dispatch('deAUTH', { redirect: true });
      this.loading.logout = false;
    },
    onSelectLang(lang) {
      setTimeout(() => {
        this.language = lang;
      }, 100);
    }
  }
};
</script>

<style lang="sass">
.dark
  #preferences
    #main-title
      color: $main-background!important
      background: $gray-800!important
    .dropdown-item.items-title
      color: $main-background!important
      background: $dark-mode-card-items-background !important
    .dropdown-item
      .autocomplete
        .dropdown-item
          color: $gray-200 !important
</style>

<style lang="sass" scoped>
#preferences
  #main-title
    font-size: $f-xl
    color: $gray-700
    font-weight: bold
    padding: 8px 16px
    border-radius: 4px 4px 0 0
    background-color: $gray-50
  ::v-deep
    .settings
      font-size: 14px
      padding: 5px
      border: none
      margin-right: 15px
      margin-left: 10px
      &.button:focus:not(:active), .button.is-focused:not(:active)
        border: none
        box-shadow: none
      p
        color: $gray-600
        text-align: left
    .autocomplete
      margin: 0
      .dropdown-menu
        left: auto
        width: auto
        margin: auto
    .dropdown-menu
      width: 295px
      margin-top: 20px
      .dropdown-content
        transition: $transition-dark-mode
        box-shadow: $box-shadow
        padding: 0 0 10px 0
        .dropdown-item
          transition: $transition-dark-mode
          color: $gray-700
          padding-right: 16px
          &.items-title
            font-size: $f-md
            text-transform: initial
            font-weight: bold
            padding-left: 15px
            width: auto
          &:last-child
            border-radius: 0 0 4px 4px
          .b-radio.button
            height: 40px
            font-size: 14px
            color: $primary
            border-color: $primary
            &.is-selected
              color: white
              background-color: $primary
        .dropdown-item.items-title
          cursor: default
</style>

<template>
  <div class="flex phone-creator">
    <div v-if="loading.get" style="width: 100%" class="mb-2">
      <b-skeleton height="20" width="100%" />
      <b-skeleton height="40" width="100%" />
    </div>
    <template v-else>
      <PhonePicker
        ref="phonePicker"
        class="phone-picker"
        :label="label"
        v-bind="{ ...$props, ...$attrs }"
        v-model="oPhone.phone_number"
        @keypress.enter.native="onSavePhone"
        :focus="focus"
      />
      <!-- :focus="true" -->
      <Link
        v-if="refPhone !== oPhone.phone_number || oPhone.is_default"
        :label="$t('button.save')"
        icon="content-save"
        :loading="loading.save"
        :disabled="refPhone === oPhone.phone_number"
        @click="onSavePhone"
      />
      <Link
        v-if="refPhone === oPhone.phone_number && !oPhone.is_default"
        :label="$t('button.setDefault.ttp')"
        icon="star"
        @click="$emit('setDefault', oPhone)"
        :disabled="validateDelete"
        position="top"
      />
      <Link :label="$t('button.delete')" icon="delete" @click="$emit('remove', oPhone)" />
    </template>
  </div>
</template>

<script>
import { PhonePicker, Link } from '@/components';
import { toast } from '@/utils/dialog';
export default {
  components: {
    PhonePicker,
    Link
  },
  computed: {
    validateDelete() {
      if (this.oPhone.id === null) return true;
      return this.oPhone.phone_number === '';
    },
    label() {
      if (this.oPhone.number_type === 'mobile') {
        if (this.oPhone.is_default) return this.$t('contact.defaultMobile');
        return this.$t('contact.mobile');
      } else {
        if (this.oPhone.is_default) return this.$t('contact.defaultLandline');
        return this.$t('contact.landline');
      }
    }
  },
  data() {
    return {
      oPhone: this.phone,
      isDefault: false,
      refPhone: this.phone.phone_number,
      loading: { save: false, get: false }
    };
  },
  methods: {
    async onSavePhone() {
      this.loading.save = true;
      const method = this.oPhone.id ? 'put' : 'post';
      const url = `/participants/${this.participantId}/phone_numbers${
        method === 'put' ? `/${this.oPhone.id}` : ''
      }`;

      let phone = {
        contact_name: this.oPhone.contact_name || this.contactName,
        phone_number: this.oPhone.phone_number,
        number_type: this.oPhone.number_type
      };

      if (method === 'post') phone = { ...phone };

      try {
        const { data } = await this.Api[method](url, phone);
        this.oPhone = data;
        this.$emit('update:isPendingToSave', false);
        toast('success', this.$t('contact.phoneSaved'), 5000);
        this.refPhone = this.oPhone.phone_number;
      } catch (error) {
        console.error(error);
      }
      this.loading.save = false;
    }
  },
  watch: {
    phone(phone) {
      this.oPhone = phone;
    },
    oPhone: {
      immediate: true,
      deep: true,
      handler(value) {
        this.$emit('update:isPendingToSave', this.refPhone !== value.phone_number);
        this.$emit('input', value);
      }
    }
  },
  props: {
    focus: { type: Boolean, default: false },
    phone: { type: Object, default: () => {} },
    participantId: { type: Number, default: null },
    contactName: { type: String, default: '' }
  }
};
</script>

<style lang="sass" scoped>
.phone-creator
  .phone-picker
    width: 100%
  .dropdown, a, .tooltip-link, span
    margin-bottom: 1.30rem
    display: flex
    align-self: flex-end
    margin-left: 0.2em
  .dropdown
    margin-bottom: 0.95rem
</style>

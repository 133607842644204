<template>
  <div class="flex">
    <span class="dt-label mr-1" v-if="label">{{ label }}:</span>
    <span>
      {{ formatedDate }}
    </span>
  </div>
</template>

<script>
export default {
  computed: {
    formatedDate() {
      const formats = { time: 'hh:mm A', date: 'Y-MM-DD' };
      const format = formats[this.format] || this.format;
      return this.$moment.utc(this.date).add(this.offset, 'hours').format(format);
    }
  },
  watch: {},
  props: {
    date: { type: String, required: true },
    format: { type: String, default: 'Y-MM-DD HH:mm:ss' },
    label: { type: String, default: '' },
    offset: { type: Number, default: 0 }
  }
};
</script>

<style lang="sass" scoped></style>

import VueCookies from 'vue-cookies';
const COOKIE_ID = process.env.VUE_APP_COOKIE_ID || null;
const DOMAIN = process.env.VUE_APP_COOKIE_DOMAIN || null;
const EXTERNAL = process.env.VUE_APP_EXTERNAL_COOKIE_NAME;
const OBJECT_KEY = '__oBj:';

const _parseCookie = (value) => {
  if (!value) return value;

  const type = typeof value;
  switch (type) {
    case 'string':
      return value.includes(OBJECT_KEY) ? JSON.parse(value.slice(OBJECT_KEY.length)) : value;
    case 'object':
      return OBJECT_KEY + JSON.stringify(value);
    default:
      return value;
  }
};
export const setCookie = (name, value, expiration) => {
  value = _parseCookie(value);
  VueCookies.set(`${COOKIE_ID}_${name}`, value, expiration, null, DOMAIN, true);
};

export const getCookie = (name) => {
  const externalCookie = EXTERNAL && _parseCookie(VueCookies.get(`${EXTERNAL}_${name}`));
  if (externalCookie) return externalCookie;
  else return _parseCookie(VueCookies.get(`${COOKIE_ID}_${name}`));
};

export const removeCookie = (name) => {
  VueCookies.remove(`${COOKIE_ID}_${name}`, null, DOMAIN);
  EXTERNAL && VueCookies.remove(`${EXTERNAL}_${name}`, null, DOMAIN);
};

<template>
  <Modal
    :header="$t('button.pickPosition')"
    :isOpen.sync="isModalActive"
    size="is-ex-large"
    @cancel="() => {}"
    buttonLabel="Pick"
    @save="onPickPosition"
    :saveDisabled="isLoading"
    id="AddresssLocationModal"
  >
    <div id="preview">
      <MessageBadge
        :message="geolocationError || messageBadgeBody"
        :type="isLocalizationAllowed ? 'is-info' : 'is-danger'"
      />
      <b-loading v-if="isLoading && !geolocationError" active :is-full-page="false" />
      <div v-if="isLocalizationAllowed">{{ mapAddressData.formatted_address }}</div>
    </div>
    <Mappr id="map" ref="map" :zoom="17" :useDarkMode="!!$store.getters.DARK">
      <M-marker draggable :position="marker.position" @dragend="onDragEnd" autopan>
        <IconMarker icon="home-account" color="#4BA8E2" :size="40" />
      </M-marker>
    </Mappr>
  </Modal>
</template>

<script>
import { Mappr, MMarker } from '@/components/Map_Mappr';
import { Modal, MessageBadge, IconMarker } from '@/components';

export default {
  components: {
    IconMarker,
    Modal,
    MessageBadge,
    Mappr,
    MMarker
  },
  mounted() {
    navigator.geolocation.getCurrentPosition(
      () => (this.isLocalizationAllowed = true),
      (err) => (this.geolocationError = this.locationErrorTypes(err)[err.code])
    );
  },
  data() {
    return {
      isModalActive: false,
      isLoading: false,
      isLocalizationAllowed: false,
      messageBadgeBody: this.$t('map.moveToPick'),
      geolocationError: null,
      marker: {
        position: this.$store.getters['map/currentPosition']
      },
      mapAddressData: {
        latitude: null,
        longitude: null,
        formatted_address: ''
      }
    };
  },
  computed: {
    searchQuery() {
      const { address, city, state, zipcode } = this.value;
      return `${address}, ${city}, ${state},${zipcode}`;
    },
    Mappr() {
      return this.$refs.map;
    }
  },
  methods: {
    async init() {
      this.isLoading = true;
      const result = await this.handleMarkerInitialPosition();
      const { position, address } = result;
      const formatted_address = address || this.value?.formatted_address || '';
      this.mapAddressData = {
        ...this.value,
        latitude: position.lat,
        longitude: position.lng,
        formatted_address
      };
      this.marker.position = position;
      this.isLoading = false;
    },
    async handleMarkerInitialPosition() {
      const { latitude, longitude } = this.value;
      if (latitude && longitude && !this.renewPosition) {
        return { address: null, position: { lat: latitude, lng: longitude } };
      } else {
        const currentPosition = await this.$store.dispatch('map/handleCurrentPosition');
        const result = await this.Mappr.searchApi({ text: this.searchQuery, ...currentPosition });
        const { address, position } = result[0];
        return { address, position };
      }
    },
    async onDragEnd(lngLat) {
      this.isLoading = true;
      const result = await this.Mappr.reverseGeocode(lngLat);
      this.isLoading = false;
      this.mapAddressData = {
        ...result,
        latitude: lngLat.lat,
        longitude: lngLat.lng
      };
    },
    onPickPosition() {
      this.$emit('pickPosition', this.mapAddressData);
      this.isModalActive = false;
    },
    locationErrorTypes(error) {
      return {
        [error.PERMISSION_DENIED]: this.$t('location.permissionDenied'),
        [error.POSITION_UNAVAILABLE]: this.$t('location.positionUnavailable'),
        [error.TIMEOUT]: this.$t('location.timeout'),
        [error.UNKNOWN_ERROR]: this.$t('location.unknow')
      };
    }
  },
  watch: {
    isOpen(value) {
      this.isModalActive = value;
      if (value) {
        this.$nextTick(() => {
          this.init();
        });
      }
    },
    isModalActive(value) {
      this.$emit('update:isOpen', value);
    }
  },
  props: {
    value: { type: Object, default: () => {} },
    isOpen: { type: Boolean, default: false },
    renewPosition: { type: Boolean, default: false }
  }
};
</script>

<style lang="sass" scoped>
#AddresssLocationModal
  ::v-deep
    .modal-footer button
      &:last-child
        margin-right: 0px
#map
  height: 60vh
#preview
  position: absolute
  z-index: 99
  background: $main-background
  border-radius: 0 0 $br-md 0
  box-shadow: 1px 1px 3px  $gray-200
  max-width: 400px
  width: 50%
  padding: 0.75rem
</style>
<style lang="sass">
.dark
  #AddresssLocationModal
    #preview
      background: $dark-700
      box-shadow: 1px 1px 3px  $dark-400
</style>

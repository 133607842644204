<template>
  <Card id="set-password-component" :title="$t('password.enterNew')">
    <div class="set-password-container">
      <div class="flex form-container">
        <form ref="form" @submit.prevent="onSubmit">
          <Field
            v-if="userId"
            v-model="password.old"
            :label="$t('password.old')"
            :placeholder="$t('password.old')"
            :upper="false"
            password-reveal
            required
            type="password"
          />
          <PasswordCreator
            :label="$t('password.newLabel')"
            :placeholder="$t('password.enterNewPlaceholder')"
            v-model="password.new"
          />
          <Field
            v-model="password.confirm"
            :custom-class="passwordsDontMatch ? 'is-danger' : ''"
            :label="$t('password.confirmLabel')"
            :placeholder="$t('password.confirmLabel')"
            :upper="false"
            password-reveal
            required
            type="password"
            validationMessage=" "
          />
          <div
            :class="passwordsDontMatch ? '' : 'noMatch'"
            class="help is-danger is-uppercase validationMsg"
          >
            {{ $t('errors.passwordMatch') }}
          </div>
          <div class="flex f-jce">
            <b-button
              class="submit-btn"
              type="is-primary"
              :label="$t('password.change')"
              native-type="submit"
              :disabled="isSomethingEmpty || passwordsDontMatch"
              :loading="loading"
            />
          </div>
        </form>
        <div class="image-container">
          <b-image :src="require('@/assets/img/password.png')" />
        </div>
      </div>
    </div>
  </Card>
</template>

<script>
import { toast } from '@/utils/dialog';
import Card from '@/components/Card.vue';
import Field from '@/components/Field.vue';
import PasswordCreator from '@/components/PasswordCreator.vue';
import mobileDetector from 'mobile-device-detect';
import { delayer } from '../utils/Helpers';
import propOr from 'ramda/src/propOr';
export default {
  components: {
    Card,
    Field,
    PasswordCreator
  },
  data() {
    return {
      password: { old: '', new: '', confirm: '' },
      loading: false
    };
  },
  computed: {
    Authorization() {
      return (
        this.$route.query.auth || this.$store.getters.Authorization || new Date().toDateString()
      );
    },
    isMobile() {
      return propOr(false, 'isMobile', this.$route.query) || mobileDetector.isMobile;
    },
    userId() {
      return this.$route.query.id;
    },
    isSomethingEmpty() {
      return !this.password.new || !this.password.confirm;
    },
    passwordsDontMatch() {
      return (
        this.password.new !== this.password.confirm &&
        this.password.new !== '' &&
        this.password.confirm !== ''
      );
    },
    token() {
      return this.$route.query.token;
    }
  },
  methods: {
    async onSubmit() {
      if (this.password.new === this.password.confirm) {
        this.makeRequest();
      }
    },
    async handleSuccess() {
      toast('success', this.$t('password.changed'), 3000);
      if (this.$store.getters.hasAuth)
        this.$store.dispatch('deAUTH', { redirect: true, allowSignOut: false });
      await delayer(3000);
      if (this.isMobile) this.$router.replace('success-mobile-password');
      else this.$router.replace('login');
      this.password = {};
    },
    async makeRequest() {
      this.loading = true;
      try {
        const { Authorization, userId, token, password } = this;
        let Api = 'set_password_from_token';
        let method = 'post';
        let payload = { new_password: password.new };

        if (userId) {
          Api = `reset_password/${userId}`;
          method = 'put';
          payload.old_password = password.old;
        } else if (token) {
          payload.token = token;
        }
        await this.Api[method](`/users/${Api}`, payload, { Authorization });
        this.handleSuccess();
      } catch (error) {
        console.error(error);
      }
      this.loading = false;
    }
  }
};
</script>

<style lang="sass" scoped>
#set-password-component
  max-width: 750px
  margin: 0 auto
  margin-top: 40px
  .set-password-container
    padding: 20px 0
    border-radius: 10px
    max-width: 750px
    form,
    .image-container
      width: 50%
    .title
      font-size: 20px
      margin-bottom: 15px
      font-weight: bold
    .bottomLabel
      font-size: 12px
      margin-bottom: 10px
    .validationMsg
      transition: all .3s ease
      font-size: 10px
      margin: -7px 0 5px 5px
    .noMatch
      opacity: 0
    .field
      margin-bottom: 10px
    @media only screen and (max-width: $bp-sm)
      width: 100%
      padding: 20px
      margin-top: 20px
      border-radius: 0
      form
        width: 100%
      .form-container
        flex-direction: column-reverse
        .image-container
          max-width: 350px
          min-width: 200px
          margin: auto
          margin-bottom: 10px
</style>

<template>
  <div class="card flex f-column" :class="classes" @click="onClick">
    <b-loading :active="loading" :is-full-page="false" />
    <div class="card-header" v-if="title || $slots.header">
      <p v-if="title">{{ title }}</p>
      <slot name="header" />
    </div>
    <div class="card-content" :class="flexContent ? 'flex f-wrap f-inherit' : ''">
      <slot />
    </div>
    <div v-if="$slots.footer" class="card-footer">
      <slot name="footer" />
    </div>
  </div>
</template>

<script>
export default {
  computed: {
    classes() {
      const classes = [];
      if (this.$listeners.click) classes.push('hasClick');
      if (this.condensed) classes.push('condensed');
      if (this.compact) classes.push('compact');
      if (this.hasMinHeight) classes.push('hasMin');
      return classes.join(' ');
    }
  },
  methods: {
    onClick() {
      this.$emit('click');
    }
  },
  props: {
    loading: { type: Boolean, default: false },
    title: { type: String, default: '' },
    condensed: { type: Boolean, default: false },
    compact: { type: Boolean, default: false },
    flexContent: { type: Boolean, default: false },
    hasMinHeight: { type: Boolean, default: true }
  }
};
</script>

<style lang="sass" scoped>

.card
  transition: $transition-dark-mode
  height: fit-content
  margin: 10px
  border-radius: $br-md
  padding: 0 15px
  box-shadow: none
  &.hasMin
    min-height: 100px
  &.compact
    margin: 5px 10px
    .card-content
      padding: 10px 0
  &.condensed
    margin: auto 0
    padding: 0 10px
    .card-header,.card-content,.card-footer
      padding: 15px 0
  &.hasClick
    cursor: pointer
    user-select: none
    transition: box-shadow .3s ease-in-out
    &:hover
      box-shadow: 0 0 10px 1px darken($main-background,5)
  &-header
    box-shadow: none
    flex-flow: column
    padding: 0
    border-bottom: 1px solid #E8E8E8
    justify-content: center
    p
      font-size: $f-xxl
      line-height: $f-xxl
      font-weight: bold
  &-header,
  &-content,
  &-footer
    padding: 15px 0
  &-content
    height: calc(100% - 115px)
  &-footer
    position: relative
    z-index: 1
    height: 60px
</style>

<template>
  <b-skeleton v-if="skeletonLoading" />
  <b-dropdown
    v-else-if="hasAutomaticSchedulingPermission || hasRecalculateTracePermission"
    :disabled="disabled || (iManifest ? !!iManifest.is_route_updated : true)"
    :expanded="expanded"
    aria-role="list"
    position="is-bottom-left"
  >
    <template #trigger="{ active }" :expanded="expanded">
      <b-button
        :loading="loading"
        :class="expanded ? '' : 'ml-1'"
        :icon-right="compact ? '' : active ? 'menu-up' : 'menu-down'"
        :label="compact ? '' : $t('manifest.recRoute')"
        :outlined="outlined"
        expanded
        icon-left="source-branch-sync"
        type="is-primary"
      />
    </template>
    <b-dropdown-item
      v-if="hasRecalculateTracePermission"
      aria-role="listitem "
      @click="recalculateTrace"
      :disabled="loading"
    >
      {{ $t('manifest.reRouteRxDeliveries') }}
    </b-dropdown-item>
    <b-dropdown-item
      v-if="hasAutomaticSchedulingPermission"
      aria-role="listitem"
      @click="() => recalculateTrace(true)"
      :disabled="loading"
    >
      {{ $t('manifest.reRouteFligthmap') }}
    </b-dropdown-item>
  </b-dropdown>
</template>

<script>
import { Permissions } from '@/utils/Secure';

export default {
  created() {
    this.$store.subscribe(({ type }, { schedule: { currentManifest } }) => {
      if (type == 'schedule/currentManifest') {
        this.iManifest = currentManifest;
      }
    });
  },
  data() {
    return {
      loading: false,
      iManifest: this.manifest
    };
  },
  computed: {
    hasAutomaticSchedulingPermission() {
      return this.Secure.permissionValidator(Permissions.Scheduling.automaticSchedulingUpdate);
    },
    hasRecalculateTracePermission() {
      return this.Secure.permissionValidator(Permissions.Scheduling.recalculateTrace);
    }
  },
  methods: {
    emitDispatchManifest() {
      this.$store.commit('dispatch/manifest', { ...this.manifest, is_route_updated: true });
    },
    async recalculateTrace(isFlightMaps) {
      this.loading = true;
      try {
        const recalculate_trace = `/delivery_manifest/${this.iManifest.id}/${
          isFlightMaps ? 'automatic_scheduling' : 'recalculate_trace'
        }`;
        await this.Api[isFlightMaps ? 'put' : 'post'](recalculate_trace);
        this.$toast('success', this.$t('messages.updated'), 3000);
        this.$emit('update', true);
        if (this.manifest) this.emitDispatchManifest();
      } catch (error) {
        console.error(error);
      }
      this.loading = false;
    }
  },
  watch: {
    manifest(val) {
      this.iManifest = val;
    }
  },
  props: {
    compact: { type: Boolean, default: false },
    disabled: { type: Boolean, default: false },
    expanded: { type: Boolean, default: false },
    manifest: { type: Object, default: null },
    outlined: { type: Boolean, default: false },
    skeletonLoading: { type: Boolean, default: false }
  }
};
</script>

<style lang="sass" scoped>
.b-skeleton
  border: none
  margin-top: 0px
  padding: 0
  width: auto
  ::v-deep
    .b-skeleton-item
      min-height: 40px
</style>

<template>
  <CustomIcon :mdiIcon="icon" :height="height || size" :width="width || size" autoSize>
    <template #svg>
      <MapMarker
        :color="color"
        :secondaryColor="secondaryColor"
        :height="height || size"
        :width="width || size"
      />
    </template>
  </CustomIcon>
</template>

<script>
import { CustomIcon } from '@/components';
import MapMarker from '@/components/Icons/MapMarker';
export default {
  components: {
    CustomIcon,
    MapMarker
  },
  props: {
    color: { type: String, default: '#F89D65' },
    secondaryColor: { type: String, default: null },
    height: { type: Number },
    icon: { type: String, default: 'car' },
    size: { type: Number, default: 35 },
    width: { type: Number }
  }
};
</script>

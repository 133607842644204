<template>
  <div class="stepCardHeader flex f-jsb">
    <Link v-if="isParent" icon="chevron-left" @click="onGoBack">
      {{ $i18n.t('global.back') }}
    </Link>
    <slot />
    <div class="steps-buttons flex">
      <div
        v-for="(step, i) in steps"
        :key="i++"
        class="step"
        :class="i == current ? 'current' : ''"
        @click="onChangeStep(i, isDisabled(i), step)"
      >
        <Link :disabled="isDisabled(i)" :permission="step.permission">
          {{ $i18n.t(step.name) }}
        </Link>
      </div>
    </div>
  </div>
</template>

<script>
import Link from '@/components/Link';
import { Secure } from '@/utils/Secure';

export default {
  components: {
    Link
  },
  data() {
    return {
      lastRoute: this.$store.getters['navigation/lastRoute'],
      current: this.value
    };
  },
  methods: {
    isDisabled(i) {
      return !this.onlyFirstAllowed || i < 2 ? false : true;
    },
    onGoBack() {
      const { returnRoute, lastRoute } = this;
      if (returnRoute) {
        if (lastRoute?.path === '/') return this.$router.replace(this.returnRoute);
      }
      this.$router.go(-1);
      // TODO validate history to stay
    },
    onChangeStep(stepIndex, isDisabled, step) {
      const isAllowed = Secure.permissionValidator(step?.permission);
      if (isDisabled || !isAllowed) return;
      this.current = stepIndex;
      this.$emit('input', stepIndex);
    }
  },
  watch: {
    value(value) {
      this.step = Number(value);
    }
  },
  props: {
    steps: { type: Array, default: () => [] },
    onlyFirstAllowed: { type: Boolean, default: true },
    value: { type: [String, Number], default: () => 1 },
    isParent: { type: Boolean, default: true },
    returnRoute: { type: String, default: '' }
  }
};
</script>

<style lang="sass" scoped>
.stepCardHeader
  width: 100%
  min-height: 50px
.steps-buttons
  align-items: flex-end
  .step
    border-bottom: 1px solid $main-separators
    padding: 0 15px
    .link
      font-weight: bold
      margin-bottom: 10px
      color: $step-button
    &.current
      pointer-events: none
      border-color: $step-button-active
      .link
        color: $step-button-active
    .button
      border: none
</style>

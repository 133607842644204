<template>
  <b-modal
    v-bind="{ ...$props, ...$attrs }"
    v-model="isModalActive"
    :width="getWidth"
    class="modal-component"
    :can-cancel="!loading.save"
  >
    <div class="card">
      <div v-if="loading.get" class="flex loading f-column">
        <slot v-if="$slots.skeleton" name="skeleton" />
        <div v-else>
          <b-skeleton height="15" width="50%" />
          <b-skeleton position="is-centered" height="30" width="100%" />
          <b-skeleton height="15" width="50%" />
          <b-skeleton position="is-centered" height="30" width="100%" />
        </div>
      </div>
      <div v-else class="h-100">
        <div class="flex f-ai modal-title">
          <p>{{ header }}</p>
          <a v-if="!loading.save" @click="isModalActive = false"
            ><b-icon icon="close" size="is-small"
          /></a>
        </div>
        <div class="modal-content-container">
          <div class="slot" :class="allowOverflow && 'allowOverflow'" ref="content">
            <slot />
          </div>
          <div
            class="flex modal-footer"
            style="padding-top: 5px"
            :class="$listeners.skip ? 'f-jsb' : 'f-jce'"
          >
            <Button
              v-if="$listeners.skip"
              :label="$t('global.skip')"
              type="is-primary"
              :disabled="loading.save || disabledSkip"
              :loading="loading.save"
              @click="$listeners.skip"
              outlined
            />
            <div class="flex">
              <Button
                outlined
                type="is-primary"
                v-if="$listeners.cancel"
                :label="buttonLabelClose || $t('global.cancel')"
                :disabled="isDisabled || loading.save"
                @click.prevent="onCancel"
                :style="isFullButtonWidth ? 'width: 100%' : ''"
              />
              <slot name="buttons" />
              <Button
                v-if="$listeners.save"
                :label="buttonLabel || $t('button.save')"
                type="is-primary"
                :loading="loading.save"
                @click.prevent="$listeners.save"
                :style="isFullButtonWidth ? 'width: 100%' : ''"
                :disabled="isDisabled || saveDisabled"
              />
              <Button
                v-if="$listeners.close || (!$listeners.cancel && !$listeners.save)"
                :label="$t('global.close')"
                type="is-primary"
                :disabled="isDisabled || loading.save"
                @click.prevent="isModalActive = false"
                :outlined="isCloseOutlined"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  </b-modal>
</template>

<script>
import { Button } from '@/components';

export default {
  components: {
    Button
  },
  data() {
    return {
      isModalActive: this.isOpen
    };
  },

  computed: {
    getWidth() {
      let width = this.size;
      switch (this.size) {
        case 'is-auto':
          width = 'auto';
          break;
        case 'is-small':
          width = 400;
          break;
        case 'is-medium':
          width = 650;
          break;
        case 'is-large':
          width = 800;
          break;
        case 'is-ex-large':
          width = '90%';
          break;
      }
      return width;
    }
  },
  methods: {
    scrollTop() {
      this.$refs.content.scrollTop = 0;
    },
    onCancel() {
      this.isModalActive = false;
      this.$emit('cancel');
    }
  },
  watch: {
    isOpen(value) {
      this.isModalActive = value;
    },
    isModalActive(value) {
      this.$emit('update:isOpen', value);
    }
  },
  props: {
    allowOverflow: { type: Boolean, default: false },
    isDisabled: { type: Boolean, default: false },
    isCloseOutlined: { type: Boolean, default: false },
    isOpen: { type: Boolean, default: false },
    saveDisabled: { type: Boolean, default: false },
    header: String,
    buttonLabel: String,
    buttonLabelClose: String,
    data: { type: Array, default: () => [] },
    disabledSkip: { type: Boolean, default: false },
    loading: {
      type: Object,
      default() {
        return { get: false, save: false };
      }
    },
    size: {
      type: String,
      enum: ['is-small', 'is-medium', 'is-large', 'is-ex-large'],
      default: 'is-small'
    },
    isFullButtonWidth: {
      type: Boolean,
      default: false
    }
  }
};
</script>

<style lang="sass" scoped>
.modal-component
  z-index: 1000
  &[size=is-auto]
    ::v-deep
      .modal-content
        width: fit-content
        max-width: 90%
  &.is-full-screen
    ::v-deep
      .modal-content
        max-width: 90%!important
  ::v-deep
    .table-wrapper
      height: 100%!important
    .modal-close
      display: none
  .modal-title
    width: 100%
    justify-content: space-between
    padding: 20px 25px
    font-weight: bold
    font-size: 20px
    background: $main-background
    border-radius: 8px
    color: $gray-900
  .modal-content-container
    padding: 20px 25px
    height: calc(100% - 70px)
    .slot
      height: calc(100% - 75px)
      padding-bottom: 10px
      // height: 90%
      overflow: auto
      &.allowOverflow
        overflow: visible
    .modal-footer
      margin-top: 10px
      padding-bottom: 10px
      // border-bottom: 1px solid #E8E8E8
  ::v-deep
    .modal-content
      overflow: visible
      max-height: 90%
      .card
        height: 100%
        overflow: visible
    .modal-footer
      .button
        margin: 5px 5px 5px 0
        min-width: 165px
        &:last-child
          margin-right: 0px
  button.modal-close.is-large
    display: none
  .loading
    padding: 25px

  @media only screen and (max-width: $bp-sm)
    .card
      width: 300px
      margin: 0 auto
</style>

import { NotificationProgrammatic } from 'buefy';
import { DialogProgrammatic as Dialog } from 'buefy';
import successSound from '@/assets/sounds/success.mp3';
const prepareType = (strType) => {
  switch (strType) {
    case 'success':
      return 'is-success';
    case 'alert':
    case 'warning':
      return 'is-warning';
    case 'danger':
    case 'error':
      return 'is-danger';
    case 'info':
      return 'is-info';
    default:
      return strType;
  }
};
const toast = (t = 'success', m = 'toast', duration = 5000, opt = {}) => {
  let options = {
    container: '#app #layout',
    type: typeof t == 'object' ? 'is-success' : prepareType(t),
    message: m,
    duration,
    animation: 'fade',
    position: 'is-top-right',
    hasIcon: true,
    queue: false,
    ...opt
  };
  if (typeof t === 'object') {
    if (t.type) t.type = prepareType(t.type);
    options = { ...options, ...t };
  }

  const notific = NotificationProgrammatic.open(options);
  if (options.onClose) {
    notific.$on('close', () => {
      options.onClose('closed');
    });
  }
  if (options.onClick) {
    notific.$on('click', () => {
      if (notific.isActive) {
        options.onClick(notific);
        notific.close();
      }
    });
  }
};
const Notification = (type, message, options) => {
  if (options.sound) {
    const audio = new Audio(options?.sound);
    audio.play();
    audio.onerror = () => {
      console.warn('Secondary sound Loaded');
      const audioDeffault = new Audio(successSound);
      audioDeffault.play();
    };
  }
  toast(type, message, 3000, { indefinite: false, hasIcon: false, ...options });
};

const separatorStyle = 'margin: 10px 0;';
const titleStyle = 'font-weight:bold;';
const bodyStyle = '';

const confirm = ({ title, message, onCancel, onConfirm, cancelText, confirmText }) => {
  const HTMLSeparator = title && message ? `<hr style="${separatorStyle}" />` : '';
  const HTMLTitle = title ? `<div style="${titleStyle}">${title}</div>${HTMLSeparator}` : '';
  const HTMLBody = message ? `<div style="${bodyStyle}">${message}</div>` : '';
  const HTML = HTMLTitle + HTMLBody;

  return new Promise((resolve, reject) => {
    Dialog.confirm({
      container: '#app #layout',
      cancelText,
      confirmText,
      message: HTML,
      onCancel: async () => {
        if (onCancel) await onCancel();
        reject();
      },
      onConfirm: async () => {
        if (onConfirm) await onConfirm();
        resolve();
      }
    });
  });
};
export default { toast };
export { toast, Notification, confirm };

<template>
  <v-popover
    :placement="position"
    trigger="hover"
    :disabled="!active"
    container="#layout"
    :delay="{ show: 300, hide: 0 }"
    :offset="5"
    :popoverClass="baseClass"
  >
    <slot />
    <template slot="popover"> {{ label }} </template>
  </v-popover>
</template>

<script>
export default {
  props: {
    active: { type: Boolean, default: true },
    appendToBody: { type: Boolean, default: false },
    baseClass: { type: String, default: 'is-primary' },
    label: { type: String, default: 'Tooltip' },
    multilined: { type: Boolean, default: false },
    position: { type: String, default: 'top' }
  }
};
</script>

<style lang="sass" scoped>
.tooltip-link
  position: relative
</style>

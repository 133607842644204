<template>
  <Field
    v-bind="{ ...$props, ...$attrs }"
    class="phone-picker"
    :value="model"
    :label="label"
    :type="'tel'"
    :validation-message="validationMessage"
    :pattern="pattern"
    :maxlength="12"
    :has-counter="false"
    :placeholder="placeholder"
    :readonly="readonly"
    :required="required"
    @primitiveInput="onInput"
    placeholder-char="0"
    mask="111-111-1111"
  />
</template>

<script>
import Field from '../Field.vue';

export default {
  components: {
    Field
  },
  data() {
    return {
      model: this.value
    };
  },
  created() {
    const { value } = this;
    if (value) this.model = `${value.slice(0, 3)}-${value.slice(3, 6)}-${value.slice(6, 10)}`;
  },
  computed: {
    isDifferentTo() {
      return this.differentTo != this.value;
    },
    pattern() {
      let pattern = '^(?=[0-9]{3}-[0-9]{3}-[0-9]{4})';
      if (this.differentTo?.length == 10) pattern += `(?!${this.phoneMask(this.differentTo)})`;
      pattern += '.*';
      return pattern;
    },
    validationMessage() {
      let message = this.$t('validations.phone');
      if (!this.isDifferentTo) message = this.$t('validations.useAnotherPhone');
      return message;
    }
  },
  methods: {
    onInput(value) {
      this.$emit('input', this.phoneMask(value, false));
    },
    phoneMask(val, mask = true) {
      if (!val) return '';
      let mPhone = '';
      if (mask) mPhone = `${val.slice(0, 3)}-${val.slice(3, 6)}-${val.slice(6, 10)}`;
      else mPhone = val.replace(/[-+()\s]/g, '');
      return mPhone;
    },
    setModel(val) {
      this.model = this.phoneMask(val);
    }
  },
  watch: {
    value(val) {
      this.setModel(val);
    }
  },
  props: {
    label: { type: String, default: '' },
    required: { type: Boolean, default: false },
    readonly: { type: Boolean, default: false },
    value: { default: null },
    differentTo: { type: String, default: '' },
    placeholder: { type: String, default: '000-000-0000' }
  }
};
</script>

<style lang="sass" scoped></style>

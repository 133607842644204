var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('Modal',{attrs:{"id":"EditRun-Modal","isOpen":_vm.isModalOpen,"header":_vm.$t('run.edit'),"size":"is-medium","loading":_vm.loading},on:{"update:isOpen":function($event){_vm.isModalOpen=$event},"update:is-open":function($event){_vm.isModalOpen=$event},"cancel":function () {},"save":_vm.onSubmit}},[_c('form',{ref:"form",staticClass:"modal-form",on:{"submit":function($event){$event.preventDefault();return _vm.onSubmit.apply(null, arguments)}}},[_c('b-field',[_c('Autocomplete',{staticClass:"f-driver",attrs:{"label":_vm.$t('global.driver'),"placeholder":"Select driver","max-height":"95","api":{
          url: 'employees',
          query: 'full_name',
          params: [
            { id: 'is_active', value: 1 },
            { id: 'user_type_name', value: 'Driver' }
          ],
          full: true
        },"field":"full_name","model":"id","required":""},model:{value:(_vm.runData.employee_id),callback:function ($$v) {_vm.$set(_vm.runData, "employee_id", $$v)},expression:"runData.employee_id"}}),_c('Autocomplete',{staticClass:"f-vehicle",attrs:{"label":_vm.$t('run.vehicle'),"placeholder":"Select vehicle","dropdownPosition":"left","max-height":"95","aria-role":"dialog","api":{
          url: '/vehicles',
          params: [{ id: 'is_active', value: 1 }],
          full: true
        },"field":"name","model":"id","required":""},model:{value:(_vm.runData.vehicle_id),callback:function ($$v) {_vm.$set(_vm.runData, "vehicle_id", $$v)},expression:"runData.vehicle_id"}})],1),_c('b-field',{staticClass:"cdp-container"},[_c('Autocomplete',{staticClass:"w-100",attrs:{"label":"CDP","placeholder":"Select CDP","dropdownPosition":"top","api":{
          url: '/cdp',
          params: [{ id: 'is_active', value: 1 }],
          full: true
        },"field":"name","model":"id","required":""},model:{value:(_vm.runData.close_door_pharmacy_id),callback:function ($$v) {_vm.$set(_vm.runData, "close_door_pharmacy_id", $$v)},expression:"runData.close_door_pharmacy_id"}})],1)],1)])}
var staticRenderFns = []

export { render, staticRenderFns }
//! sort exports alphabetically
export default {
  ActivityLogger: {
    read: { method: 'GET', path: '/activity_logger/{id}' },
    readAll: { method: 'GET', path: '/activity_logger' }
  },
  AdvancedSettings: {
    read: { method: 'GET', path: '/system/settings' }
  },
  BillingReport: {
    generate: { method: 'POST', path: '/employees/reports/billing' }
  },
  CDPs: {
    create: { method: 'POST', path: '/cdp' },
    read: { method: 'GET', path: '/cdp/{id}' },
    readAll: { method: 'GET', path: '/cdp' },
    update: { method: 'PUT', path: '/cdp/{id}' },
    disabled: { method: 'PATCH', path: '/cdp/{id}' }
  },
  DeliveryInbox: {
    create: { method: 'POST', path: '/deliveries_inbox' },
    read: { method: 'GET', path: '/deliveries_inbox/{id}' },
    readAll: { method: 'GET', path: '/deliveries_inbox' },
    update: { method: 'PUT', path: '/deliveries_inbox/{id}' },
    delete: { method: 'DELETE', path: '/deliveries_inbox/{id}' }
  },
  DeliveryInboxProcessCSV: {
    create: { method: 'POST', path: '/deliveries_inbox/process_csv' }
  },
  Dispatch: {
    readAll: { method: 'GET', path: '/delivery_manifest' },
    readAllCurrent: { method: 'GET', path: '/delivery_manifest/current_deliveries_manifest' },
    update: { method: 'PUT', path: '/delivery_manifest/{id}/delivery_events/{event_id}' },
    addOn: { method: 'POST', path: '/delivery_manifest/{id}/delivery_events_addon' },
    changeDeliveryStatus: {
      method: 'POST',
      path: '/delivery_manifest/{id}/delivery_events/{event_id}/change_status'
    },
    deliveryEvents: { method: 'GET', path: '/delivery_manifest/delivery_events/search' },
    downloadCSV: { method: 'GET', path: '/delivery_manifest/reports/csv' },
    notifyDriver: { method: 'POST', path: '/delivery_manifest/{id}/notify_driver' },
    reSchedule: {
      method: 'POST',
      path: '/delivery_manifest/{id}/delivery_events/{event_id}/re_schedule'
    },
    report: { method: 'POST', path: '/delivery_manifest/reports/pdf' },
    shareRX: { method: 'POST', path: '/delivery_manifest/{id}/request_url' },
    TransferRx: {
      method: 'PUT',
      path: '/delivery_manifest/{id}/delivery_events/{event_id}/transfer_delivery'
    },
    viewHistory: {
      method: 'GET',
      path: '/delivery_manifest/{id}/delivery_events/{event_id}/life_cycle'
    },
    viewIssues: {
      method: 'GET',
      path: '/delivery_manifest/unsuccesfully_events'
    },
    getIssues: {
      method: 'GET',
      path: '/delivery_manifest/unsuccesfully_events/total_items'
    },
    resultIssues: {
      method: 'PUT',
      path: '/delivery_manifest/{id}/delivery_events/{event_id}/fix_as_failed'
    }
  },
  Employees: {
    bypass: { method: 'POST', path: '/employees/{id}/bypass_start_work' },
    create: { method: 'POST', path: '/employees' },
    read: { method: 'GET', path: '/employees/{id}' },
    readAll: { method: 'GET', path: '/employees' },
    setDefault: { method: 'PATCH', path: '/employees/{id}' },
    update: { method: 'PUT', path: '/employees/{id}' }
  },
  FundingSource: {
    readAll: { method: 'GET', path: '/funding_sources' }
  },
  Imports: {
    read: { method: 'GET', path: '/file_import/{group_id}/deliveries_import' },
    readAll: { method: 'GET', path: '/file_import' },
    download: {
      method: 'POST',
      path: '/presigned/import/unattended_deliveries/{file_name}/download'
    },
    upload: { method: 'POST', path: '/presigned/import/unattended_deliveries' }
  },
  Participants: {
    create: { method: 'POST', path: '/participants' },
    delete: { method: 'DELETE', path: '/participants/{id}' },
    inactivate: { method: 'PUT', path: '/participants/{id}/inactivate' },
    read: { method: 'GET', path: '/participants/{id}' },
    readAll: { method: 'GET', path: '/participants' },
    update: { method: 'PUT', path: '/participants/{id}' },
    toggle: { method: 'PATCH', path: '/participants/{id}/is_active/toggle' }
  },
  ParticipantAddresses: {
    create: { method: 'POST', path: '/participants/{id}/addresses' },
    read: { method: 'GET', path: '/participants/{id}/addresses' },
    setDefault: { method: 'PATCH', path: '/participants/{id}/addresses/{address_id}' },
    update: { method: 'PUT', path: '/participants/{id}/addresses/{address_id}' }
  },
  Reports: {
    create: { method: 'POST', path: '/' },
    read: { method: 'GET', path: '/' },
    readAll: { method: 'GET', path: '/' },
    update: { method: 'PUT', path: '/' }
  },
  PasswordManager: {
    reset: { method: 'PATCH', path: '/users/{id}/fetch_password' }
  },
  Rol_Permissions: {
    create: { method: 'POST', path: '/user_types' },
    readAll: { method: 'GET', path: '/user_types' },
    update: { method: 'PUT', path: '/user_types/{id}' }
  },
  Runs: {
    create: { method: 'POST', path: '/driver_runs' },
    read: { method: 'GET', path: '/driver_runs/{id}' },
    readAll: { method: 'GET', path: '/driver_runs' },
    update: { method: 'PUT', path: '/driver_runs/{id}' },
    setDefault: { method: 'PATCH', path: '/driver_runs/{id}' }
  },
  Scheduling: {
    cancel: { method: 'PATCH', path: '/delivery_manifest/unattended_deliveries/{id}/cancel' },
    create: { method: 'POST', path: '/delivery_manifest/unattended_deliveries' },
    readAll: { method: 'GET', path: '/delivery_manifest/unattended_deliveries' },
    update: { method: 'PUT', path: '/delivery_manifest/unattended_deliveries/{id}' },
    delete: { method: 'DELETE', path: '/delivery_manifest/unattended_deliveries/{id}' },
    assign: { method: 'POST', path: '/delivery_manifest/{id}/delivery_events' },
    unassign: { method: 'DELETE', path: '/delivery_manifest/{id}/delivery_events/{event_id}' },
    automaticScheduling: { method: 'POST', path: '/delivery_manifest/automatic_scheduling' },
    automaticSchedulingUpdate: {
      method: 'PUT',
      path: '/delivery_manifest/{id}/automatic_scheduling'
    },
    recalculateTrace: { method: 'POST', path: '/delivery_manifest/{id}/recalculate_trace' }
  },
  Vehicles: {
    create: { method: 'POST', path: '/vehicles' },
    read: { method: 'GET', path: '/vehicles/{id}' },
    readAll: { method: 'GET', path: '/vehicles' },
    setDefault: { method: 'PATCH', path: '/vehicles/{id}' },
    update: { method: 'PUT', path: '/vehicles/{id}' }
  },
  VehiclesCapacityType: {
    create: { method: 'POST', path: '/vehicle_capacity_types' }
  },
  VehiclesGarage: {
    create: { method: 'POST', path: '/vehicle_groups' }
  },
  VehiclesGroup: {
    create: { method: 'POST', path: '/vehicle_garages' }
  }
};

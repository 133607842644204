<template>
  <div class="address-selector">
    <Autocomplete
      v-if="isSelect"
      v-bind="{ ...$props, ...$attrs }"
      :label="label"
      :data="oAddressess"
      field="full_address"
      model="id"
      keepFirst
      setFirst
      @select="onSelect"
      :required="required"
      :dropdown-position="dropdownPosition"
      :loading="oLoading"
      :value="address"
      showResultsCounter
    />
    <Field v-else :label="label" v-model="currentAddress" :required="required" :disabled="true" />
    <div class="buttons-container">
      <Button
        v-if="isSelect || isAdd"
        class="first-btn"
        type="is-primary"
        icon-right="plus"
        :tooltip="$t('addLabel')"
        :tooltipPosition="buttonTooltipPosition"
        :disabled="isAdd"
        outlined
        @click="handleAddAddress"
      />

      <Button
        v-if="isSelect || isEdit"
        :class="isEdit ? 'first-btn' : 'edit-btn'"
        type="is-primary"
        icon-right="pencil"
        :tooltip="$t('address.edit')"
        :tooltipPosition="buttonTooltipPosition"
        :disabled="isEdit || notValidEdit"
        outlined
        @click="handleEditAddress"
      />

      <Button
        v-if="isAdd || isEdit"
        type="is-danger"
        icon-right="close"
        :tooltipPosition="buttonTooltipPosition"
        outlined
        @click="handleCancel"
      />
    </div>
  </div>
</template>

<script>
import { Autocomplete, Field } from '@/components';
import Button from '../Button.vue';
export default {
  components: {
    Autocomplete,
    Button,
    Field
  },
  mounted() {
    if (this.initAdd) this.handleAddAddress();
    if (this.address) {
      this.$emit('input', this.address);
    }
  },
  data() {
    return {
      address: this.value,
      previousAddress: null,
      currentState: 'SELECT',
      oAddressess: this.data,
      oLoading: this.loading,
      currentAddress: null
    };
  },
  computed: {
    isSelect() {
      return this.currentState === 'SELECT';
    },
    isAdd() {
      return this.currentState === 'ADD';
    },
    isEdit() {
      return this.currentState === 'EDIT';
    },
    notValidEdit() {
      return this.address === null || String(this.address).length === 0;
    },
    isEmptyField() {
      return String(this.address).length > 1;
    }
  },
  methods: {
    onSelect(value) {
      this.$emit('select', value);
    },
    handleEditAddress() {
      this.currentState = 'EDIT';
      this.previousAddress = this.address;
    },
    handleAddAddress() {
      if (!this.initAdd) {
        this.previousAddress = this.address;
        this.address = '';
      }
      this.currentState = 'ADD';
    },
    handleCancel() {
      this.currentState = 'SELECT';
      this.address = this.previousAddress;
    }
  },
  watch: {
    value(address) {
      this.address = address;
      const findAddress = this.oAddressess.find((objectAddress) => {
        return objectAddress.id === this.address;
      });

      this.currentAddress = findAddress?.full_address;
    },
    address(value) {
      this.$emit('input', value);
    },
    data(value) {
      this.oAddressess = value || [];
    },
    oLoading(value) {
      this.$emit('onloading', value);
    },
    loading(value) {
      this.oLoading = value;
    },
    currentState(value) {
      switch (value) {
        case 'SELECT':
          this.$emit('cancel');
          return;
        case 'ADD':
          this.$emit('add');
          return;
        case 'EDIT':
          this.$emit('edit');
          return;
        default:
          return;
      }
    }
  },
  props: {
    label: { type: String, default: '' },
    required: { type: Boolean, default: false },
    loading: { type: Boolean, default: false },
    value: { type: [String, Number], default: null },
    data: { type: Array, default: () => {} },
    buttonTooltipPosition: { type: String, default: 'top' },
    dropdownPosition: { type: String, default: 'auto' },
    type: { type: String, default: 'is-mobile' },
    initAdd: { type: Boolean, default: false }
  }
};
</script>

<style lang="sass" scoped>
.address-selector
  margin-right: 0.75em
  width: 100%
  display: flex!important
  ::v-deep
    .field
      input
        border-top-right-radius: 0
        border-bottom-right-radius: 0
    &:last-child
      border-radius: 0px 5px 5px 0px
    .field, .a-field
      width: 100%
  .buttons-container
    display: flex
    align-self: flex-end
    margin-bottom: 12px
    .first-btn
      border-radius: 0px
      ::v-deep
        .button
          border-radius: 0px
    .edit-btn
      ::v-deep
        .button
          border-left: none
</style>

<template>
  <!-- //TODO COLLAPSABLE MENU -->
  <div id="menu" :class="isHidden ? '' : 'active-menu'">
    <BillingReportModal :isOpen.sync="isBillingOpen" />
    <div id="m-head" class="flex f-ai">
      <img v-if="isDarkMode" class="logo" src="@/assets/img/logo-dark.png" alt="CFS logo" />
      <img v-else class="logo" src="@/assets/img/logo-light.svg" alt="CFS logo" />
    </div>
    <div id="m-body">
      <div class="menu-group flex f-jc">
        <div class="nemt flex" :class="isSubMenuActive ? 'is-submenu-active' : ''">
          <b-icon class="m-icon" icon="pill" />
          <strong>{{ $t('menu.rxDeliveries') }}</strong>
        </div>
        <div class="rx-deliveries flex">
          <!-- :class="isSubMenuActive ? '' : 'is-submenu-active'"
          @click="submenuRxActive" -->
          <!-- <b-icon class="m-icon" icon="pill" />
          <strong>Rx Deliveries</strong> -->
        </div>
      </div>
      <div class="sub-menu-options">
        <div v-if="isSubMenuActive">
          <template v-for="({ name, meta, path }, index) of menuItems">
            <menu-section
              v-if="meta.items"
              :items="meta.items"
              :icon="meta.icon"
              :route="path"
              :name="name"
              :i18n="meta.i18n"
              :key="index"
            />
            <!-- 
          // TODO Notifications update.
        -->
            <menu-item-notifications
              v-else-if="meta.isParent && name == 'notification'"
              :key="index"
              :icon="meta.icon"
              :to="path"
              :i18n="meta.i18n"
            />
            <menu-item
              v-else-if="meta.isParent"
              :key="index"
              :icon="meta.icon"
              :secondaryIcon="meta.secondaryIcon"
              :to="path"
              :i18n="meta.i18n"
              :permission="meta.secure && meta.secure.permission"
            />
          </template>
        </div>
        <div v-else></div>
      </div>
    </div>
    <div id="m-billing">
      <Button
        :label="$t('billing.billing_report')"
        @click="() => (isBillingOpen = true)"
        icon-left="file-document-alert-outline"
        type="is-primary"
        :permission="Permissions.generate"
        outlined
      />
    </div>
    <div id="m-footer">
      Copyright
      <small>{{ copyrightYear }}</small>
      <small class="appVersion">{{ appVersion }}</small>
    </div>
  </div>
</template>

<script>
import menuItem from './menuItem';
import menuItemNotifications from './menuItemNotifications';
import menuSection from './menuSection';
import ROUTES from '@/router/routes';
import { Button } from '@/components';
import { BillingReportModal } from './fragment';
import { Permissions } from '@/utils/Secure';
// import { sortBy, prop } from 'ramda';
const appVersion = process.env.VUE_APP_VERSION || null;
export default {
  components: {
    menuItem,
    menuItemNotifications,
    menuSection,
    Button,
    BillingReportModal
  },
  data() {
    const menuItems = ROUTES.filter((m) => m.inMenu && m.meta?.isParent);
    // const sortByOrder = sortBy(prop('order'));
    return {
      isSubMenuActive: true,
      menuItems,
      isBillingOpen: false
    };
  },
  computed: {
    appVersion() {
      return appVersion;
    },
    isDarkMode() {
      return this.$store.getters.DARK;
    },
    copyrightYear() {
      return this.$moment().format('YYYY');
    },

    Permissions() {
      return Permissions.BillingReport;
    }
  },
  methods: {
    submenuNemtActive() {
      if (this.isSubMenuActive) return;
      this.isSubMenuActive = !this.isSubMenuActive;
    },
    submenuRxActive() {
      if (!this.isSubMenuActive) return;
      this.isSubMenuActive = !this.isSubMenuActive;
    }
  },
  props: {
    isHidden: { type: Boolean, default: false }
  }
};
</script>

<style lang="sass" scoped>
.appVersion
  display: block
  font-size: 11px
  opacity: .5
#menu
  display: flex
  flex-flow: column
  text-align: left
  min-width: 280px
  height: 100%
  background: $main-background
  box-shadow: 4px 0px 30px rgba(0, 0, 0, 0.09)
  transition: $transition-dark-mode
  z-index: 100
#m-head
  background: $main-background
  height: 120px
  transition: $transition-dark-mode
  .logo
    text-align: left
    margin-left: 27px
    margin-bottom: 20px
    width: 80px
#m-body
  height: calc(100% - 168px)
  display: flex
  flex-flow: column
  .menu-group
    margin-bottom: 5px
    margin-left: 16px
    justify-content: left
    .nemt, .rx-deliveries
      cursor: pointer
      border-bottom: 1px solid $gray-200
      padding: 8px 12px
      ::v-deep
        .m-icon
          margin-right: 5px
          i::before
            font-size: 18px
    .rx-deliveries
      width: 95px

    .is-submenu-active
      border: 1px solid $gray-200
      border-bottom: none
      border-radius: 5px 5px 0px 0px
      color: $blue-300
      strong
        color: $blue-500
  .sub-menu-options
    overflow-y: auto
#m-billing
  justify-self: flex-end
  text-align: center
  padding-bottom: 45px
  background: $main-background
  color: $gray-800
  margin-top: auto
  transition: $transition-dark-mode
  .button
    background-color: $white
    min-width: 147px
    &:hover
      background-color: $blue-500
#m-footer
  justify-self: flex-end
  text-align: center
  padding: 0px 12px 5px
  background: $main-background
  color: $gray-800
  margin-top: auto
  transition: $transition-dark-mode
.active-menu
  left: 0px !important

@media screen and (max-width: $bp-xxl)
  #menu
    height: 100%
    left: -280px
    min-width: 280px
    position: fixed
    overflow-y: auto
    z-index: 301
  #m-head
    .logo
      margin-top: 20px
      margin-bottom: 20px
      margin-left: 27px
      text-align: left
      width: 80px
.dark
  #m-billing
    background-color: $dark-900
    .button
      background-color: $blue-400
      color: $dark-200
      min-width: 147px
      &:hover
        background-color: #bfc6fc
</style>

import { Permissions } from '@/utils/Secure';
const { Employees } = Permissions;
export default [
  {
    inMenu: true,
    component: () => import(/* webpackChunkName: "employees" */ '@/views/Employees/index'),
    meta: {
      i18n: 'menu.emp',
      secure: { permission: Employees.readAll, redirect: null },
      title: 'Employees'
    },
    name: 'employees',
    path: '/data/employees'
  },
  {
    component: () => import(/* webpackChunkName: "employee/new" */ '@/views/Employees/Employee'),
    meta: { secure: { permission: Employees.create, redirect: null } },
    name: 'newEmployee',
    path: '/data/employees/add'
  },
  {
    component: () => import(/* webpackChunkName: "employee/edit" */ '@/views/Employees/Employee'),
    meta: { secure: { permission: Employees.update, redirect: '/data/employees' } },
    name: 'employee',
    path: '/data/employees/:employeeId(\\d+)'
  },
  {
    component: () =>
      import(/* webpackChunkName: "employee/homeAddress" */ '@/views/Employees/Employee'),
    meta: { current: 2, secure: { permission: Employees.update, redirect: '/data/employees' } },
    name: 'employee/homeAddress',
    path: '/data/employees/:employeeId(\\d+)/homeAddress'
  }
];

<template>
  <div
    v-if="[6, 7, 16, 11, 17].includes(this.deliveryId)"
    id="signatureError"
    :class="isSignature && 'signature-history'"
  >
    <img :src="imageError" alt="image-signature-error" />
  </div>
</template>

<script>
import Failed from '@/assets/img/status/Status-Failed.png';
import Rejected from '@/assets/img/status/Status-Rejected.png';
import Cancelled from '@/assets/img/status/Status-Cancelled.png';

export default {
  data() {
    return {
      rejectIDs: [6],
      cancelledIDs: [7, 16],
      failedIDs: [11, 17]
    };
  },
  computed: {
    imageError() {
      if (this.rejectIDs.includes(this.deliveryId)) return Rejected;
      else if (this.cancelledIDs.includes(this.deliveryId)) return Cancelled;
      else if (this.failedIDs.includes(this.deliveryId)) return Failed;

      return '';
    }
  },
  props: {
    isSignature: { type: Boolean, default: false },
    deliveryId: { type: Number, default: null }
  }
};
</script>

<style lang="sass" scoped>
#signatureError
  img
    width: 120px
.signature-history
  align-content: center
  border-radius: 0.6rem
  border: 1px solid $gray-300
  display: flex
  justify-content: center
</style>

import i18n from '@/locales';
export const highlightsIdentifier = (highlightsArray) => {
  let highlights = [];

  const hKeys = Object.keys(highlightsArray);
  const hValues = Object.values(highlightsArray);

  for (let i = 0; i < hKeys.length; i++) {
    highlights.push(_highlightsTypes(hKeys[i], hValues[i]));
  }

  return highlights;
};

const _highlightsTypes = (key, value) => ({
  ...{
    participant_full_name: {
      title: i18n.t('participant.participant'),
      icon: 'account-circle',
      description: value[0]
    },
    participant_client_code: {
      title: i18n.t('participant.participant'),
      icon: 'pound-box',
      description: value[0]
    },
    'participant_address.full_address': {
      title: i18n.t('address.address', ['']),
      icon: 'map-marker',
      description: value[0]
    },
    funding_source_full_address: {
      title: i18n.t('address.address', ['']),
      icon: 'map-marker',
      description: value[0]
    },
    participant_contacts: {
      title: i18n.tc('contact.label', 1),
      icon: 'notebook',
      description: value[0]
    },
    participant_alias: {
      title: i18n.t('global.alias'),
      icon: 'drama-masks',
      description: value[0]
    }
  }[key]
});

const warn = 'warn';
const err = 'error';
const prefix = { autoZoom: 'AutoZoom:' };

const errorMaker = (type, prefix, text) => ({ type, text: `${prefix} ${text}` });
export const autoZoomError = {
  requieredBounds: errorMaker(err, prefix.autoZoom, 'Bounds are requiered. [[lat,lng],...]'),
  minBounds: errorMaker(warn, prefix.autoZoom, 'Requiered at least 2 bounds')
};

export const showError = ({ type, text }) => {
  console[type || 'log'](text);
};

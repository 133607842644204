export default {
  state: {
    assignedDelivery: {},
    bestDriverCandidate: {},
    currentManifest: {},
    filters: {},
    manifests: []
  },
  getters: {
    lunchBreak: ({ lunchBreak }) => lunchBreak,
    currentManifest: ({ currentManifest }) => currentManifest,
    date: ({ filters }) => filters.date,
    manifests: ({ manifests }) => manifests
  },
  mutations: {
    assignedDelivery(state, assignedDelivery) {
      state.assignedDelivery = assignedDelivery;
    },
    bestDriverCandidate(state, bestDriverCandidate) {
      state.bestDriverCandidate = bestDriverCandidate;
    },
    currentManifest(state, currentManifest) {
      state.currentManifest = currentManifest || {};
    },
    filters(state, filters) {
      state.filters = filters;
    },
    fileImports(state, fileImports) {
      state.fileImports = fileImports;
    },
    lunchBreak(state, lunchBreak) {
      state.lunchBreak = lunchBreak;
    },
    manifests(state, manifests) {
      state.manifests = manifests || [];
    },
    reloadTable() {}
  },
  actions: {}
};

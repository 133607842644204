export const downloadCSVStringData = (filename, data) => {
  const downloadLink = document.createElement('a');
  const blob = new Blob(['\ufeff', data]);
  const url = URL.createObjectURL(blob);
  downloadLink.href = url;
  if (filename) downloadLink.download = `${filename}.csv`;
  document.body.appendChild(downloadLink);
  downloadLink.click();
  document.body.removeChild(downloadLink);
};
export const downloadFileByURL = (url) => {
  if (!url) throw new Error('500: The URL to the file is undefined');
  const downloadLink = document.createElement('a');
  downloadLink.href = url;
  document.body.appendChild(downloadLink);
  downloadLink.click();
  document.body.removeChild(downloadLink);
};

<template>
  <div class="tags-list">
    <div v-if="loading" class="flex">
      <div class="mr-2" v-for="i in 3" :key="`sk-${i}`">
        <b-skeleton width="150px" animated />
      </div>
    </div>
    <template v-else>
      <b-tag class="tags-list-tag mr-2" rounded v-for="(tag, i) in value" :key="`tag-${i}`">
        <template v-if="typeof tag === 'string'">
          {{ tag }}
        </template>
        <Tooltip v-else-if="tag.tooltip" :label="tag.tooltip">
          <span class="underlined">
            {{ tag.text }}
          </span>
        </Tooltip>
        <span v-else>
          {{ tag.text }}
        </span>
      </b-tag>
    </template>
  </div>
</template>

<script>
import Tooltip from './Tooltip.vue';

export default {
  components: { Tooltip },
  computed: {},
  props: {
    loading: { type: Boolean, default: false },
    value: { type: Array, default: () => [] }
  }
};
</script>

<style lang="sass" scoped>
.underlined
  color: $primary
.tags-list
    margin-bottom: 20px
    &-tag
      background: $yellow-200
      color: $gray-700
      font-size: 14px
      font-weight: 500
</style>

<template>
  <Dropdown
    v-if="hasPermission"
    class="CDPFilter-dropdown"
    :icon="icon"
    :showLabel="false"
    :items="filteredOptions"
    :disabled="!filteredOptions.length"
    position="is-bottom-left"
    useButton
    :skeletonLoading="skeletonLoading || loading"
    useCustomItem
    :label="$t('cdp.filterBy')"
    :close-on-click="false"
    :hideIfNotAllowedItems="false"
  >
    <template v-slot:default="{ items }">
      <b-dropdown-item v-for="item in items" :key="item.id">
        <b-card
          :is="isRadio ? 'b-radio' : 'b-checkbox'"
          v-model="checkedOptions"
          @click.native="handleCheck"
          name="name"
          :native-value="item.id"
        >
          <div class="flex f-ai w-100">
            <ColorIdentifier :color="item.color" />
            {{ item.name }}
          </div>
        </b-card>
      </b-dropdown-item>
    </template>
  </Dropdown>
</template>

<script>
import { Dropdown } from '..';
import { getActiveCDPsService } from '../../services/CPDService';
import { delayer } from '../../utils/Helpers';
import { Permissions } from '../../utils/Secure';
import ColorIdentifier from './ColorIdentifier.vue';

export default {
  components: {
    Dropdown,
    ColorIdentifier
  },
  async mounted() {
    this.loading = true;
    await this.getCDPs();
    this.loading = false;
    this.prevCheckedOptions = this.defaultValue;
  },
  data() {
    return {
      options: [],
      checkedOptions: this.value,
      prevCheckedOptions: null,
      loading: false
    };
  },
  computed: {
    defaultValue() {
      return this.isRadio ? null : [];
    },
    filteredOptions() {
      if (this.filterIds === null) return this.options;
      return this.options.filter((option) => this.filterIds.find((F) => F === option.id));
    },
    hasPermission() {
      return this.Secure.permissionValidator(Permissions.CDPs.read);
    },
    icon() {
      const hasOptions = this.isRadio ? this.checkedOptions : this.checkedOptions.length;

      return `filter-menu${hasOptions ? '' : '-outline'}`;
    }
  },
  methods: {
    async handleCheck() {
      await delayer(50);
      if (this.checkedOptions === this.prevCheckedOptions) this.checkedOptions = this.defaultValue;

      this.prevCheckedOptions = this.checkedOptions;

      this.$emit('input', this.checkedOptions);
    },
    async getCDPs() {
      try {
        const cancelToken = this.Api.cancelToken;
        this.cancelToken = cancelToken;
        const data = await getActiveCDPsService();
        this.options = data;
        this.$emit('loaded', data);
      } catch (error) {
        console.log('::::Err', error);
      }
    }
  },
  watch: {},
  props: {
    value: { type: [Array, Number], default: () => [] },
    filterIds: { type: Array, default: null },
    skeletonLoading: { type: Boolean, default: false },
    isRadio: { type: Boolean, default: false }
  }
};
</script>

<style lang="sass" scoped>
.CDPFilter-dropdown
  max-width: 40px
  margin-left: 1rem
.dropdown-item
  padding: 0 1rem
.radio,
.checkbox
  width: 100%
  padding: 0.375rem 0
</style>

<template>
  <MaskedInput
    v-if="mask"
    :mask="mask"
    v-model="model"
    v-bind="{ ...$props, ...$attrs }"
    @input="onMaskedInput"
    ref="input"
  />
  <b-input
    v-else
    v-bind="{ ...$props, ...$attrs }"
    v-model="model"
    @keypress.enter.native="handleEmit"
    @focus="onFocus"
    @blur="onBlur"
    @input="onInput"
    :validation-message="validationMessage || $t('validations.checkField')"
    ref="input"
  />
</template>

<script>
import MaskedInput from './MaskedInput';
import { Normalize } from '@/utils/Text';

export default {
  components: {
    MaskedInput
  },
  data() {
    return {
      hasFocus: false,
      hasEmited: false,
      model: this.NormalizeText(this.value),
      delayer: 0
    };
  },
  mounted() {
    if (this.focus) {
      setTimeout(() => {
        this.$refs?.input?.focus();
      }, 50);
    }
  },
  computed: {
    hasAddClass() {
      return this.$listeners.add || this.$listeners.edit ? 'hasAdd' : '';
    }
  },
  methods: {
    NormalizeText(text) {
      return Normalize(text, { upper: this.upper });
    },
    onFocus() {
      this.hasFocus = true;
    },
    onBlur() {
      this.hasFocus = false;
    },
    onMaskedInput() {
      this.hasEmited = false;
      this.$emit('primitiveInput', this.model);
      this.handleEmit();
    },
    onInput() {
      this.$emit('primitiveInput', this.model);
      this.handleInputTimeout();
      if (this.hasFocus) return;
      this.handleEmit();
    },
    handleInputTimeout() {
      if (this.inputTimeout) {
        clearTimeout(this.delayer);
        this.delayer = setTimeout(() => {
          this.handleEmit();
        }, this.inputTimeout);
      }
    },
    handleEmit() {
      if (this.hasEmited) return;
      this.$emit('input', this.model);
      this.hasEmited = true;
    }
  },
  watch: {
    hasFocus(value) {
      if (!value) this.onInput();
    },
    value(input) {
      this.model = this.NormalizeText(input);
    },
    model(input, old) {
      const { NormalizeText } = this;
      if (NormalizeText(input) == NormalizeText(old)) return;
      this.$emit('model', NormalizeText(input));
      this.hasEmited = false;
    }
  },
  props: {
    focus: { type: Boolean, default: false },
    mask: { type: String },
    upper: { type: Boolean, default: true },
    validationMessage: { type: String, value: null },
    inputTimeout: { type: Number, default: 0 },
    value: {}
  }
};
</script>

<template>
  <Modal
    id="EditRun-Modal"
    :isOpen.sync="isModalOpen"
    :header="$t('run.edit')"
    size="is-medium"
    :loading="loading"
    @cancel="() => {}"
    @save="onSubmit"
  >
    <form class="modal-form" ref="form" @submit.prevent="onSubmit">
      <b-field>
        <Autocomplete
          class="f-driver"
          :label="$t('global.driver')"
          placeholder="Select driver"
          max-height="95"
          :api="{
            url: 'employees',
            query: 'full_name',
            params: [
              { id: 'is_active', value: 1 },
              { id: 'user_type_name', value: 'Driver' }
            ],
            full: true
          }"
          v-model="runData.employee_id"
          field="full_name"
          model="id"
          required
        />
        <Autocomplete
          class="f-vehicle"
          :label="$t('run.vehicle')"
          placeholder="Select vehicle"
          dropdownPosition="left"
          max-height="95"
          aria-role="dialog"
          :api="{
            url: '/vehicles',
            params: [{ id: 'is_active', value: 1 }],
            full: true
          }"
          v-model="runData.vehicle_id"
          field="name"
          model="id"
          required
        />
      </b-field>
      <b-field class="cdp-container">
        <Autocomplete
          class="w-100"
          label="CDP"
          placeholder="Select CDP"
          dropdownPosition="top"
          :api="{
            url: '/cdp',
            params: [{ id: 'is_active', value: 1 }],
            full: true
          }"
          v-model="runData.close_door_pharmacy_id"
          field="name"
          model="id"
          required
        />
      </b-field>
    </form>
  </Modal>
</template>

<script>
import { Autocomplete, Modal } from '@/components';
import { toast } from '@/utils/dialog';
import { delayer } from '../../../utils/Helpers';
export default {
  components: {
    Autocomplete,
    Modal
  },
  beforeDestroy() {
    this.emitOpen(false);
  },
  data() {
    return {
      iCDP: this.CDP,
      isModalOpen: false,
      loading: { save: false, get: false },
      runData: this.value
    };
  },
  methods: {
    emitOpen(value) {
      this.$emit('update:isOpen', value);
    },
    async dispatchUpdates() {
      this.$store.commit('dispatch/reloadTable');
      this.$store.commit('dispatch/run', this.runData);
      await delayer(1000);
    },
    async onSubmit() {
      if (this.validate()) {
        this.loading.save = true;
        const pData = this.prepareData();
        try {
          await this.Api.put(`/delivery_manifest/${this.runData.id}`, pData);
          await this.dispatchUpdates();
          toast('success', this.$t('messages.saved'), 5000);
          this.isModalOpen = false;
        } catch (error) {
          console.log(error);
        }
        this.loading.save = false;
      }
    },
    validate() {
      let htmlValidator = false;
      if (this.$refs.form) {
        htmlValidator = this.$refs.form.checkValidity();
        if (!htmlValidator) this.$refs.form.reportValidity();
      }
      return htmlValidator;
    },
    prepareData() {
      const { runData: R } = this;
      return {
        employee_id: R.employee_id,
        vehicle_id: R.vehicle_id,
        close_door_pharmacy_id: R.close_door_pharmacy_id
      };
    }
  },
  watch: {
    isOpen(value) {
      this.isModalOpen = value;
    },
    isModalOpen(value) {
      this.emitOpen(value);
    },
    value(value) {
      this.runData = value;
    }
  },
  props: {
    isOpen: { type: Boolean, default: false },
    CDP: { type: Number, default: null },
    value: { type: Object, default: () => ({}) }
  }
};
</script>

<style lang="sass" scoped>
.modal-form
  width: calc(100% - 1rem)
.f-driver
  margin-right: 23px
.f-driver,
.f-vehicle
  width: calc(50% - .75rem)
  margin-bottom: 24px
.f-vehicle
  ::v-deep
    .dropdown-menu
      left: auto
      right: 0
</style>

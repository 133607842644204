import { render, staticRenderFns } from "./DeliveryPriorityUI.vue?vue&type=template&id=461b0a29&scoped=true&"
var script = {}
import style0 from "./DeliveryPriorityUI.vue?vue&type=style&index=0&id=461b0a29&lang=sass&scoped=true&"
import style1 from "./DeliveryPriorityUI.vue?vue&type=style&index=1&lang=sass&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "461b0a29",
  null
  
)

export default component.exports
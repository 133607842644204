<template>
  <div id="transferRxModal">
    <Modal
      :isOpen.sync="isModalOpen"
      :header="$t('dispatch.transferRx.header')"
      :loading="loading"
      size="is-medium"
      @cancel="() => {}"
      @save="onSubmit"
      :buttonLabel="$t('global.transfer')"
    >
      <form ref="form" @submit.prevent="onSubmit">
        <b-field>
          <Autocomplete
            :label="$t('global.selectDriver')"
            :placeholder="$t('global.select')"
            :api="{
              url: '/employees/drivers-on-call',
              full: true
            }"
            field="full_name"
            model="employee_id"
            :filteredByModel="[from]"
            :keepFirst="false"
            v-model="asignedDriverId"
            required
          >
            <template #results="{ data }">
              <div class="flex f-jsb">
                <p>{{ data.full_name }}</p>
                <DeliveryStatusSignUI
                  class="driver-status"
                  :delivery="{
                    delivery_event_status_id: checktype(data.status),
                    event_type_is_mandatory: 0
                  }"
                />
              </div>
            </template>
          </Autocomplete>
        </b-field>
      </form>
    </Modal>
  </div>
</template>

<script>
import { Modal, DeliveryStatusSignUI } from '@/components';
import { Autocomplete } from '@/components';

export default {
  components: {
    Autocomplete,
    DeliveryStatusSignUI,
    Modal
  },
  data() {
    return {
      loading: { save: false },
      isModalOpen: false,
      asignedDriverId: null
    };
  },
  methods: {
    checktype(status) {
      if (status === 'SCHEDULED') return 12;
      return 13;
    },
    async onSubmit() {
      this.loading.save = true;
      try {
        await this.Api.put(
          `/delivery_manifest/${this.manifestId}/delivery_events/${this.eventId}/transfer_delivery`,
          { employee_id: this.asignedDriverId }
        );
        this.$toast('success', this.$t('messages.saved'), 5000);
        this.$emit('transfered');
        if (this.isModalOpen) this.isModalOpen = false;
      } catch (error) {
        console.log(error);
      }
      this.loading.save = false;
    }
  },
  watch: {
    isOpen(value) {
      this.isModalOpen = value;
    },
    isModalOpen(value) {
      this.$emit('update:isOpen', value);
      this.asignedDriverId = null;
    }
  },
  props: {
    isOpen: { type: Boolean, default: false },
    manifestId: { type: Number, default: null },
    eventId: { type: Number, default: null },
    from: { type: Number, default: null }
  }
};
</script>

<style lang="sass" scoped>
#transferRxModal
  .driver-status
    text-transform: capitalize
  ::v-deep
    .modal-content-container .slot
      overflow: visible
</style>

<template>
  <div class="color-identifier">
    <div class="c-identifier" :style="`background-color:${color}`" />
    <template v-if="$slots.default"><slot /></template>
  </div>
</template>

<script>
export default {
  props: {
    color: { type: String, default: '' },
    text: { type: String, default: '' }
  }
};
</script>

<style lang="sass" scoped>
.color-identifier
  display: grid
  grid-template-columns: 20px 1fr
  .c-identifier
    min-height: 15px
    height: 100%
    margin: 0 5px
    width: 6px
    border-radius: 5px
</style>

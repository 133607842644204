import { render, staticRenderFns } from "./DeliveryIcons.vue?vue&type=template&id=261ae91b&scoped=true&"
import script from "./DeliveryIcons.vue?vue&type=script&lang=js&"
export * from "./DeliveryIcons.vue?vue&type=script&lang=js&"
import style0 from "./DeliveryIcons.vue?vue&type=style&index=0&id=261ae91b&lang=sass&scoped=true&"
import style1 from "./DeliveryIcons.vue?vue&type=style&index=1&lang=sass&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "261ae91b",
  null
  
)

export default component.exports
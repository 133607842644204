import Api from '@/utils/Api.js';
import axios from 'axios';
const dataURItoBlob = (dataURI) => {
  // convert base64/URLEncoded data component to raw binary data held in a string
  var byteString;
  if (dataURI.split(',')[0].indexOf('base64') >= 0) byteString = atob(dataURI.split(',')[1]);
  else byteString = unescape(dataURI.split(',')[1]);

  // separate out the mime component
  var mimeString = dataURI.split(',')[0].split(':')[1].split(';')[0];

  // write the bytes of the string to a typed array
  var ia = new Uint8Array(byteString.length);
  for (var i = 0; i < byteString.length; i++) {
    ia[i] = byteString.charCodeAt(i);
  }

  return new Blob([ia], { type: mimeString });
};
const prepareUploadPresigned = async ({ fileName, apiUrl, originalFileName }) => {
  const params = { file_name: fileName };
  if (originalFileName) params.original_file_name = originalFileName;
  const { data } = await Api.post(apiUrl, params);
  return data;
};
const savePrepareForm = ({ fields, file, base64image }) => {
  let formData = new FormData();
  const oFile = file ? file : dataURItoBlob(base64image);
  formData.append('key', fields.key);
  formData.append('AWSAccessKeyId', fields.AWSAccessKeyId);
  formData.append('x-amz-security-token', fields['x-amz-security-token']);
  formData.append('policy', fields.policy);
  formData.append('signature', fields.signature);
  formData.append('file', oFile);
  return formData;
};
const upload = async ({ base64image, file, fields, url }) => {
  const body = savePrepareForm({ fields, file, base64image });
  const ax = axios.create();
  return await ax.post(url, body);
};
const uploadFile = async ({ base64image, file, fileName, apiUrl, originalFileName }) => {
  try {
    if ((base64image || file) && fileName && apiUrl) {
      const { fields, url } = await prepareUploadPresigned({
        fileName,
        apiUrl,
        originalFileName
      });
      await upload({ base64image, file, fields, url });
    }
  } catch (err) {
    err;
  }
};
const downloadFile = async ({ fileName, apiUrl }) => {
  const { data } = await Api.post(apiUrl, { file_name: fileName });
  return data.url;
};
export { downloadFile, uploadFile };

<template>
  <Button
    v-if="isButton"
    type="is-primary"
    :label="$t('dispatch.downloadEOD')"
    :loading="loading.csv"
    icon-left="download"
    @click="getCVSFile"
    :permission="Permission.downloadCSV"
  />
  <div v-else @click="getCVSFile">
    <slot v-bind="{ ...$props, ...$attrs }" />
  </div>
</template>

<script>
import { Button } from '@/components';
import { Permissions } from '@/utils/Secure';

export default {
  components: {
    Button
  },
  data() {
    return {
      currentManifests: [],
      loading: { getManifest: false, csv: false },
      date: this.getActualDate()
    };
  },
  computed: {
    Permission() {
      return Permissions.Dispatch;
    }
  },
  methods: {
    async getCVSFile() {
      this.downloadInProgress = true;
      this.loading.csv = true;
      await this.getCurrentManifests();
      try {
        if (this.currentManifests.length === 0) {
          this.loading.csv = false;
          return this.$toast('warning', this.$t('dispatcher.noManifestAvailable'), 3000);
        }

        const { data } = await this.Api.get(
          `/delivery_manifest/reports/csv?delivery_manifest_ids=[${this.currentManifests.toString()}]`
        );
        this.$store.dispatch('notification/addAsyncProcessing', data.uuid);
        this.$toast('success', this.$t('global.requestInProcessed'), 3000);
      } catch (error) {
        console.error(error);
      }
      this.loading.csv = false;
      this.downloadInProgress = false;
    },
    async getCurrentManifests() {
      this.loading.getManifest = true;
      try {
        if (this.newDate !== '') this.date = this.newDate;
        const { data } = await this.Api.get(
          `/delivery_manifest?delivery_manifest_status_id=4&date=${this.date}&per_page=9999`
        );
        this.currentManifests = data.map((element) => element.id);
      } catch (error) {
        console.error(error);
      }
      this.loading.getManifest = false;
    },
    getActualDate() {
      let today = new Date();
      return this.$moment(today).format('YYYY-MM-DD');
    },
    getTimeStamp() {
      return this.$moment().format('hh_mm_ss');
    }
  },
  watch: {
    downloadInProgress(value) {
      if (!this.isButton) this.$emit('update:downloadInProgress', value);
    }
  },
  props: {
    manifests: { type: Object, default: () => {} },
    newDate: { type: String, default: '' },
    isButton: { type: Boolean, default: false },
    downloadInProgress: { type: Boolean, default: false }
  }
};
</script>

<style lang="sass" scoped></style>

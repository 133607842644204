import i18n from '@/locales';
const _eventTypes = (id) => ({
  ...{
    1: {
      icon: 'van-utility',
      name: i18n.t('schedule.manifest.pullOut'),
      strId: 'pull-out',
      uuid: 'e1'
    },
    2: {
      icon: 'archive-arrow-up-outline',
      name: i18n.t('global.pickUp'),
      strId: 'pick-up',
      uuid: 'e2'
    },
    3: {
      icon: 'archive-arrow-up-outline',
      name: i18n.t('global.delivery'),
      strId: 'delivery',
      uuid: 'e3'
    },
    4: {
      icon: 'silverware-fork-knife',
      name: i18n.t('schedule.manifest.startLunch'),
      strId: 'start-lunch-break',
      uuid: 'e4'
    },
    5: {
      icon: 'silverware-fork-knife',
      name: i18n.t('schedule.manifest.finishLunch'),
      strId: 'finish-lunch-break',
      uuid: 'e5'
    },
    6: {
      icon: 'clipboard-arrow-left',
      name: i18n.t('global.return'),
      strId: 'return',
      uuid: 'e6',
      actionMsg: i18n.t('dispatch.status.returnedBy')
    },
    7: {
      icon: 'van-utility mdi-flip-h',
      name: i18n.t('schedule.manifest.pullIn'),
      strId: 'pull-in',
      uuid: 'e7'
    },
    8: {
      title: i18n.t('dispatch.manifest.pickupAppt'),
      icon: 'van-utility mdi-flip-h',
      strId: 'pull-in',
      uuid: 'e8'
    }
  }[id],
  ...{ delivery_event_type_id: id }
});

export const _eventStatus = (id, tryies) => ({
  ...{
    0: {
      icon: 'currency-usd',
      name: i18n.t('global.copayment'),
      strId: 'copayment',
      uuid: 's0',
      actionMsg: i18n.t('dispatch.status.paymentCollectedBy')
    },
    1: {
      icon: 'clock-outline',
      name: i18n.t('global.scheduled'),
      strId: 'scheduled',
      uuid: 's1',
      actionMsg: i18n.t('dispatch.status.scheduledBy')
    },
    2: {
      icon: 'send',
      name: i18n.t('global.inProgress'),
      strId: 'in-progress',
      uuid: 's2',
      actionMsg: i18n.t('dispatch.status.markedInProgress')
    },
    3: {
      icon: 'alert-circle-outline',
      name: `${i18n.t('global.attempted')}${
        tryies ? `: ${i18n.tc('global.nTries', tryies, [tryies])}` : ''
      }`,
      strId: 'attempted',
      uuid: 's3',
      actionMsg: i18n.t('dispatch.status.attemptedBy')
    },
    4: {
      icon: 'check-circle-outline',
      name: i18n.tc('global.successful'),
      strId: 'successful',
      uuid: 's4',
      actionMsg: i18n.t('dispatch.status.succesfullyDelivered')
    },
    5: {
      icon: 'sort-clock-ascending-outline',
      name: i18n.t('global.reScheduled'),
      strId: 're-scheduled',
      uuid: 's5',
      actionMsg: i18n.t('dispatch.status.rescheduledBy')
    },
    6: {
      icon: 'cancel',
      name: i18n.tc('global.rejected'),
      strId: 'rejected',
      uuid: 's6',
      actionMsg: i18n.t('dispatch.status.rejectedBy')
    },
    7: {
      icon: 'cancel',
      name: i18n.tc('global.canceled'),
      strId: 'canceled',
      uuid: 's7',
      actionMsg: i18n.t('dispatch.status.canceledBy')
    },
    8: {
      icon: 'package-variant',
      name: i18n.t('global.pickedUp'),
      strId: 'picked-up',
      uuid: 's8',
      actionMsg: i18n.t('dispatch.status.pickedUpBy')
    },
    9: {
      icon: 'help-box',
      name: i18n.t('global.missing'),
      strId: 'missing',
      uuid: 's9',
      actionMsg: i18n.t('dispatch.status.markedMissingBy')
    },
    10: {
      icon: 'image-broken-variant',
      name: i18n.tc('global.damaged'),
      strId: 'damaged',
      uuid: 's10',
      actionMsg: i18n.t('dispatch.status.markedDamagedBy')
    },
    11: {
      icon: 'thumb-down',
      name: i18n.t('global.failed'),
      strId: 'failed',
      uuid: 's11',
      actionMsg: i18n.t('dispatch.status.markedFailedBy')
    },
    12: {
      icon: 'clock-outline',
      name: i18n.t('global.scheduled'),
      strId: 'scheduled',
      uuid: 's12',
      actionMsg: i18n.t('dispatch.status.scheduledBy')
    },
    13: {
      icon: 'send',
      name: i18n.t('global.inProgress'),
      strId: 'in-progress',
      uuid: 's13',
      actionMsg: i18n.t('dispatch.status.markedInProgress')
    },
    14: {
      icon: 'alert-circle-outline',
      name: `${i18n.t('global.attempted')}${
        tryies ? `: ${i18n.tc('global.nTries', tryies, [tryies])}` : ''
      }`,
      strId: 'attempted',
      uuid: 's14',
      actionMsg: i18n.t('dispatch.status.attemptedBy')
    },
    15: {
      icon: 'sort-clock-ascending-outline',
      name: i18n.t('global.reScheduled'),
      strId: 're-scheduled',
      uuid: 's15',
      actionMsg: i18n.t('dispatch.status.rescheduledBy')
    },
    16: {
      icon: 'cancel',
      name: i18n.tc('global.canceled'),
      strId: 'canceled',
      uuid: 's16',
      actionMsg: i18n.t('dispatch.status.canceledBy')
    },
    17: {
      icon: 'thumb-down',
      name: i18n.t('global.failed'),
      strId: 'failed',
      uuid: 's17',
      actionMsg: i18n.t('dispatch.status.markedFailedBy')
    },
    18: {
      icon: 'inbox-arrow-down',
      name: i18n.t('global.collected'),
      strId: 'successful',
      uuid: 's18',
      actionMsg: i18n.t('dispatch.status.collectedBy')
    },
    19: {
      icon: 'clipboard-arrow-left',
      name: i18n.t('global.returned'),
      strId: 'failed',
      uuid: 's19',
      actionMsg: i18n.t('dispatch.status.returnedBy')
    }
  }[id],
  ...{ delivery_event_status_id: id }
});

export const eventStatusAsEventType = [2, 4, 5, 6];

export const getTypeData = ({ id, useEventTypes, tryies }) => {
  const defaultStatus = { icon: '', name: `--MISSING_STATUS(${id})--`, strId: '', uuid: 'M' };
  if (useEventTypes) return _eventTypes(id) || defaultStatus;
  else return _eventStatus(id, tryies) || defaultStatus;
};

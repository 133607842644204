<template>
  <Modal
    :buttonLabel="$t('global.continue')"
    :buttonLabelClose="$t('global.cancel')"
    :header="$t('confirms.unassignDelivery')"
    :isOpen.sync="isModalOpen"
    :loading="loading"
    :saveDisabled="!saveDisabled"
    @cancel="() => {}"
    @save="onUnassign"
    id="unassignDeliveryModal"
    size="is-medium"
  >
    <MessageBadge type="is-danger" class="message-badge">
      <p v-html="label" />
    </MessageBadge>
    <div class="scroll-verification">
      <HistoryInformation :eventsData="oData" :oDelivery="delivery" />
      <b-checkbox v-model="saveDisabled">
        {{ $t('global.iAgreeProceed') }}
      </b-checkbox>
    </div>
  </Modal>
</template>

<script>
import { Modal, MessageBadge } from '@/components';
import HistoryInformation from './HistoryInformation';

export default {
  components: {
    Modal,
    MessageBadge,
    HistoryInformation
  },
  data() {
    return {
      isModalOpen: this.isOpen,
      isScrollComplete: false,
      loading: { save: false, get: true },
      oData: {},
      oDelivery: {},
      saveDisabled: false
    };
  },
  computed: {
    label() {
      return `
        <strong class="mr-1">${this.$tc('global.warning', 1)}:</strong>
        ${this.$t('schedule.unassignDelivery.delete.lighter')}
        <strong class="ml-1">${this.$t('schedule.unassignDelivery.delete.bolder')}</strong>
      `;
    }
  },
  methods: {
    async onUnassign() {
      this.loading.save = true;
      const { id, delivery_manifest_id, order_number } = this.delivery;
      const api = `delivery_manifest/${delivery_manifest_id}/delivery_events/${id}/override`;

      try {
        await this.Api.delete(api);
        this.$emit('executeDesignation', this.delivery);
        this.$toast(
          'success',
          this.$tc('schedule.messages.unassigned', 1, [`#${order_number}`]),
          5000
        );
      } catch (error) {
        console.error(error);
      }

      this.onClear();
      this.isModalOpen = false;
      this.loading.save = false;
    },
    async getHistory() {
      this.loading.get = true;
      try {
        const { data } = await this.Api.get(
          `delivery_manifest/${this.delivery.delivery_manifest_id}/delivery_events/${this.delivery.id}/life_cycle`
        );
        this.oData = data;
      } catch (error) {
        console.error(error);
      }
      this.loading.get = false;
    },
    onClear() {
      this.isScrollComplete = false;
      this.oData = {};
      this.oDelivery = {};
      this.saveDisabled = false;
    }
  },
  watch: {
    isOpen(value) {
      this.isModalOpen = value;
      if (value) this.getHistory();
      else this.onClear();
    },
    isModalOpen(value) {
      this.$emit('update:isOpen', value);
    }
  },
  props: {
    delivery: { type: Object, require: true, default: () => {} },
    isOpen: { type: Boolean, default: false },
    onlyRequiereConf: { type: Boolean, default: false }
  }
};
</script>

<style lang="sass" scoped>
#unassignDeliveryModal
  .message-badge
    p
      display: flex
    ::v-deep .message-content
      margin-bottom: 1rem
      max-width: 100%
      strong
        margin: 0
        color: $red-800
      strong:nth-child(1)
        text-transform: uppercase
        margin: 0
</style>

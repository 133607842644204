import { render, staticRenderFns } from "./_link.vue?vue&type=template&id=2974e850&scoped=true&"
import script from "./_link.vue?vue&type=script&lang=js&"
export * from "./_link.vue?vue&type=script&lang=js&"
import style0 from "./_link.vue?vue&type=style&index=0&id=2974e850&lang=sass&scoped=true&"
import style1 from "./_link.vue?vue&type=style&index=1&lang=sass&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "2974e850",
  null
  
)

export default component.exports
import CDP from './CDP';
import Employees from './Employees';
import Participants from './Participants';
import Public from './Public';
import Runs from './Runs';
import Settings from './Settings';
import Schedule from './Schedule';
import Vehicles from './Vehicles';
import ALF from './ALF_SNF';
import { Permissions } from '@/utils/Secure';
const { AdvancedSettings, Dispatch, Rol_Permissions, ActivityLogger } = Permissions;
export default [
  {
    alias: '/data',
    component: () => import(/* webpackChunkName: "home" */ '@/views/Home.vue'),
    inMenu: true,
    meta: {
      i18n: 'menu.hom',
      icon: 'home',
      // isParent: true,
      secure: { permission: null, redirect: null },
      type: 'route',
      title: 'Home'
    },
    name: 'home',
    path: '/'
  },
  {
    inMenu: true,
    meta: {
      i18n: 'menu.dat',
      icon: 'database',
      isParent: true,
      items: ['participants', 'runs', 'vehicles', 'employees', 'locations', 'cdps', 'alf_snf'],
      secure: { permission: null, redirect: null },
      type: 'section'
    },
    name: 'data',
    path: '/data',
    redirect: '/data/participants'
  },
  ...ALF,
  ...CDP,
  ...Employees,
  ...Participants,
  ...Runs,
  ...Schedule,
  ...Settings,
  ...Vehicles,
  ...Public,
  {
    inMenu: true,
    component: () => import(/* webpackChunkName: "locations" */ '@/views/Home'),
    meta: {
      i18n: 'menu.loc',
      permissionId: 0,
      secure: { permission: {}, redirect: null }
    },
    name: 'locations',
    path: '/data/locations'
  },
  {
    inMenu: true,
    component: () => import(/* webpackChunkName: "dispatch" */ '@/views/Dispatch/'),
    meta: {
      i18n: 'menu.dis',
      icon: 'bus-clock',
      isParent: true,
      secure: { permission: Dispatch.readAllCurrent, redirect: null },
      title: 'Dispatch'
    },
    name: 'dispatch',
    path: '/dispatch'
  },
  {
    inMenu: true,
    component: () => import(/* webpackChunkName: "dispatch" */ '@/views/Dispatch/DeliveryIssues'),
    meta: {
      title: 'Delivery issues',
      secure: { permission: Dispatch.viewIssues, redirect: null }
    },
    name: 'dispatch-issues',
    path: '/dispatch/issues'
  },
  {
    inMenu: true,
    component: () => import(/* webpackChunkName: "deliveryLog" */ '@/views/Dispatch/DeliveryLog'),
    meta: {
      title: 'Change log',
      secure: { permission: ActivityLogger.readAll, redirect: null }
    },
    name: 'change-log',
    path: '/change-log/:changeLog'
  },
  {
    inMenu: true,
    component: () => import(/* webpackChunkName: "fueling" */ '@/views/Home/'),
    meta: {
      i18n: 'menu.fue',
      icon: 'gas-station',
      isParent: true,
      secure: { permission: {}, redirect: null },
      title: 'Fueling'
    },
    name: 'fueling',
    path: '/fueling'
  },
  {
    inMenu: true,
    component: () =>
      import(/* webpackChunkName: "roles-permissions" */ '@/views/AdvancedSettings/index'),
    meta: {
      i18n: 'menu.set',
      icon: 'cog',
      isParent: true,
      title: 'Advanced Settings',
      secure: { permission: AdvancedSettings.read, redirect: null }
    },
    name: 'advanced-settings',
    path: '/advanced-settings'
    // redirect: '/advanced-settings/roles-permissions'
  },
  {
    component: () =>
      import(/* webpackChunkName: "roles-permissions" */ '@/views/AdvancedSettings/index'),
    meta: {
      current: 1,
      secure: { permission: Rol_Permissions.readAll, redirect: null }
    },
    name: 'advanced-settings/roles-permissions',
    path: '/advanced-settings/roles-permissions'
  },
  {
    component: () =>
      import(
        /* webpackChunkName: "roles-permissions" */ '@/views/AdvancedSettings/Steps/RolesPermissions/newRole'
      ),
    meta: { current: 2, secure: { permission: AdvancedSettings.read, redirect: null } },
    name: 'advanced-settings/roles-permissions/add',
    path: '/advanced-settings/roles-permissions/add'
  },
  {
    component: () =>
      import(/* webpackChunkName: "delivery-settings" */ '@/views/AdvancedSettings/index'),
    meta: { current: 3, secure: { permission: AdvancedSettings.read, redirect: null } },
    name: 'advanced-settings/delivery-settings',
    path: '/advanced-settings/delivery-settings'
  },
  {
    component: () =>
      import(/* webpackChunkName: "app-settings" */ '@/views/AdvancedSettings/index'),
    meta: {
      secure: { permission: AdvancedSettings.read, redirect: null }
    },
    name: 'advanced-settings/app-settings',
    path: '/advanced-settings/app-settings'
  },
  {
    component: () => import(/* webpackChunkName: "dashborads" */ '@/views/Home/'),
    meta: {
      i18n: 'menu.das',
      icon: 'view-dashboard',
      isParent: true,
      secure: { permission: null, redirect: null },
      title: 'Dashboards'
    },
    name: 'dashboards',
    path: '/dashboards'
  },
  {
    inMenu: true,
    component: () => import(/* webpackChunkName: "reports" */ '@/views/Home/'),
    meta: {
      i18n: 'menu.rep',
      icon: 'folder-text',
      isParent: true,
      secure: { permission: {}, redirect: null },
      title: 'Reports'
    },
    name: 'reports',
    path: '/'
  }
];

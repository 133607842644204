<template>
  <span></span>
</template>

<script>
// import { clientId, clientSecret, server, username, password, extension } from './constants';
// import { clientId, server } from '@/utils/ringCentral/constants.js';
// const appKey = clientId;
export default {
  mounted() {
    this.generateScript();
  },
  methods: {
    generateScript() {
      var rcs = document.createElement('script');
      rcs.src = `https://ringcentral.github.io/ringcentral-embeddable-voice/adapter.js`;
      // rcs.src = `https://ringcentral.github.io/ringcentral-web-widget/adapter.js?appKey=${appKey}&appServer=${server}&redirectUri=https://ringcentral.github.io/ringcentral-web-widget/redirect.html";`;
      var rcs0 = document.getElementsByTagName('script')[0];
      rcs0.parentNode.insertBefore(rcs, rcs0);
      if (window.RCAdapter) {
        window.RCAdapter.setMinimized(true);
      }
    }
  }
};
</script>

<style lang="sass" scoped></style>

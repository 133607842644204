<template>
  <div class="cardPicture">
    <Picture :src="src" :preasigned="preasigned" :alt="alt" />
  </div>
</template>

<script>
import Picture from './Picture.vue';
export default {
  components: {
    Picture
  },
  computed: {
    preasigned() {
      const { api, id } = this;
      return api && id ? { apiUrl: `/presigned/${api}`, fileName: `${id}.png` } : {};
    }
  },
  props: {
    api: { type: String, default: '' },
    id: { type: Number, default: null },
    alt: { type: String, default: null },
    src: { type: String, default: '' }
  }
};
</script>

<style lang="sass" scoped>
.cardPicture
  min-width: 200px
  width: 200px
  height: 200px
  border-radius: $br-md
  overflow: hidden
  margin-right: 36px
  @media screen and ( max-width: $bp-xxl )
    min-width: 120px
    width: 120px
    height: 120px
  .b-image-wrapper
    margin: 0
</style>

<template>
  <div class="picturePicker">
    <Cropper v-if="pick" class="cropper" @change="onChange" :maxSize="600" copyPaste>
      <slot name="cropper" />
    </Cropper>
    <template v-else>
      <Picture
        class="preview"
        :preasigned="preasigned"
        :rounded="rounded"
        @load="onLoad"
        @error="onError"
      />
      <div v-if="loaded" @click="onEdit" class="pp-control">
        <Tooltip :label="$t('button.pictureChange')" appendToBody>
          <b-icon class="c-icon" icon="square-edit-outline" />
        </Tooltip>
      </div>
    </template>
  </div>
</template>

<script>
import Cropper from '@/components/Cropper.vue';
import Picture from '@/components/Picture.vue';
import { Tooltip } from '@/components';
import { uploadFile } from '@/utils/Presigned';

export default {
  components: {
    Cropper,
    Picture,
    Tooltip
  },
  data() {
    return {
      file: null,
      loaded: false,
      pick: this.pickMode,
      updated: false
    };
  },
  methods: {
    onEdit() {
      this.pick = true;
    },
    onError() {
      this.pick = true;
    },
    onLoad() {
      this.loaded = true;
    },
    onReset() {
      this.pick = true;
    },
    onChange({ file }) {
      this.file = file;
      this.updated = true;
      this.$emit('onImageChange', true);
    },
    async save({ apiUrl, fileName } = {}) {
      if (this.updated)
        await uploadFile({
          fileName: fileName || this.preasigned.fileName,
          apiUrl: apiUrl || `${this.preasigned.apiUrl}/upload`,
          file: this.file
        });
      this.updated = false;
    }
  },
  watch: {
    pick(value) {
      this.$emit('pickMode', value);
    }
  },
  props: {
    preasigned: { type: Object, default: () => ({ apiUrl: null, fileName: null }) },
    rounded: { type: Boolean, default: false },
    pickMode: { type: Boolean, default: true }
  }
};
</script>

<style lang="sass" scoped>
.picturePicker
  position: relative
  border-radius: $br-md
  overflow: hidden
  .cropper
    width: 100%
    height: 100%
  .pp-control
    position: absolute
    right: 10px
    bottom: 5px
    z-index: 2
    color: white
    cursor: pointer
    transition: all .3s ease
    text-shadow: 0 0 10px rgba(0, 0, 0, .3)
    &:hover
      text-shadow: 0 0 10px rgba(0, 0, 0, .7)
</style>

<template>
  <div>
    <!-- Update to field when possible -->
    <Field
      :label="label"
      class="myInput"
      v-model="password"
      validation-message=" "
      :placeholder="placeholder"
      password-reveal
      required
      type="password"
      :upper="false"
    />
    <div class="passwordRules">
      <div v-for="rule in rules" :key="rule.id" :class="rule.class" class="flex">
        <div class="icon-container">
          <b-icon v-if="rule.isValid" icon="check-decagram" />
          <b-icon v-else-if="rule.isValid === false" icon="alert-decagram" />
          <b-icon v-else icon="circle-medium" />
        </div>
        {{ rule.text }}
      </div>
    </div>
    <br />
  </div>
</template>

<script>
import { Field } from '@/components';

export default {
  components: {
    Field
  },
  data() {
    return {
      password: ''
    };
  },
  computed: {
    isMinLengthValid() {
      return this.data;
    },
    doesNotUseSpaces() {
      const { password } = this;
      let isValid = null;
      if (password) isValid = !/ /.test(password);
      return {
        id: 'ns',
        text: this.$tc('password.validator.noBlankSpaces', 1, [this.minLength]),
        ...this.validatorHelper(isValid)
      };
    },
    minLengthRule() {
      const { password } = this;
      let isValid = null;
      if (password) isValid = /[\W\w\d]{8}/.test(password);
      return {
        id: 'ml',
        text: this.$tc('password.validator.minLength', 1, [this.minLength]),
        ...this.validatorHelper(isValid)
      };
    },
    useLowercaseRule() {
      const { password } = this;
      let isValid = null;
      if (password) isValid = /[a-z]/.test(password);
      return {
        id: 'lc',
        text: this.$t('password.validator.lower'),
        ...this.validatorHelper(isValid)
      };
    },
    useNumbersRule() {
      const { password } = this;
      let isValid = null;
      if (password) isValid = /[0-9]/.test(password);
      return {
        id: 'un',
        text: this.$t('password.validator.numbers'),
        ...this.validatorHelper(isValid)
      };
    },
    useSpecialCharsRule() {
      const { password } = this;
      let isValid = null;
      if (password) isValid = /[!"#$%&'()*+,-./\\:;<=>?@[\]^_`{|}~Ññ]/.test(password);
      return {
        id: 'us',
        text: this.$t('password.validator.special'),
        ...this.validatorHelper(isValid)
      };
    },
    useUppercaseRule() {
      const { password } = this;
      let isValid = null;
      if (password) isValid = /[A-Z]/.test(password);
      return {
        id: 'uc',
        text: this.$t('password.validator.upper'),
        ...this.validatorHelper(isValid)
      };
    },
    rules() {
      const rules = [];
      if (this.minLength) rules.push(this.minLengthRule);
      if (this.useLowercase) rules.push(this.useLowercaseRule);
      if (this.useNumbers) rules.push(this.useNumbersRule);
      if (this.useSpecialChars) rules.push(this.useSpecialCharsRule);
      if (this.useUppercase) rules.push(this.useUppercaseRule);
      if (this.noBlankSpaces) rules.push(this.doesNotUseSpaces);
      return rules;
    },
    passwordStatus() {
      const pendingRules = this.rules.filter((r) => !r.isValid);
      const invalidRules = pendingRules.filter((r) => r.isValid === false);
      return { hasErrors: !!invalidRules[0], isValid: !pendingRules[0] };
    }
  },
  methods: {
    onInput(value) {
      this.password = value;
    },
    validatorHelper(isValid) {
      return { class: isValid === null ? '' : isValid ? 'valid' : 'invalid', isValid };
    }
  },
  watch: {
    passwordStatus(value) {
      this.$emit('input', value.isValid ? this.password : '');
    }
  },
  props: {
    label: { type: String, default: '' },
    minLength: { type: Number, default: 8 },
    noBlankSpaces: { type: Boolean, default: true },
    placeholder: { type: String, default: '' },
    useLowercase: { type: Boolean, default: true },
    useNumbers: { type: Boolean, default: true },
    useSpecialChars: { type: Boolean, default: true },
    useUppercase: { type: Boolean, default: true }
  }
};
</script>

<style lang="sass" scoped>
.passwordRules
  font-size: $f-sm
  .valid
    color: $green-700
  .invalid
    color: $red-500
  .icon-container
    display: flex
    align-items: center
    width: 20px
    .icon
      line-height: 14px
      height: 14px
      ::v-deep
        i::before
          font-size: 14px
.myInput
  ::v-deep
    .icon.is-right
      width: 2em
      i::before
        font-size: 18px
</style>

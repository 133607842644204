var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{attrs:{"id":"transferRxModal"}},[_c('Modal',{attrs:{"isOpen":_vm.isModalOpen,"header":_vm.$t('dispatch.transferRx.header'),"loading":_vm.loading,"size":"is-medium","buttonLabel":_vm.$t('global.transfer')},on:{"update:isOpen":function($event){_vm.isModalOpen=$event},"update:is-open":function($event){_vm.isModalOpen=$event},"cancel":function () {},"save":_vm.onSubmit}},[_c('form',{ref:"form",on:{"submit":function($event){$event.preventDefault();return _vm.onSubmit.apply(null, arguments)}}},[_c('b-field',[_c('Autocomplete',{attrs:{"label":_vm.$t('global.selectDriver'),"placeholder":_vm.$t('global.select'),"api":{
            url: '/employees/drivers-on-call',
            full: true
          },"field":"full_name","model":"employee_id","filteredByModel":[_vm.from],"keepFirst":false,"required":""},scopedSlots:_vm._u([{key:"results",fn:function(ref){
          var data = ref.data;
return [_c('div',{staticClass:"flex f-jsb"},[_c('p',[_vm._v(_vm._s(data.full_name))]),_c('DeliveryStatusSignUI',{staticClass:"driver-status",attrs:{"delivery":{
                  delivery_event_status_id: _vm.checktype(data.status),
                  event_type_is_mandatory: 0
                }}})],1)]}}]),model:{value:(_vm.asignedDriverId),callback:function ($$v) {_vm.asignedDriverId=$$v},expression:"asignedDriverId"}})],1)],1)])],1)}
var staticRenderFns = []

export { render, staticRenderFns }
<template>
  <div class="empty flex f-ai f-jc f-column" :class="isCompact ? 'is-compact' : ''">
    <template v-if="!isCompact">
      <b-icon :icon="icon" custom-class="e-icon" custom-size="mdi-36px" />
      <strong class="e-title">{{ title || $t('global.emptyLabel') }}</strong>
    </template>
    <div class="e-text">{{ text || $t('global.isEmptyText') }}</div>
  </div>
</template>

<script>
export default {
  components: {},
  props: {
    icon: { type: String, default: 'information-outline' },
    isCompact: { type: Boolean, default: false },
    title: { type: String },
    text: { type: String }
  }
};
</script>

<style lang="sass" scoped>
.empty
  height: 100%
  width: 100%
  padding: 20px
  margin: 0 auto
  border-radius: $br-sm
  background: white
  border: 1px solid $gray-200
  &.is-compact
    padding: 5px
    margin: 5px 5%
    width: 90%
    font-size: 0.85em
  .icon
    margin-bottom: 20px
  .e-title
    margin-bottom: 5px
  .e-text
    text-align: center
  ::v-deep
    .e-icon
      font-size: 25spx
      color: $blue-300
.dark
  .empty
    background: $dark-500
    border: 1px solid $gray-700
    .e-title
      color: $main-background
    .e-text
      color: $gray-500
</style>

<template>
  <Modal
    id="addOnModal"
    size="is-medium"
    :isOpen.sync="isModalOpen"
    :header="$t('dispatch.addOn')"
    :loading="loading"
    :buttonLabel="$t('button.confirm')"
    @cancel="() => {}"
    @save="onSubmit"
  >
    <form ref="form" @submit.prevent="onSubmit">
      <p class="subTitle">{{ $t('participant.infoLabel') }}</p>
      <Autocomplete
        :label="$t('modal.name')"
        :api="{
          url: 'participants',
          query: 'participant_full_name',
          params: [{ id: 'is_active', value: 1 }]
        }"
        v-model="formData.participant_full_name"
        model="id"
        field="participant_full_name"
        @select="onSelectParticipant"
        @primitiveInput="onInput"
        :selectOnBlur="false"
        :keepFirst="false"
        required
      >
        <template #results="{ data }">
          <div class="w-100">
            <strong>{{ data.participant_full_name }}</strong>
            <p>{{ data.participant_address_full_address }}</p>
          </div>
        </template>
      </Autocomplete>

      <p class="subTitle">{{ $t('schedule.delivery') }}</p>

      <Field
        :label="$t('global.orderNumber')"
        :maxlength="15"
        type="Text"
        v-model="formData.tracking_number"
        required
      />
      <Autocomplete
        :label="$t('address.address', [])"
        :data="addresses"
        field="full_address"
        model="id"
        required
        v-model="formData.participant_address_id"
        :key="`address-${keyId}`"
        @select="
          (id) => {
            if (id) formData.participant_address_id = id;
          }
        "
        keep-first
      />
      <p class="subTitle">{{ $t('dispatch.pickupAddressCDP') }}</p>
      <Autocomplete
        :label="$t('menu.cdp')"
        :api="{
          url: 'cdp',
          query: 'name',
          full: true
        }"
        field="name"
        model="id"
        required
        v-model="formData.close_door_pharmacy_id"
        disabled
        :loading="loading.manifest"
        keep-first
      />
      <Field
        :label="$tc('global.comment', 0)"
        type="textarea"
        v-model="formData.comments"
        :upper="false"
        :placeholder="$t('global.placeholder.deliveryComment')"
        :rows="1"
      />
      <div class="addOn-sequence">
        <p class="subTitle">{{ $t('dispatch.deliverySequence') }}</p>
        <div class="flex f-jsa">
          <div>
            <iSwitch class="mt-4" v-model="priority" :text="$t('global.rush')" />
            <iSwitch
              class="mt-5 mb-4"
              v-model="hasRestriction"
              :text="$t('deliveries.restriction')"
            />
          </div>
          <iSwitch
            class="mt-5"
            v-model="formData.refrigeration_needed"
            :text="$t('global.refrigerated')"
          />
        </div>
        <div class="date-constraint">
          <DateConstraint
            :hasRestriction="hasRestriction ? true : false"
            @change="onDeliveryTimesChange"
          />
        </div>
      </div>
    </form>
  </Modal>
</template>

<script>
import { Autocomplete, Field, Modal, iSwitch, DateConstraint } from '@/components';
import { toast, confirm } from '@/utils/dialog';

export default {
  components: {
    Autocomplete,
    Field,
    iSwitch,
    Modal,
    DateConstraint
  },
  created() {
    this.unsubscribe = this.$store.subscribe(({ type }, { dispatch: { manifest } }) => {
      if (type === 'dispatch/manifest') {
        this.storedManifestId = manifest.id;
      }
    });
  },
  destroyed() {
    this.unsubscribe();
  },
  data() {
    return {
      hasRestriction: null,
      deliveryTimes: null,
      addresses: [],
      keyId: 1,
      formData: { delivery_sequence: null },
      isModalOpen: this.isOpen,
      loading: { save: false, get: false, addresses: false, manifest: false },
      storedManifestId: null,
      participantId: null,
      unsubscribe: null,
      priority: false
    };
  },
  computed: {
    cManifestId() {
      return this.manifestId || this.storedManifestId;
    }
  },

  methods: {
    async getParticipant() {
      this.loading.participant = true;
      try {
        const { data } = await this.Api.get(`participants/${this.participantId}`);
        this.setParticipantInfo(data);
      } catch (error) {
        console.log(error);
      }
      this.loading.participant = false;
    },
    commit() {
      if (this.isFromMap) {
        this.$store.commit('map/response', {
          type: 'dispatch/updateManifestEvents',
          manifestId: this.cManifestId
        });
      } else {
        this.$store.commit('dispatch/updateManifestEvents');
      }
    },
    async onAddOnDelivery(isOverride, body) {
      this.loading.save = true;
      try {
        await this.Api.post(
          `delivery_manifest/${this.cManifestId}/delivery_events_addon${
            isOverride ? '_override' : ''
          }`,
          body
        );
        toast('success', this.$t('messages.saved'), 5000);
        this.commit();
        if (this.isModalOpen) this.isModalOpen = false;
        this.hasRestriction = false;
        this.deliveryTimes = null;
        this.priority = false;
      } catch (error) {
        if (error.data.status_code === 'OVERTIME_WORK') {
          confirm({
            title: this.$t('confirms.continue'),
            message: this.$t('schedule.confirm.overtime'),
            cancelText: this.$t('confirm.no'),
            confirmText: this.$t('confirm.yes'),
            onConfirm: async () => {
              this.onAddOnDelivery(true, body);
            }
          });
        }
      }
      this.loading.save = false;
    },
    onInput(text) {
      if (!text) {
        this.formData.participant_address_id = '';
        this.keyId += 1;
      }
    },
    onSelectParticipant(id) {
      if (id) {
        this.participantId = id;
        this.getParticipant();
        this.getAddresses();
      }
    },
    setParticipantInfo(participant) {
      const tracking_number = this.formData.tracking_number;
      const close_door_pharmacy_id = this.formData.close_door_pharmacy_id;
      this.formData = {
        ...participant,
        delivery_sequence: null,
        tracking_number,
        close_door_pharmacy_id
      };
    },
    prepareData() {
      const { formData: form, priority, deliveryTimes, hasRestriction } = this;
      const { delivery_start_time, delivery_end_time } = hasRestriction ? deliveryTimes : {};
      return {
        participant_id: this.participantId,
        delivery_event_type_id: 3,
        // close_door_pharmacy_id: form.close_door_pharmacy_id,
        destination_id: form.participant_address_id,
        comments: form.comments || '',
        tracking_number: form.tracking_number,
        secuence: form.delivery_sequence === 'place' ? form.sequence_place : form.delivery_sequence,
        refrigeration_needed: form.refrigeration_needed || 0,
        delivery_end_time: delivery_end_time || null,
        delivery_start_time: delivery_start_time || null,
        priority: priority ? 'high' : 'normal'
      };
    },
    async onSubmit() {
      if (this.validate()) {
        const pData = this.prepareData();
        this.onAddOnDelivery(false, pData);
      }
    },
    validate() {
      let htmlValidator = false;
      let dateValidation = false;
      if (this.$refs.form) {
        htmlValidator = this.$refs.form.checkValidity();
        if (!htmlValidator) this.$refs.form.reportValidity();
      }

      if (this.hasRestriction) {
        if (this.deliveryTimes) {
          dateValidation = true;
        } else {
          toast('warning', this.$t('messages.deadlines'), 5000);
        }
      } else {
        dateValidation = true;
      }

      return htmlValidator && dateValidation;
    },
    async getAddresses() {
      this.loading.addresses = true;
      this.addresses = [];
      try {
        const { data } = await this.Api.get(`participants/${this.participantId}/addresses`);
        this.addresses = data;
      } catch (error) {
        console.error(error);
      }
      this.loading.addresses = false;
    },
    async getManifest() {
      if (this.manifestId) {
        try {
          this.loading.manifest = true;
          const { data } = await this.Api.get(`/delivery_manifest/${this.manifestId}`);
          this.formData.close_door_pharmacy_id = await data.close_door_pharmacy_id;
          this.loading.manifest = false;
        } catch (error) {
          console.error(error);
        }
      }
    },
    onDeliveryTimesChange(times) {
      this.deliveryTimes = times;
    }
  },
  watch: {
    isOpen(value) {
      this.isModalOpen = value;
    },
    async isModalOpen(value) {
      this.addresses = [];
      await this.getManifest();
      this.formData = {
        delivery_sequence: null,
        close_door_pharmacy_id: this.formData.close_door_pharmacy_id
      };
      this.$emit('update:isOpen', value);
    },
    priority(val) {
      console.log(val);
    }
  },
  props: {
    isOpen: { type: Boolean, default: false },
    isFromMap: { type: Boolean, default: false },
    manifestId: { type: Number, default: 1 }
  }
};
</script>

<style lang="sass" scoped>
#addOnModal
  .subTitle
    font-size: $f-lg
    font-weight: bold
    margin-bottom: 1rem
  .addOn-place
    width: calc(50% - .75rem)
  .addOn-sequence
    margin: 1.5rem 0 1.5rem 0
  .show
    display: flex
  .hide
    display: none
  ::v-deep
    .field .is-grouped
      flex-wrap: wrap
      justify-content: space-between
      .field
        margin-bottom: 10px
      .input
        padding-left: 0.75rem !important
    .autocomplete .dropdown-menu
      width: 100%
      p
        white-space: nowrap
        overflow: hidden
        text-overflow: ellipsis
</style>

<template>
  <Link
    :to="to"
    class="router-link"
    :class="myClass"
    :is="routerIs"
    @click="onClick"
    :isRouter="routerIs === 'Link'"
    :permission="permission"
  >
    <b-icon v-if="icon" class="icon" :icon="icon" />
    <span class="text">
      {{ $t(i18n) }}
    </span>
    <span class="counter" v-if="counter">{{ counter }}</span>
    <slot />
  </Link>
</template>
<script>
import { Link } from '@/components';

export default {
  components: {
    Link
  },
  computed: {
    myClass() {
      const inSection = this.inSection ? 'inSection ' : '';
      const active = this.active ? 'active ' : '';
      if (this.$route.path == '/' && this.i18n == 'menu.hom') {
        return inSection + 'is-active';
      } else if (this.to == '/') {
        return 'not-active';
      }
      return inSection + active;
    }
  },
  methods: {
    onClick() {
      this.$emit('click');
    }
  },
  props: {
    active: { type: Boolean, default: false },
    counter: { type: Number, default: 0 },
    i18n: { type: String, default: '' },
    icon: { type: String, default: '' },
    routerIs: { type: String, default: 'Link' },
    inSection: { type: Boolean, default: false },
    to: { type: String, default: '' },
    permission: { type: Object, default: () => {} }
  }
};
</script>

<style lang="sass" scoped>
@mixin item-border
  border-right: 4px solid $dark
  position: absolute
  height: 70%
  right: 5px
  border-radius: 5px
  content:''

.icon
  margin-right: 10px
.router-link
  display: flex
  align-items: center
  color: $gray-800!important
  background: $main-background
  height: 52px
  padding: 0 20px
  transition: $transition-dark-mode
  &.active
    font-weight: bold
    background: $gray-100
  &:hover
    position: relative
    font-weight: bold
    &::after
      @include item-border
.inSection
  background: $main-background
  height: 40px
  padding-left: 65px
  &:hover
    font-weight: bold
.text
  width: 100%
.router-link-active
  background: $gray-100
  font-weight: bold
  position: relative
.router-link-exact-active:not(.not-active)
  &::after
    @include item-border
.is-active
  background: $gray-100
  font-weight: bold
  position: relative
.not-active
  background: inherit
  font-weight: inherit
  position: inherit
.counter
  margin-left: 10px
  background: red
  padding: 0 13px
  border-radius: 30px
</style>

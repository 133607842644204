<template>
  <SlickList
    :style="draggableListStyle"
    class="draggable-list"
    v-model="items"
    :helperClass="isDarkMode ? 'draggable-active-dark' : 'draggable-active'"
    @sort-end="onSortEnd"
    :pressDelay="200"
    lockAxis="y"
  >
    <SlickItem
      class="draggable-item"
      :class="disabledClass(item)"
      v-for="(item, index) in items"
      :index="index"
      :key="index"
      :disabled="disabled || loading"
    >
      <b-progress v-if="!!loadingClass(index)" type="is-primary" />
      <slot v-if="!!$scopedSlots.default" v-bind="{ ...item, index }" />
      <template v-else>
        {{ item[field] || item }}
      </template>
    </SlickItem>
  </SlickList>
</template>

<script>
import { SlickList, SlickItem } from 'vue-slicksort';
export default {
  components: {
    SlickList,
    SlickItem
  },
  data() {
    return {
      currentIndex: null,
      items: this.value,
      itemsBackup: this.value,
      loading: false
    };
  },
  computed: {
    draggableListStyle() {
      const total = this.items.length;
      return total < 3 ? 'overflow:visible' : '';
    },
    isDarkMode() {
      return this.$store.getters.DARK;
    }
  },
  methods: {
    disabledClass(item) {
      const { field, value } = this.disabledItem || {};
      const aValue = typeof value != 'object' ? [value] : value;
      let isDisabled = false;
      if (field && value) {
        isDisabled = aValue.indexOf(item[field]) >= 0;
      }
      if (this.disabled) isDisabled = true;
      return isDisabled ? 'draggable-disabled' : '';
    },
    loadingClass(index) {
      return this.currentIndex === index && this.loading ? 'is-loading' : '';
    },
    onSortEnd({ newIndex, oldIndex, ...rest }) {
      if (newIndex == oldIndex) return;
      const item = this.items[oldIndex];
      this.currentIndex = newIndex;
      this.loading = true;
      setTimeout(async () => {
        const index = newIndex;
        const result = await this?.confirmation({
          nextItem: this.items[newIndex + 1],
          prevItem: this.items[newIndex - 1],
          item,
          newIndex,
          oldIndex,
          ...rest
        });
        if (result) {
          this.$emit('sortEnd', { item, index });
          this.$emit('input', this.items);
        } else {
          this.items = this.itemsBackup;
        }
        this.loading = false;
      }, 50);
    }
  },
  watch: {
    items(value, oldValue) {
      this.itemsBackup = oldValue;
    },
    value(value) {
      this.items = value;
    }
  },
  props: {
    confirmation: { type: Function, default: () => true },
    disabled: { type: Boolean, value: false },
    disabledItem: { type: Object, default: () => ({ field: null, value: null }) },
    field: { type: String, value: null },
    value: { required: true, default: [] }
  }
};
</script>
<style lang="sass">
.draggable-active
  box-shadow: $box-shadow
  z-index: 99
.draggable-active-dark
  box-shadow: $box-shadow
  z-index: 99
  .delivery-event
    .delivery-event-heading
      background: $dark-500 !important
      color: $main-background
      .mdi-chevron-up
        color: $blue-400
    .delivery-event-collapse
      background: $gray-800 !important
      color: $main-background
      .delivery-event-body
        .deliveryStatusUI
          border-color: $gray-500
          background: $gray-500
        .refrigerated-tag
          background: $blue-600
          border: none
          span
            color: $main-background
        .tooltip-trigger
          .mdi
            color: $blue-400
        .dropdown-menu
          .dropdown-content
            background: $dark !important
            a
              color: $main-background !important
      .changes-history-link
        color: $blue-400
</style>
<style lang="sass" scoped>
.draggable-list
  overflow: auto!important
  .draggable-item
    user-select: none
    cursor: pointer
    position: relative
    // ::v-deep
      // a
        // pointer-events: all
  .progress-wrapper
    position: absolute
    width: 100%
    height: 100%
    margin: 0
    opacity: .5
    z-index: 2
    ::v-deep
      .progress
        border-radius: 0
        height: 100%
  .draggable-disabled
    cursor: default
</style>

export default [
  {
    component: () => import(/* webpackChunkName: "setPassword" */ '@/views/SetPassword.vue'),
    meta: {
      i18n: 'password.change',
      title: 'Change password'
    },
    name: 'changePassword',
    path: '/change-password'
  }
];

import { Permissions } from '@/utils/Secure';
const { Runs } = Permissions;
export default [
  {
    inMenu: true,
    component: () => import(/* webpackChunkName: "runs" */ '@/views/Runs/index'),
    meta: {
      i18n: 'menu.run',
      secure: { permission: Runs.readAll, redirect: null },
      title: 'Runs'
    },
    name: 'runs',
    path: '/data/runs'
  },
  {
    component: () => import(/* webpackChunkName: "runs/new" */ '@/views/Runs/Runs'),
    meta: { secure: { permission: Runs.create, redirect: null } },
    name: 'newRun',
    path: '/data/runs/add'
  },
  {
    component: () => import(/* webpackChunkName: "run/edit" */ '@/views/Runs/Runs'),
    meta: { secure: { permission: Runs.update, redirect: null } },
    name: 'run',
    path: '/data/runs/:runId(\\d+)'
  },
  {
    component: () => import(/* webpackChunkName: "run/settings" */ '@/views/Runs/Runs'),
    meta: { current: 2, secure: { permission: Runs.update, redirect: '/data/runs' } },
    name: 'runs/contacts',
    path: '/data/runs/:runId(\\d+)/settings'
  },
  {
    component: () => import(/* webpackChunkName: "run/daily-times" */ '@/views/Runs/Runs'),
    meta: { current: 3, secure: { permission: Runs.update, redirect: '/data/runs' } },
    name: 'runs/mailing',
    path: '/data/runs/:runId(\\d+)/daily-times'
  }
];

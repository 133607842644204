<template>
  <div v-if="showTime" :class="isTag ? 'tag' : null">
    <strong v-if="endTime && startTime">{{ $t('global.between') }}: </strong>
    <strong v-else-if="endTime">{{ $t('global.before') }}: </strong>
    <strong v-else-if="startTime">{{ $t('global.after') }}: </strong>
    <label class="text-wrap">{{ showTime }}</label>
  </div>
</template>

<script>
export default {
  computed: {
    showTime() {
      const startTime = this.formatTime(this.startTime);
      const endTime = this.formatTime(this.endTime);
      let timeString = '';
      if (startTime && endTime) timeString = `${startTime} - ${endTime}`;
      else if (endTime) timeString = endTime;
      else if (startTime) timeString = startTime;
      return timeString;
    }
  },
  methods: {
    formatTime(time) {
      if (!time) return;
      if (this.offset) {
        return this.$moment(time, 'HH:mm:ss').add(this.offset, 'hours').format('HH:mm');
      } else {
        return this.$moment(time, 'HH:mm:ss').format('HH:mm');
      }
    }
  },
  props: {
    startTime: { type: String, default: null },
    endTime: { type: String, default: null },
    offset: { type: Number, default: null },
    isTag: { type: Boolean, default: false }
  }
};
</script>
<style lang="sass" scoped>
span
  margin-right: 3px
.tag
  padding-top: 2px
  padding-bottom: 2px
  height: auto
  display: flex
  flex-wrap: wrap
  border-radius: $br-lg
  background: $yellow-200
  font-size: $f-sm
  label
    margin-left: 5px
</style>
<style lang="sass" scoped>
.dark
  .tag
    background: $yellow-650
    color: $white
  strong
    color: $white
</style>

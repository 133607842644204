<template>
  <div v-show="showHelperMarker"><slot /></div>
</template>
<script>
export default {
  name: 'mappr-marker',
  beforeDestroy() {
    this.marker.remove();
  },
  data() {
    return {
      showHelperMarker: false,
      marker: null
    };
  },
  computed: {
    customMarker() {
      const slots = this.$slots.default;
      return slots && slots[0]?.child?.$el;
    },
    props() {
      const props = {};
      return props;
    },
    map() {
      return this.$options.parent.map;
    }
  },
  methods: {
    init() {
      const position = this.position || { lat: 0, lng: 0 };
      const options = this.$props;
      options.element = this.customMarker;
      this.marker = new window.flightmap.Marker(options).setLngLat(position).addTo(this.map);
      this.createListenners();
    },
    createListenners() {
      if (this.draggable)
        this.marker.on('dragend', (e) => {
          const lngLat = e.target._lngLat;
          this.$emit('dragend', lngLat);
          this.map.panTo(lngLat);
        });
    },
    move(latLng) {
      if (!this.marker || !latLng) return;
      if (this.autopan) this.map.panTo(latLng);
      this.marker.setLngLat(latLng);
    },
    remove() {
      this.marker.remove();
    }
  },
  watch: {
    map() {
      if (!this.marker) this.init();
    },
    position(value) {
      this.move(value);
    }
  },
  props: {
    autopan: { type: Boolean, default: false },
    position: { type: Object, default: () => ({ lat: 0, lng: 0 }) },
    draggable: { type: Boolean, default: false }
  }
};
</script>

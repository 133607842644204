<template>
  <DeliveryStatusSignUI
    :delivery="deliveryEvent"
    :markerShowNumbers="showNumbers"
    :markerWidth="markerSize.width"
    :markerHeight="markerSize.height"
    isMapMarker
    :isCollect="deliveryEvent.delivery_event_type_id === 8"
  />
</template>

<script>
import { DeliveryStatusSignUI } from '@/components';
export default {
  components: {
    DeliveryStatusSignUI
  },
  computed: {
    markerSize() {
      let size = { height: this.markerHeigth, width: this.markerWidth };

      const { delivery_start_time, delivery_end_time } = this.deliveryEvent;
      if (delivery_start_time || delivery_end_time) {
        size = { height: 34, width: 34 };
      }
      return size;
    }
  },
  props: {
    showNumbers: { type: Boolean, default: false },
    markerWidth: { type: Number, default: 34 },
    markerHeigth: { type: Number, default: 34 },
    deliveryEvent: { type: Object, default: () => {} }
  }
};
</script>

<style lang="sass" scoped></style>

<template>
  <Field :label="label" :required="required">
    <div class="color-picker-container input">
      <p class="placeholder">
        {{ color || placeholder || $t('global.placeholder.select', [$tc('global.color')]) }}
      </p>
      <b-icon v-if="loading" icon="loading" custom-class="mdi-spin" />
      <v-swatches
        v-else
        :trigger-style="{ width: '20px', height: '20px', borderRadius: '4px' }"
        v-model="color"
        @input="onInput"
        :swatches="swatches"
        v-bind="{ ...$props, ...$attrs }"
      />
    </div>
  </Field>
</template>

<script>
import VSwatches from 'vue-swatches';
import 'vue-swatches/dist/vue-swatches.css';
import Field from '@/components/Field.vue';

export default {
  components: { VSwatches, Field },
  mounted() {
    this.getColors();
  },
  data() {
    return {
      color: this.value,
      loading: false,
      swatches: []
    };
  },
  methods: {
    async getColors() {
      this.loading = true;
      try {
        const { data } = await this.Api.get('catalogs/colors');
        this.swatches = data.map((color) => `#${color.hex_color}`);
      } catch (err) {
        console.log(err);
      }
      this.loading = false;
    },
    onInput(input) {
      this.$emit('input', input);
    }
  },
  props: {
    label: { type: String, default: '' },
    required: { type: Boolean, default: false },
    placeholder: { type: String, default: '' },
    value: { type: String, default: '' }
  }
};
</script>

<style lang="sass" scoped>
.color-picker-container
  display: flex
  justify-content: space-between
  align-items: center
  .vue-swatches
    width: 20px
    height: 20px
  .placeholder
    color:  #64616B
    cursor: default
  ::v-deep .vue-swatches__wrapper
    width: 265px !important
</style>

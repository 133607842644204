<template>
  <Field
    :label="$t('global.copayment')"
    :validationMessage="$t('global.invalidAmount')"
    v-model="input"
    type="money"
    placeholder="0.00"
    ref="field"
    :focus="focus"
    :disabled="disabled"
  >
    <template #prefixText>USD $</template>
  </Field>
</template>

<script>
import Field from './Field.vue';
export default {
  components: {
    Field
  },
  mounted() {
    this.$refs.field.checkHtml5Validity();
  },
  data() {
    return {
      input: this.value
    };
  },
  computed: {},
  watch: {
    input(input) {
      this.$emit('input', input);
    },
    value(value) {
      this.input = value;
    }
  },
  props: {
    focus: { type: Boolean, default: false },
    disabled: { type: Boolean, default: false },
    value: { type: [Number, String], default: '' }
  }
};
</script>

<style lang="sass" scoped></style>

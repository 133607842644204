<template>
  <menu-item :icon="icon" :to="to" :i18n="i18n" :counter="16" />
</template>
<script>
import menuItem from './menuItem';
export default {
  components: {
    menuItem
  },
  props: {
    counter: { type: Number, default: 0 },
    i18n: { type: String, default: '' },
    icon: { type: String, default: '' },
    to: { type: String, default: '#' }
  }
};
</script>
<style lang="sass" scoped></style>

<template>
  <div class="collapse card" :class="classes">
    <b-collapse
      animation="slide"
      :aria-id="`collapse-${collapseId}`"
      :open="isOpen"
      @open="onToggle(true)"
      @close="onToggle(false)"
    >
      <template #trigger="props">
        <div
          class="card-header"
          :class="!separator ? 'no-separator' : ''"
          role="button"
          :aria-controls="`collapse-${collapseId}`"
        >
          <slot v-if="$slots.header" name="header" />
          <span v-else class="card-header-title">{{ title }}</span>
          <a class="card-header-icon">
            <b-icon :icon="props.open ? 'chevron-up' : 'chevron-down'" />
          </a>
        </div>
      </template>

      <div class="card-content">
        <div class="content">
          <slot />
        </div>
      </div>
    </b-collapse>
    <div class="collapse-footer" v-if="$slots.footer">
      <slot name="footer" />
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      isOpen: this.open
    };
  },
  computed: {
    classes() {
      const classes = [];
      if (this.disabled) classes.push('disabled');
      return classes.join(' ');
    }
  },
  methods: {
    onToggle(isOpen) {
      this.isOpen = isOpen;
      this.$emit('update:open', isOpen);
    }
  },
  props: {
    collapseId: { type: [Number, String], required: true },
    disabled: { type: Boolean, default: false },
    open: { type: Boolean, default: false },
    separator: { type: Boolean, default: true },
    title: { type: String, default: 'Collapse' }
  }
};
</script>

<style lang="sass" scoped>
.card-header
  display: flex
  justify-content: space-between
  align-items: center
  padding: 0 0 0 1rem
.no-separator
  border: none!important
.collapse.card
  margin-left: 10px
  margin-right: 10px
  transition: box-shadow .3s ease-in-out
  &:hover
    box-shadow: 0 0 10px 1px darken($main-background,5)
.collapse-footer
  padding: .75rem 1rem
.card-header-icon
  align-self: flex-start
.card-content
  padding: 1rem
.icon
  color: $primary
.disabled
  &:hover
    box-shadow: none!important
  // pointer-events: none
  ::v-deep
    .card-header:hover
      background: inherit!important
    .collapse-trigger
      cursor: default!important
    .collapse-content
      display: none!important
    .card-header-icon
      pointer-events: none
      opacity:0
</style>

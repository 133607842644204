<template>
  <Card :class="classes" :style="`margin-bottom:${marginBottom}px;`">
    <template v-if="title" #header>{{ title }}</template>
    <slot />
  </Card>
</template>
<script>
import Card from '@/components/Card.vue';
export default {
  components: {
    Card
  },
  computed: {
    classes() {
      const clases = [];
      if (this.shadow) clases.push('shadow');
      if (this.small) clases.push('small');
      return clases.join(' ');
    }
  },
  props: {
    title: {
      type: String,
      default: ''
    },
    marginBottom: {
      type: Number,
      default: 30
    },
    shadow: {
      type: Boolean,
      default: true
    },
    small: {
      type: Boolean,
      default: false
    }
  }
};
</script>

<style lang="sass" scoped>
.card
  margin: 0
  padding-bottom: 30px
  &.shadow
    box-shadow: $box-shadow
  &.small
    min-height: 50px
    padding: 5px!important
  ::v-deep
    .card-header
      border: none
      font-weight: bold
      margin-bottom: 10px
</style>

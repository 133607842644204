<template>
  <div class="copy-board">
    <Link @click.stop="() => copyToClipboard(copyText)" :class="classPosition">
      {{ label }}
    </Link>
  </div>
</template>

<script>
import { Link } from '@/components';

export default {
  components: {
    Link
  },
  computed: {
    label() {
      return this.text || this.copyToText;
    },
    copyText() {
      return this.copyToText || this.text;
    },
    classPosition() {
      return this.positionLeft ? 'position-left' : 'position-right';
    }
  },
  methods: {
    copyToClipboard(text) {
      navigator.clipboard.writeText(text);
      this.$toast('info', this.$t('global.copiedToClipboard'), 1000);
    }
  },
  props: {
    text: { type: String, default: null },
    copyToText: { type: String, default: null },
    positionLeft: { type: Boolean, default: false }
  }
};
</script>
<style lang="sass" scoped>
.copy-board
  display: flex
  .link
    font-size: 14px !important
    font-weight: 700
    color: $blue-600
    margin: 6px 0 6px 0
  .position-left
    margin-right: auto
  .position-right
    margin-left: auto
</style>

<style lang="sass">
.dark
  .copy-board
    .link
      color: $blue-400
</style>

<template>
  <Field :label="label" :required="required" :avoidSpecialChars="false">
    <b-datepicker
      @input="onInput"
      v-bind="{ ...$props, ...$attrs }"
      v-model="date"
      :date-formatter="dateFormater"
      :date-parser="dateParser"
      :validation-message="$t('validations.date')"
      placeholder="MM/DD/YYYY"
      :locale="locale"
    />
  </Field>
  <!-- :years-range="[-40, 20]" -->
</template>

<script>
import Field from '@/components/Field.vue';

export default {
  data() {
    return {
      date: this.DOB() || undefined
    };
  },
  components: {
    Field
  },
  computed: {
    maxDateComputed() {
      return this.$moment(this.maxDate).add(1, 'day').toDate();
    },
    locale() {
      return this.$store.getters.LANG === 'es' ? 'es-ES' : 'en-US';
    }
  },
  methods: {
    DOB() {
      const date = this.value ? this.$moment(this.value, this.responseFormat).toDate() : null;
      return date;
    },
    dateFormater(date) {
      return this.$moment(date).utc().format('MM/DD/YYYY');
    },
    dateParser(date) {
      return this.$moment(date, 'MM/DD/YYYY').toDate();
    },
    onInput(date) {
      this.$emit(
        'input',
        this.$moment(this.validationRanges(date)).utc().format(this.responseFormat)
      );
    },
    validationRanges(value) {
      const minDateValidation = this.minDate
        ? this.$moment(this.minDate, 'MM/DD/YYYY').toDate()
        : null;
      const maxDateValidation = this.maxDate
        ? this.$moment(this.maxDate, 'MM/DD/YYYY').toDate()
        : null;

      const conditionalOutOfRange = !this.$moment(value, "MM/DD/YYYY'").isBetween(
        minDateValidation,
        maxDateValidation
      );

      let nextDate = null;

      if (minDateValidation && maxDateValidation && conditionalOutOfRange) {
        nextDate = this.$moment(this.maxDate, 'MM/DD/YYYY').toDate();
      } else if (minDateValidation && value <= minDateValidation) {
        nextDate = this.$moment(this.minDate, 'MM/DD/YYYY').add(1, 'day').toDate();
      } else if (maxDateValidation && value >= maxDateValidation) {
        nextDate = this.$moment(this.maxDate, 'MM/DD/YYYY').toDate();
      }

      if (nextDate) this.$nextTick(() => (this.date = nextDate));

      return nextDate || value;
    }
  },
  watch: {
    value() {
      this.date = this.DOB();
    }
  },
  props: {
    editable: { type: Boolean, default: true },
    label: { type: String, default: '' },
    maxDate: {},
    minDate: {},
    required: { type: Boolean, default: false },
    responseFormat: { type: String, default: 'Y-MM-DD' },
    value: {},
    iconRight: { type: String, default: 'calendar-today' }
  }
};
</script>

<style lang="sass" scoped>
::v-deep
  .datepicker
    .dropdown-menu
      z-index: 202
  .is-disabled
    opacity: 1!important
</style>

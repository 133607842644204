<template>
  <div class="search-field">
    <b-field :label="iLabel" :message="' ' || resultsMessage">
      <p class="control" v-if="iUseDropdown ? filterOptions.api || filterOptions.data : false">
        <b-dropdown
          aria-role="list"
          :max-height="300"
          scrollable
          :disabled="disabled || loading.search || loading.filter"
        >
          <template #trigger="{ active }">
            <b-button
              class="drowdown-btn"
              :label="filter.id ? filter[filterOptions.field] : filterOptions.label"
              type="is-primary"
              :icon-right="active ? 'menu-up' : 'menu-down'"
              :loading="loading.filter"
              :disabled="loading.filter"
            />
          </template>
          <b-dropdown-item aria-role="listitem" @click="onFilterChange({})">
            {{ $t('global.all') }}
          </b-dropdown-item>
          <b-dropdown-item
            aria-role="listitem"
            v-for="filter in filters"
            :key="filter.id"
            @click="onFilterChange(filter)"
          >
            {{ filter.name }}
          </b-dropdown-item>
        </b-dropdown>
      </p>
      <Input-search
        v-model="search"
        :class="iUseDropdown ? 'w-dropdown' : ''"
        :placeholder="placeholder || `${$t('global.search')}...`"
        :loading="loading.search"
        :disabled="disabled || loading.search || loading.filter"
        @input="doSearch"
      />
    </b-field>
  </div>
</template>

<script>
import InputSearch from '@/components/InputSearch.vue';
export default {
  components: {
    InputSearch
  },
  created() {
    this.paramsFromUrl();
    this.$emit('input', this.search);
  },
  mounted() {
    const { iUseDropdown, hasDropdownPermission } = this;
    if (!hasDropdownPermission) {
      this.iUseDropdown = hasDropdownPermission;
    }
    if (iUseDropdown) {
      this.getFilters();
    }
  },
  data() {
    return {
      filter: {},
      iUseDropdown: this.useDropdown,
      filters: this.filterOptions.data || [],
      loading: { filter: false, search: false },
      search: this.$route.query.search
    };
  },
  computed: {
    hasDropdownPermission() {
      const { permission } = this.filterOptions;
      return permission ? this.Secure.permissionValidator(permission) : true;
    },
    iLabel() {
      if (this.label === false) return '';
      else return this.label || this.$i18n.t('global.searchBy');
    },
    URLParams() {
      return this.$route.query || {};
    }
  },
  methods: {
    onFilterChange(filter) {
      this.filter = filter;
      this.updateRouter();
    },
    doSearch(search) {
      this.$emit('input', search);
      this.updateRouter();
    },
    updateRouter() {
      const { filter, filterOptions, queryKey, search } = this;
      let query = { ...this.$route.query };
      if (filter) query[filterOptions.model] = filter.id;
      else delete query[filterOptions.model];
      if (search) query[queryKey] = search;
      else delete query[queryKey];
      this.$router.replace({ query });
      this.updateSearchParams();
    },
    paramsFromUrl() {
      const { queryKey, filterOptions } = this;
      const query = this.$route.query;
      const aQuery = Object.keys(query);
      const params = [];
      const search = query[queryKey];
      const filterId = query[filterOptions.model];
      if (search) this.search = search;
      if (filterId) this.filter = { tempId: filterId };
      aQuery.map((key) => {
        params.push(`${key}=${query[key]}`);
      });
      if (params.length) this.updateSearchParams(params);
    },
    updateSearchParams(URLParams) {
      let searchParams = [];
      if (URLParams) searchParams = URLParams;
      else {
        const { filter, filterOptions, queryKey, search } = this;
        const params = [];
        if (filter?.id) params.push(`${filterOptions.model}=${filter.id}`);
        if (search) params.push(`${queryKey}=${search}`);
        searchParams = params;
      }
      this.$emit('update:searchParams', searchParams);
    },
    filterById(id) {
      return this.filters.filter((f) => f.id == id)[0];
    },
    async getFilters() {
      const { Api, filter, filterOptions } = this;
      this.loading.filter = true;
      try {
        const { data } = await Api.get(filterOptions.api);
        this.filters = data;
        if (filter.tempId) this.filter = this.filterById(filter.tempId);

        // if (this.$route.query.filter) this.filter = this.filterById(this.$route.query.filter);
      } catch (error) {
        console.error(error);
      }
      this.loading.filter = false;
    },
    onUpdate(data) {
      this.$emit('updated', data);
    }
  },
  watch: {
    value(val) {
      if (this.search === val) return;
      this.search = val;
      this.updateRouter();
    }
  },
  props: {
    value: {},
    api: { type: String, default: '' },
    disabled: { type: Boolean, default: false },
    filterOptions: {
      type: Object,
      default: () => ({ label: '', api: '', data: [], field: '', model: '', permission: {} })
    },
    label: { type: [String, Boolean], default: '' },
    placeholder: { type: String, default: '' },
    queryKey: { type: String, default: 'all' },
    searchParams: { type: Array, default: () => [] },
    useDropdown: { type: Boolean, default: true }
  }
};
</script>

<style lang="sass" scoped>
.searcher
  height: calc(100vh - 90px)
  border-radius: $br-md
  overflow: hidden
.search-field
  .drowdown-btn
    border-radius: $br-md
  ::v-deep
    .w-dropdown
      width: 100%
      input
        border-top-left-radius: 0
        border-bottom-left-radius: 0
    .label
      font-size: 14px
      text-transform: uppercase
      color: $search-field-label!important
      margin-bottom: 3px
    .help
      text-align: right
.infinite-scroll
  height: calc( 100% - 65px )
  overflow: auto
  .card
    &.disabled
      opacity: 0.3
</style>
<style lang="sass" scoped>
.dark
  .search-field
    .input-search
      ::v-deep
        .control
          input
            &::placeholder
              color: $gray-600
              opacity: 1
</style>

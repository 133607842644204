<template>
  <div class="Picture" ref="image">
    <div class="letters" :class="loaded ? 'hidden' : ''" :style="`font-size:${this.fontSize};`">
      <b-icon v-if="loading" icon="loading" custom-class="mdi-spin" custom-size="auto" />
      <template v-else-if="alt">
        {{ letters }}
      </template>
      <template v-else>
        <b-icon v-if="error" icon="image-remove" custom-size="auto" />
        <b-icon v-else :icon="icon" custom-size="auto" />
      </template>
    </div>

    <div v-if="mySrc" class="pe-container" :class="loaded ? 'loaded' : ''">
      <b-image
        v-if="mySrc.indexOf('@/assets') >= 0"
        v-bind="{ ...$props, ...$attrs }"
        :src="require(`@/assets/${mySrc.replace('@/assets/', '')}`)"
        @error="onError"
      />
      <b-image
        v-else
        v-bind="{ ...$props, ...$attrs }"
        :src="mySrc"
        @error="onError"
        @load="onLoad"
      />
    </div>
  </div>
</template>

<script>
import { downloadFile } from '@/utils/Presigned';
export default {
  mounted() {
    this.calculateFontSize();
    this.getFromPreasigned();
  },
  data() {
    return {
      fontSize: '1em',
      error: false,
      mySrc: this.src,
      loading: false,
      loaded: false
    };
  },
  computed: {
    letters() {
      let alt = this.alt || 'N A';
      alt = alt.replace(/\(|\)|,|'|"/g, '');
      const abc = alt.toUpperCase().split(' ');
      const i = abc.length;
      return `${abc[0].slice(0, 1)}${i > 1 ? `${abc[1].slice(0, 1)}` : ''}`;
    }
  },
  methods: {
    calculateFontSize() {
      const width = this.$refs?.image?.clientWidth;
      this.fontSize = `${(width * 7) / 250}em`;
    },
    async getFromPreasigned() {
      const { apiUrl, fileName } = this.preasigned;
      if (!this.mySrc && apiUrl && fileName) {
        this.loading = true;
        const src = await downloadFile({ apiUrl: `${apiUrl}/download`, fileName });
        if (src) this.updateSrc(src);
      }
    },
    updateSrc(src) {
      if (src) this.$emit('update:src', src);
      this.mySrc = src || 'src::Error';
    },
    onError() {
      this.error = true;
      this.loading = false;
      this.$emit('error');
    },
    onLoad() {
      this.loaded = true;
      setTimeout(() => {
        this.loading = false;
        this.$emit('load');
      }, 300);
    }
  },
  watch: {
    src(value) {
      this.error = false;
      this.mySrc = value;
    },
    preasigned(newVal, oldVal) {
      if (newVal.fileName != oldVal.fileName) {
        this.loaded = false;
        this.getFromPreasigned();
      }
    }
  },
  props: {
    alt: { type: String, default: '' },
    icon: { type: String, default: 'image' },
    apiUrl: { type: String, default: '' },
    controls: { type: Boolean, default: false },
    preasigned: { type: Object, default: () => ({ apiUrl: null, fileName: null }) },
    rounded: { type: Boolean, default: false },
    ratio: { type: String, default: '' },
    src: { type: String, default: null }
  }
};
</script>

<style lang="sass" scoped>
.hidden
  transition: opacity .4s ease
  opacity: 0
.Picture
  border-radius: $br-md
  overflow: hidden
  height: 100%
  position: relative
  .pe-container
    transition: opacity .3s ease
    opacity: 0
    position: absolute
    top: 0
    z-index: 1
    &.loaded
      opacity: 1
  .loading
    position: absolute
    top: 0
    left: 0
    bottom: 0
    right: 0
  .loading,
  .letters
    align-items: center
    background: $gray-100
    border: 2px solid $gray-200
    border-radius: $br-md
    color: $gray-700
    display: flex
    height: 100%
    justify-content: center
    padding: 20px
figure
  margin: 0!important
</style>

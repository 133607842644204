export default {
  state: {
    deliveryHistory: {},
    manifest: {},
    rescheduleDelivery: {},
    multipleDeliveries: {},
    run: {}
  },
  getters: {
    deliveryHistory: ({ deliveryHistory }) => deliveryHistory,
    manifest: ({ manifest }) => manifest,
    rescheduleDelivery: ({ rescheduleDelivery }) => rescheduleDelivery,
    multipleDeliveries: ({ multipleDeliveries }) => multipleDeliveries,
    run: ({ run }) => run
  },
  mutations: {
    deliveryHistory(state, deliveryHistory) {
      state.deliveryHistory = deliveryHistory;
    },
    manifest(state, manifest) {
      state.manifest = manifest;
    },
    multipleDeliveries(state, data) {
      state.multipleDeliveries = data;
    },
    rescheduleDelivery(state, payload) {
      state.rescheduleDelivery = payload;
    },
    run(state, run) {
      state.run = run;
    },
    updateManifestEvents() {
      console.warn('mutation-updateManifestEvents');
    },
    reloadTable() {}
  },
  actions: {}
};

const requireModule = require.context('.', false, /\.js$/);
const modules = {};
if (requireModule)
  requireModule.keys().forEach((fileName) => {
    if (fileName === './index.js') return;
    const moduleName = fileName.replace(/(\.\/|\.js)/g, '');
    // create a dynamic object with all modules
    modules[moduleName] = {
      namespaced: true,
      ...requireModule(fileName).default
    };
  });
export default modules;

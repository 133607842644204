<template>
  <Autocomplete
    :label="$tc('global.gender.label', 0)"
    :data="genders"
    model="id"
    field="label"
    v-model="gender"
    :required="required"
    :sortData="false"
  />
</template>

<script>
import { Autocomplete } from '@/components';
export default {
  components: {
    Autocomplete
  },
  mounted() {
    if (this.gender) this.$emit('input', this.gender);
  },
  computed: {
    genders() {
      return [
        { id: 'Unspecified', label: this.$tc('global.gender.notSpecified') },
        { id: 'Male', label: this.$tc('global.gender.label', 1) },
        { id: 'Female', label: this.$tc('global.gender.label', 2) }
      ];
    }
  },
  data() {
    return { gender: this.value };
  },
  watch: {
    value(gender) {
      this.gender = gender;
    },
    gender(value) {
      this.$emit('input', value);
    }
  },
  props: {
    required: { type: Boolean, default: false },
    value: { type: String, default: 'Unspecified' }
  }
};
</script>

<style lang="sass" scoped></style>

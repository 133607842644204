<template>
  <div id="layout">
    <div id="layout-container">
      <ViewHistory />
      <ReScheduleDelivery />
      <MultipleDetails />
      <div :class="isHidden ? '' : 'overlay'" @click="toggleMenu" />
      <Menu :isHidden="isHidden" />
      <section id="layout-content">
        <div class="flex header">
          <Link v-show="isHidden" id="m-show-menu-button" @click="toggleMenu">
            <b-icon class="icon-menu" icon="menu" />
          </Link>
          <div class="breadcumbs">
            <template v-if="breadcumbs.custom">
              <template v-for="(b, i) in breadcumbs.custom">
                <span class="capitalize" :key="i"> {{ $tc(b) }}</span>
                <template v-if="i < breadcumbs.custom.length - 1"> / </template>
              </template>
            </template>
            <template v-else>
              <span v-if="breadcumbs.parent.meta" class="capitalize"
                >{{ $t(breadcumbs.parent.meta.i18n) }}
                <span> / </span>
              </span>
              <span class="capitalize">{{ $t($route.meta.i18n) }}</span>
            </template>
          </div>
          <div class="flex f-ai">
            <div class="flex f-ai">
              <Portal-target name="headerActions" class="actions" />
              <div v-if="$slots.actions" class="actions">
                <slot name="actions" />
              </div>
              <Notifications isAsynchronous :refreshRate="300000" />
              <div class="flex f-ai">
                <Picture
                  :preasigned="{
                    apiUrl: '/presigned/employees',
                    fileName: `${user.employee_id}.png`
                  }"
                  :alt="user.full_name"
                />
                <Settings :isDark.sync="isDarkMode" />
              </div>
              <Notifications />
            </div>
          </div>
        </div>
        <slot />
      </section>
      <!-- <div v-if="isMenuOpen" id="container-cover"></div> -->
    </div>
    <RingCentral />
  </div>
</template>
<script>
// import Header from '@/components/Header';
// import Footer from "@/components/Footer.vue";
// import Menu from '@/components/Menu';
import Menu from '@/components/Menu';
import Notifications from '@/components/Notifications/Notifications';
import { Link, Picture, Settings } from '@/components';
import { ViewHistory, ReScheduleDelivery, MultipleDetails } from '@/components/Dispatch/Modals';
import RingCentral from '@/components/RingCentral';

export default {
  name: 'defaultLayout',
  components: {
    Link,
    Menu,
    MultipleDetails,
    Notifications,
    Picture,
    ReScheduleDelivery,
    RingCentral,
    Settings,
    ViewHistory
  },
  computed: {
    routes() {
      return this.$router.getRoutes();
    },
    user() {
      const { employee_id, full_name } = this.$store.getters.AUTH || {};
      return { employee_id, full_name };
    }
  },
  data() {
    return {
      isHidden: true,
      breadcumbs: { parent: {}, custom: null },
      isDarkMode: this.$store.getters.DARK
    };
  },
  methods: {
    setDarkMode() {
      document.getElementsByTagName('body')[0].className = this.isDarkMode ? 'dark' : '';
    },
    setParent(route = this.$route) {
      const parents = this.routes.filter((r) => r.meta.items);
      this.breadcumbs.parent =
        parents.filter((r) => r.meta?.items.filter((i) => i == route.name)[0])[0] || {};
    },
    toggleMenu() {
      this.isHidden = !this.isHidden;
    }
  },
  created() {
    this.setDarkMode();
  },
  mounted() {
    this.setParent();
    this.unsubscribe = this.$store.subscribeAction({
      after: ({ type }, { breadcumbs }) => {
        if (type === 'setBreadcumbs') {
          this.breadcumbs.custom = breadcumbs;
        }
      }
    });
  },
  // components: { Header, Menu }
  watch: {
    isDarkMode() {
      this.setDarkMode();
    },
    $route(value) {
      this.setParent(value);
      this.breadcumbs.custom = false;
    }
  }
};
</script>
<style lang="sass" scoped>
#layout
  display: grid
  height: 100%
  min-height: 700px
  overflow: auto
#layout-container
  // min-width: 1440px
  transition: $transition-dark-mode
  display: flex
  min-height: 700px
  height: 100%
  min-width: 100%
  width: fit-content
  background: $main-background
#layout-content
  // width: calc(100% - 220px)
  min-height: 100vh
  width: 100%
  padding: 0 20px 0
  .flex
    display: flex
  .header
    padding: 0 20px
    height: 70px
    margin: 0 -20px 15px
    align-items: center
    justify-content: space-between
    background: white
    transition: $transition-dark-mode
  .breadcumbs
    font-size: $f-xxl
  .actions
    margin-right: 10px
  .locales
    display: flex
    color: $gray
    align-items: center
    a
      margin: 0 10px
      color: $gray
      border-bottom: 2px solid transparent
      font-weight: bold
    a.active
      color: $primary
      border-color: $primary
.overlay
  background: transparentize($black, 0.5)
  display: none
  height: 100%
  transition: 500ms ease
  width: 100%
  z-index: 300
#m-show-menu-button
  color: $black
  display: none
  height: 40px
  line-height: 47px
  margin-left: 10px
  text-align: center
  transition: 500ms ease
  width: 40px
  &:hover
    color: $blue-400
#menu
  transition: 400ms ease
.Picture
  height: 50px
  width: 50px
  margin-left: 10px
  border-radius: $br-sm

@media screen and ( max-width: $bp-xxl )
  #m-show-menu-button
    display: block
    left: 0px
    position: absolute
  #layout-content
    width: 100%
  .breadcumbs
    margin-left: 35px
  .overlay
    display: block
    position: fixed
</style>

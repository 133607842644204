<template>
  <div v-if="iconMode" class="iconManifestStatusUI flex f-ai" :class="classes">
    <b-icon :icon="status.icon" class="statusIcon" custom-size="mdi-18px" />
    <slot v-if="$slots.default" name="default" />
  </div>
  <span v-else-if="skeletonLoading">
    <b-skeleton class="manifestStatusUI" />
  </span>
  <b-tag v-else class="manifestStatusUI" :class="classes" rounded>
    {{ status.name }}
  </b-tag>
</template>

<script>
export default {
  computed: {
    manifestStatusTypes() {
      return {
        1: { name: this.$t('manifest.status.created'), strId: 'created' },
        2: {
          name: this.$t('manifest.status.scheduled'),
          strId: 'scheduled',
          icon: 'clock-outline'
        },
        3: { name: this.$t('manifest.status.running'), strId: 'running', icon: 'send' },
        4: { name: this.$t('manifest.status.done'), strId: 'done', icon: 'check-circle-outline' },
        5: {
          name: this.$t('manifest.status.incomplete'),
          strId: 'incomplete',
          icon: 'progress-close'
        },
        6: { name: this.$t('manifest.status.stalled'), strId: 'stalled' }
      };
    },
    classes() {
      let { strId } = this.status;
      const classes = [strId];
      return classes.join(' ');
    },
    status() {
      return this.manifestStatusTypes[this.id];
    }
  },
  watch: {
    status: {
      immediate: true,
      deep: true,
      handler(value, oldVal) {
        if (value?.strId && value?.strId !== oldVal?.strId) {
          this.$emit('status', value);
        }
      }
    }
  },

  props: {
    id: { type: Number, default: 1 },
    iconMode: { type: Boolean, default: false },
    skeletonLoading: { type: Boolean, default: false }
  }
};
</script>

<style lang="sass" scoped>
.b-skeleton
  min-width: 80px
  ::v-deep
    .b-skeleton-item
      border-radius: 30px
      min-height: 25px
.tag
  font-size: 14px
  color: $dark-600
  border: 1px solid $gray-100
  background: $gray-100
  &.clickable
    cursor: pointer
  ::v-deep
    & + *
      margin-left: 3px

@each $i,$color in $manifestStatusColors
  .manifestStatusUI.#{$i}
    border-color: $color
    background: $color
@each $i,$color in $manifestStatusIconColors
  .iconManifestStatusUI.#{$i}
    color: $color
</style>
<style lang="sass">
.dark
  .manifestStatusUI
    color: $main-background!important
    &.scheduled, &.created
      color: $dark!important
    @each $i,$color in $manifestStatusColorsDark
      &.#{$i}
        border-color: $color!important
        background: $color!important
  .iconManifestStatusUI.running
    color: $blue-200
</style>

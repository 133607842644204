<template>
  <a class="link" @click="onClick" :class="classes">
    <b-icon v-if="isLoading && icon" icon="loading" custom-class="mdi-spin" />
    <b-icon v-else-if="icon" :icon="icon" />
    <slot />
    <b-icon v-if="isLoading && !icon" icon="loading" custom-class="mdi-spin" />
  </a>
</template>

<script>
export default {
  data() {
    return {
      bLoading: this.loading
    };
  },
  computed: {
    classes() {
      const classes = [];
      if (this.disabled || this.isLoading) classes.push('disabled');
      if (this.underlined) classes.push('underlined');
      return classes.join(' ');
    },
    isLoading() {
      return this.bLoading || this.loading;
    }
  },
  methods: {
    handlerAutoLoading() {
      if (!this.autoLoading) return;
      this.bLoading = true;
      setTimeout(() => {
        this.bLoading = false;
      }, 500);
    },
    onClick(e) {
      this.handlerAutoLoading();
      this.$emit('click', e);
    }
  },
  watch: {
    loading(value) {
      this.bLoading = value;
    }
  },
  props: {
    autoLoading: { type: Boolean, default: false },
    disabled: { type: [Boolean, Number], default: false },
    icon: { type: String, default: '' },
    loading: { type: Boolean, default: false },
    underlined: { type: Boolean, default: false },
    activate: { type: Boolean, default: false }
  }
};
</script>
<style lang="sass" scoped>
a
  display: flex
  align-items: center
  width: fit-content
  &:hover
    color: darken($primary, 10)
  &.underlined
    text-decoration: underline!important
  &.disabled
    pointer-events: none
    cursor: default
    text-decoration: none
    color: black
    opacity: 0.5
</style>
<style lang="sass">
.dark
  .link, .tooltip-link a, a.card-header-icon, a.card-header-icon .icon
    color: $primary-dark
    &:hover
      color: $primary-dark-hover
    &.disabled
      color: $main-background
      opacity: 1 !important
</style>

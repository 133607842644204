<template>
  <b-dropdown-item aria-role="listitem" custom :class="classes">
    <b-progress v-if="loading.getting" type="is-primary" />
    <div class="flex w-100" @click="onNotificationClick">
      <div class="n-icon flex f-ai f-jc" :class="notiData.classType">
        <b-icon
          v-if="notiData.icon"
          :class="notiData.icon === 'timer-sand' ? 'icon rotate' : 'icon'"
          :icon="notiData.icon"
          custom-size=" "
        />
        <img v-else :src="notification.imageIconUrl" />
      </div>
      <b-icon v-if="!notification.is_attended" class="unreaded" icon="circle-medium" />
      <div class="n-content">
        <!-- {{ notification.type }} -->
        <div class="n-head flex f-jsb f-ai">
          <FormatText class="n-title" capitalize>
            {{ notiData.title || notification.title || notification.type }}
          </FormatText>
          <div class="n-date">{{ formatTime(notification.created_at) }}</div>
        </div>
        <div class="n-body" v-html="notiData.body || notification.body || '-'" />
        <div v-if="notiData.reason">
          <strong>{{ $t('global.reason') }}:</strong> {{ notiData.reason }}
        </div>
        <b-button
          class="mt-2"
          v-if="notiData.button"
          :label="notiData.button.title"
          :loading="loading.button"
          @click="notiData.button.onClick"
          outlined
          type="is-primary"
        />
      </div>
    </div>
  </b-dropdown-item>
</template>

<script>
import { Strongify } from '@/utils/Text';
import { FormatText } from '@/components';
import { downloadFileByURL } from '../../utils/Download';
export default {
  components: {
    FormatText
  },
  mounted() {
    this.init();
  },
  computed: {
    classes() {
      const classes = [];
      if (this.notiData.onClickAction || !this.notification.is_attended) classes.push('clickable');
      if (this.isImportant) classes.push('isImportant');
      return classes.join(' ');
    },
    isImportant() {
      return ['package_reschedule'].includes(this.notification?.type);
    },
    asynchronousRequestData() {
      if (!this.isAsynchronous) return {};
      const { jsonBody, title } = this.notification;
      const { response, uuid } = jsonBody || {};

      const uuidText = `UUID: ${uuid}`;
      const bodyText = JSON.parse(response || '{}').text || '';

      const successData = {
        classType: 'success',
        icon: 'progress-check',
        body: `${bodyText} ${uuidText}`,
        title: this.$tc('global.complete'),
        button: {
          title: this.$t('global.download'),
          onClick: () => this.onDownloadFile(uuid)
        }
      };
      return {
        file_download_request_success: successData,
        request_done: successData,
        asynchronous_processing: {
          classType: 'warning',
          icon: 'progress-clock',
          body: uuidText,
          title: this.$tc('global.processing')
        },
        request_failed: {
          classType: 'danger',
          icon: 'progress-close',
          body: uuidText,
          title: title
        }
      };
    },
    notiData() {
      const { jsonBody, body, type } = this.notification;
      const {
        address,
        employee_name,
        employee,
        events,
        group_id,
        issues,
        no_try,
        participant_name,
        reason,
        driver_name,
        expiration_date,
        delivered_datetime,
        date,
        tracking_number,
        vehicle_name,
        time,
        email
      } = jsonBody || {};
      const newEvents = events ? JSON.parse([events]) : null;
      const user = Strongify(newEvents ? employee : employee_name, true);
      const client =
        Strongify(newEvents ? newEvents[0].participant_name : participant_name, true) +
        Strongify(this.$t('grammar.pHelp'));
      const notiData = {
        ...this.asynchronousRequestData,
        add_on: {
          body: this.$t('notification.messages.addOn'),
          classType: 'info',
          icon: 'plus',
          title: `${this.$tc('global.new', 2, [this.$t('dispatch.addOn')])}`
        },
        automatic_scheduling: {
          classType: 'success',
          body: this.$t('notification.autoSchedul.body', {
            user,
            status: Strongify(this.$tc('global.successful', 2))
          }),
          icon: 'clipboard-check-multiple-outline',
          title: this.$t('notification.autoSchedul.tilte')
        },
        geofence: {
          classType: 'danger',
          body: this.$t('notification.geofence.body', {
            user: Strongify(employee_name, true),
            on: Strongify(tracking_number, true),
            time: Strongify(this.formatTime(delivered_datetime, true), true),
            status: Strongify(this.$tc('global.successful', 2))
          }),
          icon: 'map-marker-radius',
          title: this.$t('notification.geofence.title')
        },
        file_import: {
          classType: 'success',
          icon: 'file-check-outline',
          body: this.$t('notification.import', {
            fileName: group_id,
            status: Strongify(this.$tc('global.successful', 2))
          }),
          onClickAction: () => this.redirectToImports(group_id),
          title: this.$t('global.fileImport')
        },
        package_attempted: {
          classType: 'warning',
          icon: 'alert-circle-outline',
          status: Strongify(
            `${this.$t('global.attempted')}: ${this.$tc('global.nTries', no_try, [no_try])}`
          ),
          title: this.$tc('global.package', 0, [this.$t('global.attempted')])
        },
        package_delivered: {
          classType: 'success',
          icon: 'check-circle-outline',
          status: Strongify(this.$tc('global.successful')),
          title: this.$tc('global.package', 0, [this.$t('global.delivered')])
        },
        package_in_progress: {
          classType: 'info',
          icon: 'send',
          status: Strongify(this.$t('global.inProgress')),
          title: this.$tc('global.package', 0, [this.$t('global.inProgress')])
        },
        package_canceled: {
          classType: 'danger',
          icon: 'close-circle-outline',
          onClickAction: this.showDeliveryhistory,
          status: Strongify(this.$tc('global.canceled', 0)),
          title: this.$tc('global.package', 0, [this.$tc('global.canceled')])
        },
        package_damaged: {
          button: { title: this.$t('button.seeEvidence'), onClick: this.showDeliveryhistory },
          classType: 'danger',
          icon: 'image-broken-variant',
          status: Strongify(this.$t('global.damaged')),
          title: this.$tc('global.package', 0, [this.$t('global.damaged')])
        },
        package_failed: {
          classType: 'danger',
          icon: 'thumb-down',
          status: Strongify(this.$tc('global.failed', 2)),
          onClickAction: this.showDeliveryhistory,
          title: this.$tc('global.package', 0, [this.$t('global.failed')])
        },
        package_missing: {
          classType: 'danger',
          icon: 'help-box',
          status: Strongify(this.$t('global.missing')),
          onClickAction: this.showDeliveryhistory,
          title: this.$tc('global.package', 0, [this.$t('global.missing')])
        },
        package_rejected: {
          classType: 'danger',
          icon: 'cancel',
          status: Strongify(this.$tc('global.rejected', 0)),
          title: this.$tc('global.rejected')
        },
        package_reschedule: {
          button: {
            title: this.$t('dispatch.reScheduleDelivery'),
            onClick: this.showRescheduleModal
          },
          classType: 'warning',
          icon: 'sort-clock-ascending-outline',
          status: Strongify(this.$tc('global.reschedule', 2)),
          title: this.$tc('global.package', 0, [this.$tc('global.reschedule', 2)])
        },
        route_outdated: {
          classType: 'danger',
          icon: 'alert-outline',
          body: this.$t('notification.outdated', {
            status: Strongify(this.$t('global.outdated'))
          })
        },
        package_scheduled: {
          classType: 'default',
          icon: 'clock-outline',
          status: Strongify(this.$t('notification.scheduled'))
        },
        package_return: {
          classType: 'danger',
          icon: 'clipboard-arrow-left',
          status: Strongify(this.$t('global.return')),
          title: this.$tc('global.package', 0, [this.$t('global.return')]),
          onClickAction: this.showDeliveryhistory
        },
        multiple_package_return: {
          classType: 'danger',
          icon: 'clipboard-arrow-left',
          title: this.$tc('global.multiplePackage', 0, [this.$t('global.return')]),
          body: this.$t('notification.multipleDeliveries', {
            user,
            status: Strongify(this.$t('global.return'))
          }),
          button: {
            title: this.$t('global.seeDetails'),
            onClick: this.showReturnModal
          }
        },
        package_picked_up: {
          classType: 'info',
          icon: 'archive-arrow-up-outline',
          status: Strongify(this.$t('global.pickedUp')),
          title: this.$tc('global.package', 0, [this.$t('global.pickedUp')])
        },
        multiple_package_picked_up: {
          classType: 'info',
          icon: 'archive-arrow-up-outline',
          title: this.$tc('global.multiplePackage', 0, [this.$t('global.pickedUp')]),
          body: this.$t('notification.multipleDeliveries', {
            user,
            status: Strongify(this.$t('global.pickedUp'))
          }),
          button: {
            title: this.$t('global.seeDetails'),
            onClick: this.showPickupModal
          }
        },
        csv_processing: {
          title: this.$tc('dispatch.processing', 0, [this.$t('global.csvFile')]),
          classType: 'info',
          icon: 'timer-sand'
        },
        participant_update: {
          title: this.$t('dispatch.participantUpdated'),
          classType: 'warning',
          icon: 'autorenew',
          body: this.$t('notification.participantUpdated', {
            trackingNumber: tracking_number || '',
            status: Strongify(this.$t('messages.updated'))
          })
        },
        package_rescheduled: {
          title: this.$tc('global.package', 0, [this.$tc('global.reschedule', 2)]),
          classType: 'warning',
          icon: 'sort-clock-ascending-outline',
          status: Strongify(this.$tc('global.reschedule', 2))
        },
        exceeded_lunch_break: {
          title: this.$t('notification.lunchBreakTimeExceeded'),
          classType: 'danger',
          icon: 'silverware-fork-knife',
          body: this.$t('notification.lunchBreakExceeded', {
            employee: user
          })
        },
        package_unsuccessfully: {
          title: this.$t('notification.dayIssues'),
          classType: 'danger',
          icon: 'clipboard-text-search-outline',
          body: this.$t('notification.dayIssueDescription', {
            issues: Strongify(issues)
          }),
          button: {
            title: this.$t('global.seeDetails'),
            onClick: () => {
              this.$router.push({
                path: `/dispatch/issues`,
                query: {
                  date: `${this.$moment(this.notification.created_at)
                    .subtract(1, 'days')
                    .format('YYYY-MM-DD')}`
                }
              });
            }
          }
        },
        lunch_break_time: {
          title: this.$t('notification.timeForLunchBreak'),
          classType: 'warning',
          icon: 'silverware-fork-knife',
          body: this.$t('notification.employeeLunchBreak', {
            employee: user,
            vehicle: Strongify(vehicle_name, true)
          })
        },
        notice_renewal: {
          title: this.$t('notification.licenseExpirationTitle'),
          classType: 'warning',
          icon: 'card-account-details',
          body: this.$t('notification.licenseExpiration', {
            driver: Strongify(driver_name, true),
            date: Strongify(expiration_date)
          })
        },
        password_expires_soon: {
          title: this.$t('notification.passwordExpiresSoon'),
          classType: 'danger',
          icon: 'lock',
          body: this.$t('notification.passwordExpiresSoonBody', {
            date: Strongify(date),
            time: Strongify(time)
          })
        },
        blocked_user: {
          title: this.$t('notification.blockedUser'),
          classType: 'danger',
          icon: 'block-helper',
          body: this.$t('notification.blockedUserBody', {
            email: Strongify(email)
          }),
          button: {
            title: this.$t('global.seeDetails'),
            onClick: () => {
              if (this.$route.path !== '/advanced-settings') {
                this.$router.push({
                  path: `/advanced-settings`,
                  query: { current: 3 }
                });
              }
            }
          }
        },
        address_parser_unresolve: {
          title: this.$t('notification.unresolvedAddress'),
          classType: 'danger',
          icon: 'home-alert',
          body: this.$t('notification.unresolvedAddressBody', {
            participant: Strongify(participant_name, true),
            order_number: Strongify(tracking_number),
            address: Strongify(address, true)
          })
        },
        override_sign_in: {
          title: this.$t('notification.sessionClosed'),
          classType: 'danger',
          icon: 'login',
          body: this.$t('notification.sessionClosedBody')
        }
      }[type] || { body };
      if (notiData && !notiData.body) {
        notiData.body = notiData.status
          ? this.$t('notification.markedAs', {
              user,
              client,
              id: newEvents ? newEvents[0].tracking_number : tracking_number,
              status: notiData.status
            })
          : body;
      }
      // case 5:
      //   status = Strongify(this.$tc('global.reschedule', 0));
      //   icon = 'sort-clock-ascending-outline';
      //   body = this.$t('notification.reschedule', { user, client, status });
      return { type, onClickAction: null, reason, classType: 'default', ...notiData };
    }
  },
  data() {
    return {
      loading: { getting: false, button: false }
    };
  },
  methods: {
    init() {
      if (this.notification.dispatch) {
        this.showNotification();
      }
    },
    showNotification() {
      const { classType } = this.notiData;
      this.$Notification(classType, this.notification.body, {
        sound: this.notification.sound
      });
    },
    formatTime(dateTime, justTime = false) {
      if (!dateTime) return '-';
      const format = justTime ? 'hh:mm a' : 'Y-MM-DD hh:mm a';
      const utcDate = this.$moment.utc(dateTime).toDate();
      return this.$moment(utcDate).format(format);
    },
    async onNotificationClick() {
      this.setAttended();
      const { onClickAction } = this.notiData;
      onClickAction && onClickAction();
    },
    async onNotificationButtonClick() {
      this.setAttended();
      const { onButtonClickAction } = this.notiData;
      onButtonClickAction && onButtonClickAction();
    },
    async setAttended() {
      if (this.setNotificationAttendedFunction) {
        const { id, is_attended } = this.notification;
        if (!is_attended) {
          try {
            await this.setNotificationAttendedFunction(id);
            this.notification.is_attended = 1;
          } catch (Err) {
            Err;
          }
        }
      }
    },
    showDeliveryhistory() {
      this.$store.commit('dispatch/deliveryHistory', this.notification.jsonBody);
    },
    showRescheduleModal() {
      const { body } = this.notification;
      const { event_id, delivery_manifest_id, reason, participant_id } = this.notification.jsonBody;
      this.$store.commit('dispatch/rescheduleDelivery', {
        body,
        delivery_manifest_id,
        id: event_id,
        participant_id,
        reason
      });
    },
    redirectToImports(group_id) {
      let path = `/schedule/imports${(group_id || '') && `/${group_id}`}`;
      if (this.$router.currentRoute.path != path) this.$router.push({ path });
    },
    showReturnModal() {
      const data = { data: this.notification.jsonBody, type: 'multiple_package_return' };
      this.$store.commit('dispatch/multipleDeliveries', data);
    },
    showPickupModal() {
      const data = { data: this.notification.jsonBody, type: 'multiple_package_picked_up' };
      this.$store.commit('dispatch/multipleDeliveries', data);
    },
    async onDownloadFile(uuid) {
      try {
        this.loading.button = true;
        this.onNotificationClick();
        const fileUrl = await this.getFileUrl(uuid);
        if (fileUrl) this.$toast('success', this.$t('global.downloading'), 3000);
        downloadFileByURL(fileUrl);
      } catch (error) {
        const message = `Error: ${this.$t('global.errors.404.file')}<br />UUID:${uuid}`;
        this.$toast('error', message, 3000);
      } finally {
        this.loading.button = false;
      }
    },
    async getFileUrl(uuid) {
      const s3Url = await this.getAsyncRequestUrl(uuid);
      const { url: fileUrl } = await fetch(s3Url);
      return fileUrl;
    },
    async getAsyncRequestUrl(uuid) {
      const { data } = await this.Api.get(`/async_request/${uuid}`);
      const url = data.result.url;
      if (url) return url;
      throw new Error('URL TO FILE NOT FOUND');
    }
  },
  props: {
    notification: { type: Object, required: true },
    isAsynchronous: { type: Boolean, default: false },
    setNotificationAttendedFunction: { type: Function, required: true }
  }
};
</script>

<style lang="sass" scoped>
.isImportant
  border-left: 5px solid $red-800
.n-icon
  align-self: flex-start
  border-radius: 50%
  border: none !important
  color: white
  font-size: 25px
  height: 45px
  margin-right: 15px
  min-width: 45px
  overflow: hidden
  width: 45px
  &.default
    background: $gray-500
  &.info
    background: $blue-400
  &.success
    background: $green-500
  &.danger
    background: $red-700
  &.warning
    background: $yellow-500
.n-content
  font-size: 14px
  color: $gray-800
  width: 100%
.n-title
  color: $dark-500
  font-size: $f-lg
  font-weight:500
.n-date
  color: $gray-700
  font-weight: 500
.dropdown-item
  display: flex
  align-items: center
  padding: 0
  border-bottom: 1px solid $main-separators
  &:hover
    background: $primary-hover
  &:last-child
    border-bottom: none
  &>.flex
    padding: 10px 16px
.clickable
  cursor: pointer
.unreaded
  position: absolute
  left: 0
  top: 0
  color: $blue-400
.rotate
  transform: rotate(180deg)
.participant,
.employee
  text-transform: uppercase
  font-weight: 700
.progress-wrapper
  position: absolute
  top:0
  height: 100%
  width: 100%
  z-index: 2
  border: none
  ::v-deep
    .progress
      height: 100%
      border-radius: 0
      opacity: .3
      pointer-events: none
.dark
  .dropdown-item
    border-bottom-color: $dark-500
  .n-content
    color: $gray-500
    ::v-deep
      strong
        color: $gray-400!important
    .n-title
      color: $gray-400
</style>

import { setCookie, getCookie } from '@/utils/Cookies.js';
import moment from 'moment';
import { getActiveCDPsService } from '../../services/CPDService';

export default {
  namespaced: false,
  state: {
    AppConf: JSON.parse(localStorage.getItem('AppConf')) || { IsMenuOpen: true },
    cdpColors: getCookie('CDP_COLORS') || []
  },
  getters: {
    AppConf: (state) => state.AppConf || {},
    cdpColors: (state) => state.cdpColors || []
  },
  mutations: {
    setAppConf(state, conf) {
      state.AppConf[conf.name] = conf.value;
      localStorage.setItem('AppConf', JSON.stringify(state.AppConf));
    },
    setCDPColors(state, cdps) {
      const cdpColors = cdps.map(({ id, color }) => ({ id, color }));
      setCookie('CDP_COLORS', cdpColors, moment().add(1, 'day').toDate());
      state.cdpColors = cdpColors;
      localStorage.setItem('cdpColors', cdpColors);
    }
  },
  actions: {
    updateCDPColors: async (context, forced) => {
      const hasCDPs = !!context.getters.cdpColors.length;
      if (hasCDPs && !forced) return;
      const cdps = await getActiveCDPsService();
      context.commit('setCDPColors', cdps);
    },
    AppConf: (context, config) => {
      const keys = Object.keys(config);
      keys.map((key) => {
        context.commit('setAppConf', { name: key, value: config[key] });
      });
    }
  }
};

<template>
  <div v-if="value" class="copayment-tag">
    <DeliveryStatusUI type="copayment" component="tag" :text="amount" />
  </div>
</template>

<script>
import { toMoney } from '../utils/NumberHelper';
import DeliveryStatusUI from './DeliveryStatusUI.vue';
export default {
  components: {
    DeliveryStatusUI
  },
  computed: {
    hasError() {
      return !(Number(this.value) >= 0);
    },
    iSufix() {
      return this.useSufix ? this.sufix : '';
    },
    amount() {
      return toMoney(this.value, { sufix: this.iSufix, sign: '' });
    }
  },
  props: {
    useSufix: { type: Boolean, default: true },
    sufix: { type: String, default: 'USD' },
    value: { type: [Number, String], default: 0 }
  }
};
</script>

<style lang="sass" scoped>
.copayment-tag
  .delivery-tag
    width: fit-content
    margin: 0
    white-space: nowrap
</style>

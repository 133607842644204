import moment from 'moment';

export default {
  namespaced: true,
  state: {
    async_processing: JSON.parse(localStorage.getItem('asyncNotifications') || '[]'),
    message: null
  },
  getters: {
    asyncProcess: (state) => state.async_processing
  },
  mutations: {
    onMessage(state, message) {
      state.message = message;
    },
    setAsyncProcess(state, uuid) {
      const data = {
        is_attended: 0,
        created_at: moment().toDate(),
        type: 'asynchronous_processing',
        jsonBody: { uuid }
      };
      state.async_processing.unshift(data);
      localStorage.setItem('asyncNotifications', JSON.stringify(state.async_processing));
    }
  },
  actions: {
    onMessage({ commit }, message) {
      commit('onMessage', message);
    },
    addAsyncProcessing({ commit, state: { async_processing } }, uuid) {
      const notInState = async_processing.every((n) => n.uuid !== uuid);

      if (notInState) commit('setAsyncProcess', uuid);
    }
  }
};

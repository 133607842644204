export const formatNumber = (num, { decimals = 2 }) => {
  let value = num % 1 ? num.toFixed(decimals) : num;
  value = String(value).replace(/(?<!\..*)(\d)(?=(?:\d{3})+(?:\.|$))/g, '$1,');
  return value;
};

export const toFixedTrunc = (val, decimals = 2) => {
  if (!val) {
    console.log('Err: A value is required');
    return 0;
  }
  let value = val;
  if (typeof value === 'string') {
    value = parseFloat(value);
  }
  if (!(value % 1)) {
    value += 0.000000001;
  }
  const strValue = String(value.toFixed(10));
  const oValue = strValue.split('.');
  if (decimals === 0) {
    return oValue[0];
  }

  let strDecimals = oValue[1] || '';
  if (strDecimals.length > decimals) {
    return `${oValue[0]}.${strDecimals.substr(0, decimals)}`;
  }

  while (strDecimals.length < decimals) {
    strDecimals += '0';
  }
  return `${oValue[0]}.${strDecimals}`;
};

export const toMoney = (
  amount,
  {
    fillDecimals = true,
    decimals = 2,
    isTruncated = false,
    isNumeric = false,
    sign = '$',
    sufix = 'pesos'
  } = {}
) => {
  if (Number.isNaN(amount) || !amount) {
    console.log(`Err: Use only numeric: ${amount}`);
    return 0;
  }
  const commaRegex = /\B(?=(\d{3})+(?!\d))/g;
  let parsed = '';
  let strAmount = '';
  const fAmount = parseFloat(amount);
  if (!(fAmount % 1)) {
    parsed = fAmount.toString().replace(commaRegex, ',');
    const zeros = '0'.repeat(decimals);
    if (fillDecimals) {
      strAmount = `${parsed}.${zeros}`;
    } else {
      strAmount = parsed;
    }
  } else {
    if (isTruncated) {
      parsed = toFixedTrunc(fAmount, decimals);
    } else {
      parsed = fAmount.toFixed(decimals);
    }
    const oParcial = parsed.split('.');
    oParcial[0] = oParcial[0].replace(commaRegex, ',');
    strAmount = oParcial.join('.');
  }
  if (isNumeric) {
    return Number(strAmount.replace(/,/g, ''));
  }
  if (sufix) {
    strAmount = `${strAmount} ${sufix}`;
  }
  return sign ? `${sign}${strAmount}` : strAmount;
};

/* eslint-disable prettier/prettier */
// General
export const allCharactersRegex = /.*/;
export const letters = '^[a-zA-ZnÑ]+$';
export const numberAndLettersRegex = '^[a-zA-Z0-9nÑ]+$';
export const numberAndLettersPlusRegex = "^[a-zA-Z0-9nÑ'.-]+$";
export const numberAndLettersPlusSpaceRegex = "^[a-zA-Z0-9nÑ '.-]+$";
export const numberAndLettersSpaceRegex = '^[a-zA-Z0-9nÑ ]+$';

// Custom
export const clientCodeRegex = '[a-zA-Z]{3}[0-9]{6}';
export const dateRegex = '(((0[13-9]|1[012])[-/]?(0[1-9]|[12][0-9]|30)|(0[13578]|1[02])[-/]?31|02[-/]?(0[1-9]|1[0-9]|2[0-8]))[-/]?[0-9]{4}|02[-/]?29[-/]?([0-9]{2}(([2468][048]|[02468][48])|[13579][26])|([13579][26]|[02468][048]|0[0-9]|1[0-6])00))';
export const driverLicense = numberAndLettersRegex;
export const emailRegex = '[a-zA-Z0-9._%+-]+[a-zA-ZñÑ0-9]@[a-zA-Z0-9.-]+.[a-zA-Z]{1,63}$';
export const imeiRegex = '^[0-9]{15,16}$';
export const licensePlateRegex = '^[a-zA-Z0-9]{7}$';
export const mtsRegex = '^[N][-][0-9]{3,5}';
export const onlyNumbersRegex = /[\d]/;
export const phoneRegex = '^[0-9-]{12}$';
export const vinRegex = '^[a-zA-Z0-9]{17}$';
export const zipcodeRegex = '[0-9]{5}';
export const multipleSpacesRegex = '\\s\\s+';

export default {
  allCharactersRegex,
  clientCodeRegex,
  dateRegex,
  driverLicense,
  emailRegex,
  imeiRegex,
  licensePlateRegex,
  mtsRegex,
  numberAndLettersRegex,
  numberAndLettersPlusRegex,
  numberAndLettersPlusSpaceRegex,
  numberAndLettersSpaceRegex,
  onlyNumbersRegex,
  phoneRegex,
  vinRegex,
  zipcodeRegex
};

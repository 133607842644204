import { render, staticRenderFns } from "./Notifications.vue?vue&type=template&id=3e870367&scoped=true&"
import script from "./Notifications.vue?vue&type=script&lang=js&"
export * from "./Notifications.vue?vue&type=script&lang=js&"
import style0 from "./Notifications.vue?vue&type=style&index=0&id=3e870367&lang=sass&scoped=true&"
import style1 from "./Notifications.vue?vue&type=style&index=1&lang=sass&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "3e870367",
  null
  
)

export default component.exports
<template>
  <b-skeleton v-if="loading" :width="justIcon ? 25 : null" :height="justIcon ? 25 : null" />
  <Link
    v-else
    class="phone-viewer"
    :label="label"
    :icon="showIcon || justIcon ? 'phone' : ''"
    :href="`tel:+1${phone}`"
    @click="onClick"
    :disabled="disabled || !phone"
  >
    <span v-if="!justIcon">
      {{ parsedPhone }}
    </span>
  </Link>
</template>

<script>
import { Link } from '@/components';
import { call_ringout, send_sms } from '@/utils/ringCentral';
import { toast, confirm } from '@/utils/dialog';
export default {
  components: {
    Link
  },
  computed: {
    parsedPhone() {
      const { format, phone } = this;
      let phoneStr = '-';
      if (phone) phoneStr = phone.toString().replace(/^(\d{3})(\d{3})(\d{4})$/g, format);
      return phoneStr;
    },
    label() {
      let label = '';
      if (this.sms) label = this.$t('global.sms');
      if (this.call) label = this.$t('global.call');
      return label;
    },
    type() {
      if (this.call) return 'CALL';
      if (this.sms) return 'SMS';
      return '';
    }
  },
  data() {
    return {
      event: null
    };
  },
  methods: {
    onClick(e) {
      if (e.processed) return;
      const { type } = this;
      this.event = e;
      if (type) {
        e.stopPropagation();
        e.preventDefault();
      }
      if (type === 'SMS') this.prepareSMS();
      else if (type === 'CALL') this.prepareCall();

      this.$emit('click');
    },
    prepareSms() {
      confirm({
        title: this.$t('confirms.sendSms', [this.parsedPhone]),
        cancelText: 'No',
        confirmText: 'Ok',
        onConfirm: () => {
          this.sendSMS();
        }
      });
    },
    prepareCall() {
      if (this.useConfirmation) {
        confirm({
          title: this.$t('global.call', [this.parsedPhone]),
          cancelText: 'No',
          confirmText: 'Ok',
          onConfirm: () => {
            this.makeCall();
          }
        });
      } else {
        this.makeCall();
      }
    },
    async sendSMS() {
      alert('PENDING');
      if (this.phone === 'x') {
        const strPhone = `+1 ${this.parsedPhone}`;
        const result = await send_sms(
          `+1${this.phone}`,
          'Welcome  to CFS, the #1 NEMT Provider in San Diego, any questions? please call 819-134-86311'
        );
        if (result) {
          toast('success', `SMS SENT TO:<br/>${strPhone}`, 10000);
        } else {
          toast('error', `SMS COULDN'T BE SEND TO:<br/>${strPhone}`, 10000);
        }
      }
    },
    makeCall() {
      const clonedNativeEvent = new MouseEvent('click', this.event);
      clonedNativeEvent.processed = true;
      this.event.target.dispatchEvent(clonedNativeEvent);
      call_ringout;
    }
  },
  props: {
    call: { type: Boolean, default: () => true },
    useConfirmation: { type: Boolean, default: () => false },
    disabled: { type: Boolean, default: () => false },
    format: { type: String, default: '($1) $2-$3' },
    justIcon: { type: Boolean, default: () => false },
    loading: { type: Boolean, default: () => false },
    phone: { required: true },
    showIcon: { type: Boolean, default: () => false },
    sms: { type: Boolean, default: () => false }
  }
};
</script>

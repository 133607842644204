<template>
  <div v-if="visible" class="flex f-ai icons" :class="deliveryClass">
    <slot />
    <CopaymentTag v-if="isCopayment" class="mr-1" :value="copayment_amount" />
    <DeliveryStatusUI v-if="isRush" type="rush" :component="typeComponent" />
    <DeliveryStatusUI v-if="isPriority" type="priority" :component="typeComponent" />
    <DeliveryStatusUI v-if="isCollect" type="collect" :component="typeComponent" />
    <DeliveryStatusUI v-if="isRefrigerated" type="refrigerated" :component="typeComponent" />
    <DeliveryStatusUI v-if="isLeaveAtDoor" type="leave-at-door" :component="typeComponent" />
    <DeliveryStatusUI v-if="isMaleDriver" type="male-driver" :component="typeComponent" />
    <DeliveryStatusUI v-if="isFemaleDriver" type="female-driver" :component="typeComponent" />
  </div>
</template>

<script>
import { DeliveryStatusUI, CopaymentTag } from '@/components';

export default {
  components: {
    DeliveryStatusUI,
    CopaymentTag
  },
  computed: {
    visible() {
      return !!(
        !!this.$slots.default ||
        this.isCopayment ||
        this.isRush ||
        this.isPriority ||
        this.isCollect ||
        this.isRefrigerated ||
        this.isLeaveAtDoor ||
        this.isMaleDriver ||
        this.isFemaleDriver
      );
    },
    copayment_amount() {
      return this.status.copayment_amount;
    },
    isCopayment() {
      return !!this.copayment_amount;
    },
    isRush() {
      return this.status.rush;
    },
    isPriority() {
      return this.status.priority && !this.status.rush;
    },
    isRefrigerated() {
      return this.status.refrigerated;
    },
    isCollect() {
      return this.status.collect;
    },
    isMaleDriver() {
      return this.status.male_driver;
    },
    isFemaleDriver() {
      return this.status.female_driver;
    },
    isLeaveAtDoor() {
      return this.status.leaveAtDoor && !this.status.collect;
    },
    typeComponent() {
      return this.isTag ? 'tag' : 'simple-icon';
    },
    deliveryClass() {
      if (this.isIconTag) return 'is-icon-tag';
      if (this.isTag) return 'is-tag';
      return '';
    }
  },
  props: {
    isIconTag: { type: Boolean, default: false },
    isTag: { type: Boolean, default: false },
    status: { type: Object, default: () => {} }
  }
};
</script>

<style lang="sass" scoped>
.icons
  margin-right: 10px
.is-icon-tag
  padding: 1px 5px
  background-color: #FFFFFF
  border-radius: 2rem
  margin: 0
  margin-bottom: 5px
  ::v-deep
    .simple-delivery
      margin: auto
.is-tag
  flex-wrap: wrap
  border-radius: 2rem
  margin: 0
  ::v-deep
    .simple-delivery
      margin: auto
</style>

<style lang="sass">
.dark
  .is-icon-tag
    background-color: $dark
</style>

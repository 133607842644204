<template>
  <div class="columns">
    <PhoneSelector
      :label="$t('contact.mobile')"
      :phones="oPhones"
      :class="classes"
      :value="mobileNumber || mobile"
      @input="(p) => onInput('mobile', p)"
      :loading="loading"
    />
    <PhoneSelector
      :label="$t('contact.landline')"
      :phones="oPhones"
      type="land"
      :class="classes"
      :value="landNumber || land"
      @input="(p) => onInput('land', p)"
      :loading="loading"
    />
  </div>
</template>

<script>
import PhoneSelector from './PhoneSelector.vue';
export default {
  components: {
    PhoneSelector
  },
  mounted() {
    this.getPhones();
  },
  data() {
    return {
      loading: false,
      oPhones: this.phones,
      landNumber: null,
      mobileNumber: null
    };
  },
  computed: {
    classes() {
      return 'column is-half';
    },
    defaultLandNumber() {
      return this.oPhones?.find((P) => P.number_type === 'land' && P.is_default)?.phone_number;
    },
    defaultMobileNumber() {
      return this.oPhones?.find((P) => P.number_type === 'mobile' && P.is_default)?.phone_number;
    }
  },
  methods: {
    onInput(type, phoneNumber) {
      const phone = this.oPhones?.find(
        (phone) => phone.number_type == type && phone.phone_number == phoneNumber
      );
      if (type === 'land') this.landNumber = phoneNumber;
      if (type === 'mobile') this.mobileNumber = phoneNumber;

      this.$emit(`update:${type}`, phoneNumber);
      this.$emit('select', { phoneNumber, type, phone });
    },
    async getPhones() {
      if (!this.participantId || this.phones?.length) return;
      this.loading = true;
      try {
        const { data } = await this.Api.get(
          `/participants/${this.participantId}/phone_numbers?sort=-is_default`
        );
        this.oPhones = data;
        this.$emit('updatedPhones', data);
      } catch (error) {
        console.error(error);
      }
      this.loading = false;
    },
    setDefaultPhones() {
      const { defaultMobileNumber, mobile, mobileId, defaultLandNumber, land, landId } = this;
      const landPhoneById = this.oPhones?.find((phone) => phone.id == landId)?.phone_number;
      const mobilePhoneById = this.oPhones?.find((phone) => phone.id == mobileId)?.phone_number;
      this.landNumber = landPhoneById || land || defaultLandNumber;
      this.mobileNumber = mobilePhoneById || mobile || defaultMobileNumber;
      const { mobileNumber, landNumber } = this;
      this.onInput('mobile', mobileNumber);
      this.onInput('land', landNumber);
    }
  },
  watch: {
    loading(value) {
      this.$emit('update:loading', value);
    },
    participantId() {
      this.getPhones();
    },
    phones(value) {
      this.oPhones = value;
    },
    oPhones() {
      this.setDefaultPhones();
    }
  },
  props: {
    land: { type: String, default: null },
    landId: { type: [String, Number], default: null },
    mobileId: { type: [String, Number], default: null },
    mobile: { type: String, default: null },
    participantId: { type: Number, default: null },
    phones: { type: Array, default: () => [] }
  }
};
</script>

<style lang="scss" scoped></style>

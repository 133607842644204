import { setCookie } from '@/utils/Cookies.js';

export default {
  namespaced: true,
  state: {
    OFFSET: 1
  },
  getters: {
    OFFSET: (state) => state.OFFSET
  },
  mutations: {
    reloadTable() {},
    OFFSET(state, data) {
      setCookie('OFFSET', data, Infinity);
      state.OFFSET = data;
    }
  }
};

<template>
  <div class="control" :class="loading ? 'is-loading' : ''">
    <masked-input
      ref="input"
      class="input"
      v-model="model"
      :mask="mask"
      v-bind="{ ...$props, ...$attrs }"
      @input="onInput"
      :disabled="loading"
    />
  </div>
</template>

<script>
import MaskedInput from 'vue-masked-input';

export default {
  components: {
    MaskedInput
  },
  data() {
    return {
      model: this.value
    };
  },
  methods: {
    onInput(input) {
      this.$emit('input', input);
    },
    focus() {
      this.$refs.input.$el.focus();
    }
  },
  watch: {
    value(val) {
      this.model = val;
    }
  },
  props: {
    value: {},
    mask: { type: String },
    type: { type: String, default: 'text' },
    loading: { type: Boolean, default: false }
  }
};
</script>

import { setCookie, getCookie } from '@/utils/Cookies.js';

export default {
  namespaced: false,
  state: {
    DARK: Number(getCookie('DARK') || 0)
  },
  getters: {
    DARK: (state) => state.DARK
  },
  mutations: {
    DARK(state, data) {
      setCookie('DARK', data, Infinity);
      state.DARK = data;
    }
  },
  actions: {
    DARK: ({ commit }, DARK_data) => {
      commit('DARK', DARK_data);
      commit('map/update', { action: 'darkMode', data: DARK_data });
    }
  }
};

<template>
  <strong v-if="strongify">
    {{ processedText() }}
  </strong>
  <span v-else>
    {{ processedText() }}
  </span>
</template>

<script>
import { Capitalize } from '@/utils/Text';

export default {
  methods: {
    processedText() {
      let text = String(this.text || this.$slots?.default[0]?.text || '');
      if (this.capitalize) text = Capitalize(text);
      if (this.lowerCase) text = text.toLowerCase();
      return text;
    }
  },
  props: {
    capitalize: { type: Boolean, default: false },
    lowerCase: { type: Boolean, default: false },
    strongify: { type: Boolean, default: false },
    text: { type: String, default: '' }
  }
};
</script>
<style lang="sass" scoped></style>

<template>
  <div>
    <b-button
      tag="a"
      :class="classes"
      :type="isLink ? 'is-text' : 'is-primary'"
      :icon-left="isLink ? '' : 'download'"
      outlined
      @click="downloadQR"
      :disabled="disabled || !text"
    >
      {{ $t('global.downloadQR') }}
    </b-button>
    <Vue-qr :size="300" :text="text" :bindElement="false" :callback="onQRgenerated" />
  </div>
</template>

<script>
import VueQr from 'vue-qr';
import { jsPDF } from 'jspdf';

export default {
  components: {
    VueQr
  },
  data() {
    return {
      doc: null
    };
  },
  computed: {
    classes() {
      const classes = [];
      if (this.fullWidth) classes.push('w-100');
      if (this.isLink) classes.push('link');
      return classes.join(' ');
    },
    downloadName() {
      return this.name.replace(/ /g, '_');
    }
  },
  methods: {
    addImage(image) {
      this.clearDoc();
      this.doc.addImage(image, 'PNG', 15, 15, 60, 60);
      this.doc.text(this.downloadName, 19, 80);
    },
    downloadQR() {
      this.doc.save(`${this.downloadName}.pdf`);
      this.$emit('click', '');
    },
    onQRgenerated(data) {
      this.addImage(data);
    },
    clearDoc() {
      this.doc = new jsPDF();
    }
  },
  props: {
    disabled: { type: Boolean, default: false },
    fullWidth: { type: Boolean, default: false },
    isLink: { type: Boolean, default: false },
    name: { type: String, default: 'image' },
    text: { type: String, default: '' }
  }
};
</script>

<style lang="sass" scoped>
.link
  color: $primary
  font-weight: bold
  background: transparent!important
  padding: 0
</style>

<style lang="sass" scoped>
.dark
  .link
    color: $blue-400
    text-style: underline
    &:hover
      color: $primary-dark-hover
      box-shadow: none !important
</style>

<template>
  <section id="mappr">
    <slot />
    <div :id="mapId" :style="mapStyle" />
  </section>
</template>

<script>
import { fligthmap_apiKey } from '@/config/constants.js';
export default {
  mounted() {
    this.map = new window.flightmap.Map({
      container: this.mapId, // container id
      style: this.style, //hosted style id
      center: this.center, // starting position
      zoom: this.zoom, // starting zoom,
      accessToken: fligthmap_apiKey
    });
  },
  data() {
    return {
      map: undefined,
      reverseGeocodeDelayer: 0
    };
  },
  computed: {
    mapStyle() {
      return { minHeight: '300px', height: '100%' };
    },
    style() {
      return `style-${this.useDarkMode ? 'dark' : 'bright'}.json`;
    }
  },
  methods: {
    delayer(timeout = 500, timer = 0) {
      clearTimeout(timer);
      return new Promise((resolve) => {
        timer = setTimeout(() => {
          resolve();
        }, timeout);
      });
    },
    async reverseGeocode({ lat, lng, zoom = 18 }) {
      await this.delayer(200, this.reverseGeocodeDelayer);
      const response = await fetch(
        `https://maps.flightmap.io/api/search_reverse?fm_token=${fligthmap_apiKey}&lat=${lat}&lng=${lng}&zoom=${zoom}`
      );
      if (response.status === 200) {
        const { data } = await response.json();
        return data;
      } else {
        throw response;
      }
    },
    async searchApi({ text, lat = this.center[0], lng = this.center[1] }) {
      await this.delayer(200, this.reverseGeocodeDelayer);
      const encodedText = window.encodeURIComponent(text);
      const response = await fetch(
        `https://maps.flightmap.io/api/search?fm_token=${fligthmap_apiKey}&currentlatitude=${lat}&currentlongitude=${lng}&offering=3&text=${encodedText}`
      );
      if (response.status === 200) {
        const { data } = await response.json();
        return data;
      } else {
        throw response;
      }
    }
  },
  watch: {
    useDarkMode() {
      this.map.setStyle(this.style);
    }
  },
  props: {
    mapId: { type: String, default: 'mappr-map' },
    zoom: { type: Number, default: 7 },
    center: { type: Array, default: () => [-117.0367, 32.7456] },
    useDarkMode: { type: Boolean, default: false }
  }
};
</script>

<style lang="sass" scoped>
#mappr
  height: 100%
</style>

<template>
  <gmap-info-window
    v-if="isGmaps"
    :options="{ pixelOffset: { width: -3, height: -37 } }"
    :position="position"
    @closeclick="onClose"
  >
    <slot />
  </gmap-info-window>
  <LMarker
    v-else
    :key="`os-iw-${key}`"
    :lat-lng="os_position"
    @add="os_openPopup"
    @popupopen="() => $emit('open')"
    @popupclose="onClose"
    :opacity="0"
  >
    <LPopup ref="os_popup" :options="{ closeButton: false }">
      <a class="leaflet-popup-close-button" @click="os_handleClose">×</a>
      <slot />
    </LPopup>
  </LMarker>
</template>

<script>
import { LMarker, LPopup } from 'vue2-leaflet';
import { osLatlngParser } from './OSM/osHelper';
import { isGoogleMap } from './helper';

export default {
  components: {
    LMarker,
    LPopup
  },
  data() {
    return {
      isOpen: false,
      os_popup: null,
      key: 1
    };
  },
  beforeUpdate() {
    console.log('::💬 BU');
  },
  updated() {
    console.log('::💬 U', this.position);
  },
  computed: {
    isGmaps() {
      return isGoogleMap();
    },
    os_position() {
      return osLatlngParser(this.position);
    }
  },
  methods: {
    os_handleClose() {
      this.os_popup.remove();
    },
    os_openPopup(event) {
      this.$nextTick(() => {
        this.os_popup = event.target;
        event.target.openPopup();
      });
    },
    onClose() {
      this.$emit('close');
    }
  },
  watch: {
    position() {
      this.key++;
    }
  },
  props: {
    anchor: { type: Array, default: () => [16, 37] },
    position: { type: Object, default: () => {} }
  }
};
</script>
